import styles from './Footer.module.scss';
import AxiomaLogo from '../../assets/images/Footer/axioma_logo.png';
import CompanyLogo from '../../assets/images/Footer/company_logo.png';
import React from 'react';

const Footer: React.FC = () => {
	return (
		<div className={styles.footer}>
			<div className={styles['section-container']}>
				<div className={styles['company-info']}>
					<div className={styles['company-image-container']}>
						<img
							src={CompanyLogo}
							alt='company_logo'
							className={styles['company-image']}
						/>
					</div>
					<div className={styles['company-text']}>
						© {new Date().getFullYear()} ApertureData Inc.
					</div>
				</div>
				<div className={styles.divider} />
				<div className={styles['company-info']}>
					<div className={styles['company-text']}>
						Powered by Axioma Studios
					</div>
					<div className={styles['company-image-container']}>
						<img
							src={AxiomaLogo}
							alt='axioma_logo'
							className={styles['axioma-image']}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
