import styles from './CompanyDescription.module.scss';
import ActionButton from '../../../components/Buttons/ActionButton';
import GroupImage from '../../../assets/images/JoinUs/group.svg';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const style = {
	contactUs: {
		backgroundColor: '#4648c6',
		color: '#fff',
		borderRadius: 8,
		width: 200,
		height: 48,
		fontSize: 18,
		letterSpacing: 0.2,
		textTransform: 'none',
		fontWeight: 'bold',
	},
};

const CompanyDescription: React.FC = () => {
	const navigate = useNavigate();

	return (
		<div className={styles['company-description']}>
			<div
				className={`${styles['section-container']} ${styles['section-container-extension']}`}
			>
				<div className={styles['title-column']}>
					<div className={styles['company-description-image-container']}>
						<img
							className={styles['company-description-image']}
							alt='group'
							src={GroupImage}
						/>
					</div>
				</div>
				<div className={styles['content-column']}>
					<div className={styles['company-description-title']}>
						We manage multimodal data so our users can make machines learn better and faster
					</div>
					<div className={styles['company-description-subtitle']}>
						If solving the next set of challenges for machine learning
						applications excites you, please reach out to us!
					</div>
					<ActionButton
						name='Contact Us'
						onClick={(): void => navigate('../contact-us')}
						style={style.contactUs}
					/>
				</div>
			</div>
		</div>
	);
};

export default CompanyDescription;
