import styles from "./Users.module.scss";
import React, { useState } from "react";
import users from "../../../assets/content/Users";
import { Paper, MobileStepper, Button, useMediaQuery } from "@material-ui/core";

export interface UserType {
	id: number;
	phrase: string;
	author?: string;
	image?: string;
	stand?: string;
}

export interface CompanyUserType {
	name: string;
	image: string;
}

const Users: React.FC = () => {
	const [activeStep, setActiveStep] = useState(0);

	const isSmallScreen = useMediaQuery("(max-width:870px)");
	const startIndex = activeStep <= 2 ? 0 : activeStep - 2;
	const endIndex = activeStep <= 2 ? 3 : activeStep + 1;

	const handleNext = () => {
		setActiveStep((prevActivePage) =>
			Math.min(prevActivePage + 1, users.length)
		);
	};

	const handleBack = () => {
		setActiveStep((prevActivePage) => Math.max(prevActivePage - 1, 0));
	};

	return (
		<div className={styles.users}>
			<div
				className={`${styles["section-container"]} ${styles["section-container-extension"]}`}
			>
				<span className={styles.firstTitle}>
					Hear what the visionaries have to say
				</span>
				<span className={styles.subTitle}>
					Users, thought leaders and technologists alike rave about
					ApertureData’s product and mission
				</span>
				<div className={styles.usersContainer}>
					{isSmallScreen ? (
						<Paper
							elevation={4}
							variant="outlined"
							className={`${styles.paper} ${styles.activeShadow}`}
							style={{
								padding: "16px",
								margin: "8px",
								transform: "scale(1.05)",
							}}
						>
								<div className={styles.container}>
									<blockquote className={styles.userPhrase}>
										“{users[activeStep].phrase}”
									</blockquote>
									<div className={styles.userSign}>
										<div className={styles.userImageContainer}>
											<img
												alt={users[activeStep].author}
												src={users[activeStep].image}
												style={{
													maxWidth: "80px",
													maxHeight: "50px",
												}}
											/>
										</div>
										<div className={styles.userDetails}>
											<cite className={styles.userAuthor}>
												{users[activeStep].author}
											</cite>
											<p className={styles.stand}>
												{users[activeStep].stand}
											</p>
										</div>
									</div>
								</div>
						</Paper>
					) : (
						users.slice(startIndex, endIndex).map((user) => (
							<Paper
								elevation={activeStep === (user.id - 1) ? 4 : 1}
								variant="outlined"
								className={`${styles.userContainer} ${
									activeStep === (user.id - 1)
										? styles.activeShadow
										: styles.notActiveShadow
								}`}
								key={(user.id - 1) }
								style={{
									padding: "16px",
									margin: "8px",
									transform:
										activeStep === (user.id - 1)
											? "scale(1.05)"
											: "scale(1)",
								}}
							>
								<blockquote className={styles.userPhrase}>
									“{user.phrase}”
								</blockquote>
								<div className={styles.userSign}>
									<div className={styles.userImageContainer}>
										<img
											alt={user.author}
											src={user.image}
											style={{
												maxWidth: "80px",
												maxHeight: "50px",
											}}
										/>
									</div>
									<div className={styles.userDetails}>
										<cite className={styles.userAuthor}>
											{user.author}
										</cite>
										<p className={styles.stand}>
											{user.stand}
										</p>
									</div>
								</div>
							</Paper>
						))
					)}
				</div>
				<MobileStepper
					classes={{
						dotActive: styles.dotActive,
						dot: styles.dot,
						root: styles.mobileStepper,
					}}
					steps={users.length}
					position="static"
					variant="dots"
					activeStep={activeStep}
					nextButton={
						<Button
							size="small"
							onClick={handleNext}
							disabled={activeStep === users.length - 1}
							classes={{
								root:
									activeStep === users.length - 1
										? styles.arrowRightDisabled
										: styles.arrowRight,
							}}
						/>
					}
					backButton={
						<Button
							size="small"
							onClick={handleBack}
							disabled={activeStep === 0}
							classes={{
								root:
									activeStep === 0
										? styles.arrowLeftDisabled
										: styles.arrowLeft,
							}}
						/>
					}
				/>
			</div>
		</div>
	);
};

export default Users;
