import styles from "./Features.module.scss";
import LastImg from "../../../assets/images/Features/lastImg.svg";
import SecondImg from "../../../assets/images/Features/SecondImg.svg";
import FirstImg from "../../../assets/images/Features/firstImg.svg";
import React from "react";

const Features: React.FC = () => {
	return (
		<div className={`${styles.landing} ${styles["border-bottom"]}`}>
			<div className={styles.features}>
				<div
					className={`${styles["section-container"]} ${styles["section-container-extension"]}`}
				>
					<span className={styles.firstTitle}>
						Outcomes you can count on
					</span>
					<span className={styles.subTitle}>
						Unlock endless opportunities to leverage multimodal
						data to power ML innovations
					</span>
					<div className={styles["features-list"]}>
						<div className={styles.feature}>
							<div className={styles["feature-image-container"]}>
								<img
									src={FirstImg}
									className={styles["feature-image"]}
									alt="img_support"
								/>
							</div>
							<div className={styles["feature-text"]}>
								<p className={styles.highlighted}>
									Faster ML Innovation
								</p>
								<ul className={styles.list}>
									<li>
										<span>
											Tune ML models in hours, not days
											with easy dataset access.
										</span>
									</li>
									<li>
										<span>
											Track labels, data lineage with
											graph-based metadata.
										</span>
									</li>
									<li>
										<span>
											Easily evolve metadata and data in
											step with ML capabilities.
										</span>
									</li>
									<li>
										<span>
											Easily find nearest neighbors and
											classify new data with descriptor
											indexes.
										</span>
									</li>
									<li>
										<span>
											High performance, scalable data
											management, and seamless
											collaboration through common data
											backend.
										</span>
									</li>
								</ul>
							</div>
						</div>
						<div className={`${styles.feature} ${styles.middleCardFeature}`}>
							<div className={styles["feature-text"]}>
								<p className={styles.highlighted}>
									Data Engineering Agility
								</p>
								<ul className={styles.list}>
									<li>
										<span>
											Fast data loaders to ingest data
											rapidly, including from cloud
											buckets.
										</span>
									</li>
									<li>
										<span>
											Easy query, batch feeding of
											datasets into ML frameworks like
											PyTorch.
										</span>
									</li>
									<li>
										<span>
											Various API layers (web,
											object-based, JSON-based) for access
											by users with different skills and
											tasks.
										</span>
									</li>
									<li>
										<span>
											REST API to connect labeling and
											other web frontends.
										</span>
									</li>
									<li>
										<span>
                                            Python / C++
											clients to integrate with existing
											pipelines and build new ones.
										</span>
									</li>

								</ul>
							</div>
							<div className={styles["feature-image-container"]}>
								<img
									src={SecondImg}
									className={styles["feature-image"]}
									alt="video_support"
								/>
							</div>
						</div>
						<div className={styles.feature}>
							<div className={styles["feature-image-container"]}>
								<img
									src={LastImg}
									className={styles["feature-image"]}
									alt="k_nearest_support"
								/>
							</div>
							<div className={styles["feature-text"]}>
								<p className={styles.highlighted}>
									Security and Governance
								</p>
								<ul className={styles.list}>
									<li>
										<span>
											Authentication and SSL encrypted
											communication between client/server.
										</span>
									</li>
									<li>
										<span>
											Role based access control and user
											management.
										</span>
									</li>
									<li>
										<span>
											API to monitor status, performance
											metrics, support for Prometheus/
											Grafana.
										</span>
									</li>
									<li>
										<span>
											Audit logging, straightforward
											software deployment and updates.
										</span>
									</li>
									<li>
										<span>
											Fewer tools make it easier to
											maintain data integrity and
											traceability.
										</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Features;
