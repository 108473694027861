import React from "react";
import ContactUs from "../../Landing/sections/ContactUs";
import styles from "./DockerLicense.module.scss";

const DockerLicense: React.FC = () => {
	return (
		<>
			<div className={styles.dockerLicense}>
				<div className={styles["section-container"]}>
					<h1>Docker License</h1>
				</div>
			</div>
			<div className={styles["title-container"]}>
				<p style={{ textAlign: "center" }}>
					<strong>Evaluation Software License Agreement</strong>
				</p>
				<p>
					<span className={styles.span}>
						This Evaluation Software License Agreement is a legal
						agreement between you (either an individual or an entity)
						and ApertureData, Inc.
						<span className={styles.boldText}> (“ApertureData”) </span>
						governing your use of the ApertureData software licensed under
						this Agreement, which includes all applicable user documentation
						(together, the
						<span className={styles.boldText}> “Software” </span>).
					</span>
				</p>
				<ol>
					<ol>
						<li style={{ fontWeight: 400 }}>
							<p>
								<strong>Introduction and Acceptance</strong>
							</p>
							<span className={styles.spanUpperCase}>
								BY ACCEPTING THIS AGREEMENT, EITHER BY CLICKING A BOX INDICATING
								YOUR ACCEPTANCE, DOWLOADING, INSTALLING OR USING THE SOFTWARE,
								OR OTHERWISE INDICATING YOUR ACCEPTANCE OF THIS AGREEMENT,
								YOU AGREE TO THE TERMS OF THIS AGREMENT. IF YOU ARE DOWNLOADING,
								INSTALLING OR USING THIS SOFTWARE ON BEHALF OF AN ORGANIZATION,
								COMPANY OR OTHER LEGAL ENTITY, THEN YOU: (A) AGREE TO THIS AGREEMENT
								ON BEHALF OF THAT ORGANIZATION, COMPANY, OR OTHER LEGAL ENTITY;
								(B) REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH ORGANIZATION,
								COMPANY OR LEGAL ENTITY TO THIS AGREEMENT; AND (C) REFERENCES TO YOU
								IN THIS AGREEMENT WILL INCLUDE SUCH ORGANIZATION, COMPANY OR LEGAL ENTITY.
								IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT AGREE TO THE TERMS
								OF THIS AGREEMENT, APERTUREDATA DOES NOT LICENSE THE SOFTWARE TO YOU AND
								YOU MUST IMMEDIATELY CEASE ALL USE OF THE SOFTWARE AND DELETE ALL COPIES OF
								THE SOFTWARE FROM YOUR SYSTEMS AND ANY OTHER SYSTEMS IN YOUR POSSESSION OR
								THAT YOU CONTROL.
							</span>
						</li>
						<li style={{ fontWeight: 400 }}>
							<p>
								<strong>Grant of License</strong>
							</p>
							<span className={styles.span}>
								Subject to your compliance with this Agreement, ApertureData hereby grants
								you a limited, non-exclusive, non-transferable, non-sublicensable, royalty-free,
								worldwide license during the Term to download, install and use the Software solely
								for the purposes of internal testing and evaluation, and not for production use.
							</span>
						</li>
						<li style={{ fontWeight: 400 }}>
							<p>
								<strong>Ownership; Reservation of Rights</strong>
							</p>
							<span className={styles.span}>
								The license granted to you in Section 2 is not a transfer or sale of ApertureData’s
								ownership rights in or to the Software. Except for the license expressly granted in
								Section 2, ApertureData retains all right, title and interest (including all
								intellectual property rights) in and to the Software. The Software is protected by
								applicable intellectual property laws, including United States copyright laws and
								international treaties.
							</span>
						</li>
						<li style={{ fontWeight: 400 }}>
							<p>
								<strong>License Restrictions</strong>
							</p>
							<span className={styles.span}>
								You will not (a) use the Software for any commercial or other
								revenue-generating purposes;
								(b) publish, perform, display or otherwise provide access to all or
								any part of the Software or its functionality to any third party;
								(c) modify or create derivative works of the Software
								or associated materials, or any part thereof;
								(d) copy or reproduce the Software or any associated
								materials, in whole or in part;
								(e) except as specifically permitted by applicable law, reverse
								engineer, decompile, disassemble or otherwise attempt to derive the source
								code for the Software;
								(f) disclose, distribute, encumber, sell, rent, lease, sublicense or
								transfer the Software or associated materials, or use the Software or associated
								materials in a timesharing or service bureau arrangement;
								(g) use the Software for benchmarking purposes or to develop a similar or
								competing software;
								(h) use the Software for any public performance comparisons;
								(i) use or permit the use of the Software in violation of applicable law
								or any third-party rights;
								(j) remove, modify or obscure any copyright or other
								proprietary rights marks or legends, including any references to
								ApertureData’s or its licensors’ names, contained in or on the Software
								or associated materials; or (k) transfer any rights granted hereunder.
							</span>
						</li>
						<li style={{ fontWeight: 400 }}>
							<p>
								<strong>Third-Party Licenses</strong>
							</p>
							<span className={styles.span}>
								The Software may include software, content, data or other materials,
								including related documentation, that are owned or licensed by third parties
								<span className={styles.boldText}> (“Third-Party Software”) </span>
								. To the extent required by the
								license terms governing such Third-Party Software, that license will apply
								to the Third-Party Software on a standalone basis instead of this Agreement.

								A list of all Third-Party Software can be found&nbsp;
								<a target="_blank" rel="noopener noreferrer" href={process.env.PUBLIC_URL + '/pdf/open_source_disclosures.pdf'} >
								here
								</a>
								.
							</span>
						</li>
						<li style={{ fontWeight: 400 }}>
							<p>
								<strong>Term and Termination</strong>
							</p>
							<span className={styles.span}>
								Unless earlier terminated as set forth below, this Agreement will
								remain in effect for twelve (12) months from the earlier of the
								date you first access the Software or accept the Agreement (the
								<span className={styles.boldText}> “Term” </span>
								). You may request to extend the Term by contacting ApertureData via
								email at team@apertureata.io, and any renewals will be granted at
								ApertureData’s sole discretion. You may terminate this Agreement at
								any time by permanently ceasing use of the Software and deleting all
								copies of the Software in your possession or control.
								ApertureData may terminate this Agreement at any time. Upon termination
								or expiration of this Agreement the rights and licenses granted to you
								under this Agreement will automatically terminate and you will immediately
								delete all copies of the Software in your possession or control.
							</span>
						</li>
						<li style={{ fontWeight: 400 }}>
							<p>
								<strong>Disclaimer of Warranties</strong>
							</p>
							<span className={styles.spanUpperCase}>
								THE SOFTWARE IS PROVIDED ON AN “AS IS” BASIS. YOU ASSUME
								ALL RESPONSIBILITY FOR THE INSTALLATION AND USE OF THE SOFTWARE.
								TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, APERTUREDATA
								DISCLAIMS ON BEHALF OF ITSELF AND ITS LICENSORS ALL WARRANTIES
								OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION,
								IMPLIED WARRANTIES OF MERCHANTABILITY, QUALITY, ACCURACY, TITLE,
								NONINFRINGEMENT, OR FITNESS FOR A PARTICULAR PURPOSE, WITH RESPECT
								TO THE SOFTWARE. YOU MAY HAVE OTHER STATUTORY RIGHTS, BUT ANY
								STATUTORILY REQUIRED WARRANTIES WILL BE LIMITED TO
								THE SHORTEST LEGALLY PERMITTED PERIOD.
							</span>
						</li>
						<li style={{ fontWeight: 400 }}>
							<p>
								<strong>Limitation of Liability</strong>
							</p>
							<span className={styles.spanUpperCase}>
								TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, UNDER NO
								CIRCUMSTANCES WILL APERTUREDATA BE LIABLE TO YOU FOR ANY CONSEQUENTIAL,
								SPECIAL, DIRECT, INDIRECT, INCIDENTAL, PUNITIVE OR OTHER DAMAGES OF
								ANY KIND (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF BUSINESS
								PROFITS, BUSINESS INTERRUPTION, LOSS OF BUSINESS INFORMATION, LOSS OF
								DATA OR OTHER SUCH PECUNIARY LOSS) ARISING UNDER THIS AGREEMENT OR
								RELATED TO THE SOFTWARE, OR YOUR USE OR INABILITY TO USE THE SOFTWARE,
								EVEN IF APERTUREDATA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
							</span>
						</li>
						<li style={{ fontWeight: 400 }}>
							<p>
								<strong>Miscellaneous</strong>
							</p>
							<span className={styles.span}>
								You may not assign this Agreement, in whole or in part, by operation of law
								or otherwise, without the prior written consent of ApertureData. Any attempted
								assignment without such consent will be null and of no effect, ab initio.
								ApertureData may freely assign this Agreement. This Agreement is governed by
								the laws of the State of California, without reference to its conflict of laws
								principles. Any dispute between you and ApertureData regarding this Agreement
								will be subject to the exclusive jurisdiction of the state and federal courts
								located in Santa Clara County, California in connection with any action arising
								under this Agreement. There are no third party beneficiaries to this Agreement.
								This Agreement is the entire agreement between you and ApertureData and supersedes
								any prior or contemporaneous agreements and communications between the parties
								with respect to the Software. If any provision of this Agreement is held invalid
								or unenforceable, the remainder of this Agreement will continue in full force and effect.
								The waiver of any breach or default will not constitute a waiver of any other right
								hereunder or of any subsequent breach or default.
							</span>
						</li>
					</ol>
				</ol>
			</div>
			<ContactUs />
		</>
	);
};

export default DockerLicense;
