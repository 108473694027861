import styles from "./Comment.module.scss";
import React from "react";
import endDoubleQuotes from "../../assets/images/CaseStudies/end-double-quote.svg";
import startDoubleQuotes from "../../assets/images/CaseStudies/start-double-quote.svg";

interface CommentProps {
	comment: string;
	author?: string;
	position?: string;
}

const Comment: React.FC<CommentProps> = ({ comment, author, position }) => {
	return (
		<div className={styles.containerComments}>
			<div className={styles.boxDoubleQuotes}>
				<img src={startDoubleQuotes} alt={"comment"} className={styles.commentImage}
				/>
				<div className={styles.comment}>{comment}</div>
				<img src={endDoubleQuotes} alt={"comment"} className={styles.commentImage}
				/>
			</div>
			<div className={styles.authorContainer}>
				{author && (
					<span className={styles.authorComment}>{author}</span>
				)}
				{position && (
					<>
						<span className={styles.authorPositionFirstWord}>
							{position.split(" ")[0]}
						</span>
						<span className={styles.authorPositionRest}>
							{position.split(" ").slice(1).join(" ")}
						</span>
					</>
				)}
			</div>
		</div>
	);
};

export default Comment;
