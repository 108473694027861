import styles from './Intro.module.scss';
import React from 'react';

const Intro: React.FC = () => {
	return (
		<div className={styles['intro-news']}>
			<div className={styles['section-container']}>
				<h1>ApertureData in the News</h1>
				<h2>Explore recent coverage of ApertureData and ApertureDB</h2>
			</div>
		</div>
	);
};

export default Intro;
