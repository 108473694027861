import styles from './Home.module.scss';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Navigation from '../../Navigation';
import PreFooter from '../../components/PreFooter';
import React from 'react';

const Home = (): JSX.Element => {

	return (
		<div className={styles.home}>
			<Header />
			<Navigation />
			<PreFooter />
			<Footer />
		</div>
	);
};

export default Home;
