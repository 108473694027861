import styles from './TeamMember.module.scss';
import ActionButton from '../../../../components/Buttons/ActionButton';
import React from 'react';
import { TeamMemberType } from '../Team';

const style = {
	linkedIn: {
		backgroundColor: '#4648c6',
		color: '#fff',
		borderRadius: 8,
		width: 200,
		height: 48,
		fontSize: 18,
		letterSpacing: 0.2,
		textTransform: 'none',
		fontWeight: 'bold',
		marginTop: 30,
	},
};

interface TeamMemberProps {
	teamMember: TeamMemberType;
}

const TeamMember: React.FC<TeamMemberProps> = ({ teamMember }) => {
	return (
		<div className={styles['team-member']}>
			<div className={styles['team-member-img-container']}>
				<img
					alt={teamMember.name}
					src={teamMember.image}
					className={styles['team-member-img']}
				/>
			</div>
			<div className={styles['team-member-name']}>{teamMember.name}</div>
			<div className={styles['team-member-position']}>
				{teamMember.position}
			</div>
			<div className={styles['team-member-description']}>
				{teamMember.description}
			</div>
			{teamMember.url && (
				<ActionButton
					name='LinkedIn'
					onClick={(): void => {
						window.open(teamMember.url, '_blank');
					}}
					style={style.linkedIn}
				/>
			)}
		</div>
	);
};

export default TeamMember;
