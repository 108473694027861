import styles from './JobCard.module.scss';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { JobType } from '../JoinUs';
import { Link } from 'react-router-dom';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import React from 'react';
import ScheduleIcon from '@material-ui/icons/Schedule';

interface JobCardProps {
	job: JobType;
	sectionId: number;
}

const JobCard: React.FC<JobCardProps> = ({ job, sectionId }) => {
	return (
		<Link
			to={'/join-us/' + sectionId + '/' + job.id}
			className={styles['job-card-link']}
		>
			<div className={styles['job-card']}>
				<div className={styles['job-card-top']}>
					<span>{job.title}</span>
					<ArrowForwardIosIcon />
				</div>
				<div className={styles['job-card-bottom']}>
					<div className={styles['job-card-bottom-row']}>
						<ScheduleIcon style={{ marginRight: 15 }} />
						<span>{job.contractType}</span>
					</div>
					<div className={styles['job-card-bottom-row']}>
						<LocationOnIcon style={{ marginRight: 15 }} />
						<span>{job.location}</span>
					</div>
				</div>
			</div>
		</Link>
	);
};

export default JobCard;
