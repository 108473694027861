import { NewType } from '../News';
import NewCard from './NewCard';
import React from 'react';
import styles from './NewList.module.scss';

interface NewListProps {
	news: NewType[];
}

const NewList: React.FC<NewListProps> = ({ news }) => {
	return (
		<div className={styles['new-list']}>
			<div className={styles['section-container']}>
				{news.map(
					(specificNew: NewType, index: number): JSX.Element => (
						<NewCard new={specificNew} key={index} />
					)
				)}
			</div>
		</div>
	);
};

export default NewList;
