import AVGImage from '../images/Company/avg.png';
import GraphVCImage from '../images/Company/graphventures.jpg';
import HFImage from '../images/Company/hustlefund.png';
import Image2048 from '../images/Company/2048.png';
import { InvestorType } from '../../pages/Company/sections/Investors';
import MagicImage from '../images/Company/magicfund.png';
import NSFImage from '../images/Company/NSF.png';
import RootVCImage from '../images/Company/rootvc.png';
import VBImage from '../images/Company/venturebridge.jpg';
import WBImage from '../images/Company/work-bench.png';

const investors: InvestorType[] = [
	{
		image: RootVCImage,
	},
	{
		image: Image2048,
	},
	{
		image: NSFImage,
	},
	{
		image: AVGImage,
	},
	{
		image: GraphVCImage,
	},
	{
		image: MagicImage,
	},
	{
		image: WBImage,
	},
	{
		image: HFImage,
	},
	{
		image: VBImage,
	},
];

export default investors;
