import styles from "./DataExperts.module.scss";
import React from "react";
import { ReactComponent as Circle } from "../../../assets/images/DataExperts/circle.svg";
import { ReactComponent as Computer } from "../../../assets/images/DataExperts/computer.svg";
import { ReactComponent as Hand } from "../../../assets/images/DataExperts/hand.svg";

const DataExperts: React.FC = () => {
	return (
		<div className={styles.dataExpertsContainer}>
			<div
				className={`${styles["section-container"]} ${styles["section-container-extension"]}`}
			>
				<span className={styles.firstTitle}>
					Built by data experts to simplify data science and
					engineering
				</span>
				<div className={styles.subTitleContainer}>
					<p className={styles.subTitle}>
						Unified API allows data science applications to manage
						and query all supported data types in an easy-to-deploy, fully-managed or self-hosted database
						service
					</p>
				</div>
				<div className={styles.reasons}>
					<div id="divUnifiedAccess" className={styles.reason}>
						<div className={styles.title}>
							<Hand className={styles.image} />
						</div>
						<div className={styles.body}>
							<span>
								Natively supported images and videos along with
								necessary pre-processing and augmentation
								operations.
							</span>
						</div>
					</div>
					<div id="divMLReady" className={styles.reason}>
						<div className={styles.title}>
							<Computer className={styles.image} />
						</div>
						<div className={styles.body}>
							<span>
								Application and annotation metadata
								stored an in-memory knowledge
								graph for insightful queries and easy
								schema updates
							</span>
						</div>
					</div>
					<div id="divRobust" className={styles.reason}>
						<div className={styles.title}>
							<Circle className={styles.image} />
						</div>
						<div className={styles.body}>
							<span>
								Built-in similarity matching for
								high-dimensional feature vectors. Native support
								for k-nearest neighbor computation.
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DataExperts;
