import styles from "./Intro.module.scss";
import React from "react";
import { Avatar, Button, Grid, makeStyles, createTheme, ThemeProvider } from "@material-ui/core";
import { CaseStudyType } from "../CaseStudy";
import { downloadPDF } from "../../../helpers/downloadPDF";
import linkedinIcon from "../../../assets/images/CaseStudies/Icons/linkedin.svg";
import pdfIcon from "../../../assets/images/CaseStudies/Icons/pdf.svg";
import shareIcon from "../../../assets/images/CaseStudies/Icons/share.svg";
import vectorIcon from "../../../assets/images/CaseStudies/Icons/vectorIcon.svg";
import xIcon from "../../../assets/images/CaseStudies/Icons/x.svg";

interface IntroProps {
	study: CaseStudyType;
}

const theme = createTheme({
	overrides: {
		MuiButton: {
			root: {
				minWidth: 0,
			},
		},
	},
});
const useStyles = makeStyles((theme) => ({
	button: {
		margin: theme.spacing(0),
		padding: "0 10px",
	},
	avatar: {
		width: "25px !important",
		height: "25px !important",
	},
}));

const Intro: React.FC<IntroProps> = ({ study }) => {
	const classes = useStyles();

	function handleCopyUrlToShare(text: string) {
		const input = document.createElement("input");

		input.value = text;
		document.body.appendChild(input);

		input.select();
		input.setSelectionRange(0, 99999);

		document.execCommand("copy");

		document.body.removeChild(input);
	}

	return (
		<div className={styles.intro}>
			<Grid
				container
				className={styles.container}
				id={`${study?.id}`}
				alignItems="center"
			>
				<Grid item xs={6} className={styles.containerDetailsGridItem}>
					<div className={styles.detailsGridItem}>
						<text>{`Case Study`}</text>
						<div className={styles.title}>
							{
								study?.card.link ? (
									<a href={study?.card.link} target="_blank" rel="noreferrer">
										<h1>{`${study?.card.title}`}</h1>
									</a>
								) : (
									<h1>{`${study?.card.title}`}</h1>
								)
							}
						</div>
						<div className={styles.buttonContainer}>
							<div className={styles.rectangleContainer}>
								{study.card.pdf &&
									study.card.pdf.length > 0 && (
										<>
											<div
												className={styles.pdfButton}
												onClick={() => downloadPDF(study.card.pdf || "")}
											>
												<img src={pdfIcon} style={{ width: 21, height: 27 }} />
												<span className={styles.pdfText}>{`download pdf`}</span>
											</div>
											<img
												src={vectorIcon}
												className={styles.divider}
											/>
										</>
									)}
								<ThemeProvider theme={theme}>
									<Button
										className={classes.button}
										href={`https://twitter.com/intent/tweet?text=Hey!%20Check%20out%20this%20Case%20Study!%20${window.location.href}%20%40ApertureData&original_referer=https://aperturedata.io/`}
										target="_blank"
										rel="noreferrer"
									>
										<Avatar
											src={xIcon}
											className={classes.avatar}
										/>
									</Button>
									{/* <Button
												className={classes.button}
												href={data[0]?.reddit}
												target='_blank'
												rel='noreferrer'
											>
												<Avatar src={redditIcon} className={classes.avatar} />
											</Button> */}
									<Button
										className={classes.button}
										href={`https://www.linkedin.com/share?text=Hey!%20Check%20out%20this%20Case%20Study!%20${window.location.href}%20%40ApertureData&original_referer=https://aperturedata.io/`}
										target="_blank"
										rel="noreferrer"
									>
										<Avatar
											src={linkedinIcon}
											className={classes.avatar}
										/>
									</Button>
									<Button
										className={classes.button}
										href={"#"}
										onClick={() =>
											handleCopyUrlToShare(
												window.location.href
											)
										}
									>
										<Avatar
											src={shareIcon}
											className={classes.avatar}
										/>
									</Button>
								</ThemeProvider>
							</div>
						</div>
					</div>
				</Grid>
				<Grid item xs={6} className={styles.containerImageGridItem}>
					<div className={styles.imageGridItem}>
						<img
							src={study?.card.image}
							alt={study?.card.title}
							className={styles.image}
						/>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

export default Intro;
