import styles from './FilterOptions.module.scss';
import React, { useEffect, useState } from 'react';
import FilterOptionCard from './Case/FilterOptionsCard';
import { FilterOptionType } from './FilterOptionInterface';
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, createStyles, makeStyles } from '@material-ui/core';
import { UseViewPortWidth } from '../../pages/Blog/Hook/UseViewPortWidth';

interface FilterProps {
	cases: FilterOptionType[];
	handleFilterOptionSelected: (state: string) => void;
	filterTag?: string;
}

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			display: 'flex',
		},
		paper: {

		},
		MuiMenuItem: {
			backgroundColor: '#5856F0',
			width: '100%',
		},
		MuiSelected: {
			'&: hover': {

				backgroundColor: '#fffff',
			},
			color: '#FFFFFF',
			fontWeight: 700,

		},
	}));

const FilterOptions: React.FC<FilterProps> = ({ cases, handleFilterOptionSelected, filterTag = "" }) => {
	const [open, setOpen] = React.useState(false);
	const [optionState, setOptionState] = useState<string>(filterTag !== "" ? `${filterTag} Tag` : 'All');
	const [firstOptions, setFirstOptions] = useState<FilterOptionType[]>([]);
	const [secondOptions, setSecondOptions] = useState<FilterOptionType[]>([]);
	const [menuCounter, setMenuCounter] = useState<number>(5);

	const viewPortWidth = UseViewPortWidth();

	const classes = useStyles();
	const anchorRef = React.useRef<HTMLDivElement>(null);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: React.MouseEvent<EventTarget>) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return;
		}

		setOpen(false);
	};

	function handleListKeyDown(event: React.KeyboardEvent) {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		}
	}

	const handleOption = (option: string) => {
		handleFilterOptionSelected(option);
		setOptionState(option);

	};

	useEffect(() => {
		setMenuCounter(cases.length > 6 ? 5 : 6);

		const handleSepareArray = () => {
			const primeraParte = cases.slice(0, menuCounter);
			const segundaParte = cases.slice(menuCounter);

			setFirstOptions(primeraParte);
			setSecondOptions(segundaParte);
		};

		handleSepareArray();
	}, [cases, menuCounter]);

	return (
		<>
			{viewPortWidth > 950 ?
				<div className={styles.filterContainer}>
					<div className={styles['section-container']}>
						<div onClick={() => handleOption('All')} className={`${styles['case-card']} ${optionState === 'All' && styles['active']} `}>
							<div className={styles['case-text']}>
								<span className={styles['case-title']}>{'All'}</span>
							</div>
						</div>
						{firstOptions.map(
							(specificOption: FilterOptionType): JSX.Element => (
								<FilterOptionCard case={specificOption} key={specificOption.type} handleOption={handleOption} optionState={optionState} />
							)
						)}
						{
							(cases.length > menuCounter) &&
							<div className={`${styles['case-card']} ${optionState === 'More...' && styles['active']} `} onClick={handleToggle} ref={anchorRef}>
								<div className={styles['case-text']}>
									<span className={styles['case-title']}>{'More ...'}</span>
								</div>
								<Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
									{({ TransitionProps }) => (
										<Grow
											{...TransitionProps}
										>
											<Paper className={classes.paper}>
												<ClickAwayListener onClickAway={handleClose}>
													<MenuList className={classes.MuiMenuItem} autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
														{secondOptions.map(
															(specificOption: FilterOptionType): JSX.Element => (
																<MenuItem key={specificOption.type} className={classes.MuiSelected} onClick={(e) => { handleOption(specificOption.type); handleClose(e); }}>{specificOption.type}</MenuItem>
															)
														)}
													</MenuList>
												</ClickAwayListener>
											</Paper>
										</Grow>
									)}
								</Popper>
							</div>
						}

					</div>
				</div> :
				<div className={styles.menuMobileFilter} onClick={handleToggle} ref={anchorRef}>
					<div className={styles.menuMobileTitle}>
						<div></div>
						<div><span>	{optionState.includes("Tag") ?
							`${optionState}` : `${optionState} Categories`
							}
						</span></div>
						<div><span>
							<svg xmlns="http://www.w3.org/2000/svg" width="23" height="14" viewBox="0 0 23 14" fill="none">
								<path d="M20.0984 2.80078L11.4985 11.4008L2.89844 2.80078" stroke="white" strokeWidth="4" strokeMiterlimit="2.6131" strokeLinecap="round" strokeLinejoin="round" />
							</svg>
						</span></div>

					</div>
					<Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
						{({ TransitionProps }) => (
							<Grow
								{...TransitionProps}
							>
								<Paper className={classes.paper}>
									<ClickAwayListener onClickAway={handleClose}>
										<MenuList className={classes.MuiMenuItem} autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
											<MenuItem key={'All'} className={classes.MuiSelected} onClick={(e) => { handleOption('All'); handleClose(e); }}>{'All'}</MenuItem>
											{[...firstOptions, ...secondOptions].map(
												(specificOption: FilterOptionType): JSX.Element => (
													<MenuItem key={specificOption.type} className={classes.MuiSelected} onClick={(e) => { handleOption(specificOption.type); handleClose(e); }}>{specificOption.type}</MenuItem>
												)
											)}
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>
				</div>
			}
		</>
	);
};

export default FilterOptions;
