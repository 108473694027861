import styles from './Error.module.scss';
import React from 'react';

const Error = (): JSX.Element => {
	return (
		<div className={styles.error}>
			<header className={styles['error-header']}>
				<p>
					<code>Ups!</code>, looks like an error.
				</p>
			</header>
		</div>
	);
};

export default Error;
