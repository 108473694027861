import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const blogSuscribe = async ( email: string): Promise<boolean> => {
	let result = true;

	await axios.post(`${API_URL}/mails/subscribe`, { email }).catch(() => {
		result = false;
	});

	return result;
};

const contactMail = async (firstName: string, lastName: string, email: string, phone: string, comments: string): Promise<boolean> => {
	const subject = 'Contact info';
	let result = true;

	await axios.post(`${API_URL}/mails/contact`, { firstName, lastName, email, phone, comments, subject }).catch(() => {
		result = false;
	});

	return result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const jobPositionMail = async (firstName: string, lastName: string, email: string, phone: string, comments: string, jobTitle: string, linkedIn: string, attachment: any): Promise<boolean> => {
	const subject = jobTitle;
	let result = true;
	const bodyFormData = new FormData();

	bodyFormData.set('firstName', firstName);
	bodyFormData.set('lastName', lastName);
	bodyFormData.set('email', email);
	bodyFormData.set('phone', phone);
	bodyFormData.set('comments', comments);
	bodyFormData.set('linkedIn', linkedIn);
	bodyFormData.set('subject', subject);
	bodyFormData.append('attachment', attachment);

	await axios({
		method: 'post',
		url: `${API_URL}/mails/apply`,
		data: bodyFormData,
		headers: { 'Content-Type': 'multipart/form-data' },
	}).catch(() => {
		result = false;
	});

	return result;
};

const requestDemoMail = async (email: string, type: string): Promise<string | undefined> => {
	try {
		const response = await axios.post(`${API_URL}/demo/request`, { email, type });

		return `https://${response.data}`;
	} catch (error) {
		return undefined;
	}
};

const requestSelfSetupMail = async (email: string): Promise<string | undefined> => {
	try {
		const response = await axios.post(`${API_URL}/self-setup`, { email });

		return response.status.toString();
	} catch (error) {
		return undefined;
	}
};

const freeTrialMail = async (email: string): Promise<boolean> => {
	const subject = 'Free trial required';
	let result = true;

	await axios.post(`${API_URL}/mails/free-trial`, { email, subject }).catch(() => {
		result = false;
	});

	return result;
};

export default {
	blogSuscribe,
	contactMail,
	freeTrialMail,
	jobPositionMail,
	requestDemoMail,
	requestSelfSetupMail,
};
