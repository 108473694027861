import styles from './ContactForm.module.scss';
import ActionButton from '../../../components/Buttons/ActionButton';
import MailRepository from '../../../api/mail';
import MailService from '../../../services/mail';
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';

const style = {
	submit: {
		backgroundColor: '#4648c6',
		color: '#fff',
		borderRadius: 8,
		width: 200,
		height: 48,
		fontSize: 18,
		letterSpacing: 0.2,
		textTransform: 'none',
		fontWeight: 'bold',
		marginRight: 10,
	},
};

const ContactForm: React.FC = () => {
	const [firstName, setFirstName] = React.useState('');
	const [lastName, setLastName] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [phoneNumber, setPhoneNumber] = React.useState('');
	const [comments, setComments] = React.useState('');
	const [snackbarMessage, setSnackbarMessage] = React.useState('');
	const [openSnackbar, setOpenSnackbar] = React.useState(false);

	const showSnackbarMessage = (): void => {
		setOpenSnackbar(true);
		setTimeout(() => {
			setOpenSnackbar(false);
		}, 3000);
	};

	const checkAndSubmit = (): void => {
		if (!email || !MailService.isValidEmail(email)) {
			setSnackbarMessage(
				'The email is not valid. Please review the data and try again.'
			);
			showSnackbarMessage();
		} else if (!firstName || !lastName) {
			setSnackbarMessage(
				'There is some information missing. Please review it and try again.'
			);
			showSnackbarMessage();
		} else {
			MailRepository.contactMail(
				firstName,
				lastName,
				email,
				phoneNumber,
				comments
			).then((result: boolean) => {
				if (result) {
					setSnackbarMessage('Email sent!');
				} else {
					setSnackbarMessage(
						'There was an error while trying to send email. Please review the data and try again.'
					);
				}

				showSnackbarMessage();
			});
		}
	};

	return (
		<div className={styles['contact-section']}>
			<div className={styles['contact-row']}>
				<TextField
					label='First Name'
					variant='outlined'
					required={true}
					className={styles['contact-field']}
					value={firstName}
					onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
						setFirstName(event.target.value)
					}
				/>
				<TextField
					label='Last Name'
					variant='outlined'
					required={true}
					className={styles['contact-field']}
					value={lastName}
					onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
						setLastName(event.target.value)
					}
				/>
			</div>
			<div className={styles['contact-row']}>
				<TextField
					label='Email'
					variant='outlined'
					required={true}
					className={styles['contact-field']}
					type='mail'
					value={email}
					onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
						setEmail(event.target.value)
					}
				/>
				<TextField
					label='Phone Number'
					variant='outlined'
					required={false}
					className={styles['contact-field']}
					value={phoneNumber}
					onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
						setPhoneNumber(event.target.value)
					}
				/>
			</div>
			<div className={styles['contact-row']}>
				<TextField
					label='Comments'
					variant='outlined'
					className={styles['contact-field']}
					multiline={true}
					rows={3}
					value={comments}
					onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
						setComments(event.target.value)
					}
				/>
			</div>
			<div className={styles['contact-row']}>
				<ActionButton
					name='Submit'
					onClick={checkAndSubmit}
					style={style.submit}
					isDisabled={!(firstName && lastName && email)}
				/>
			</div>
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				open={openSnackbar}
				autoHideDuration={6000}
				message={snackbarMessage}
			/>
		</div>
	);
};

export default ContactForm;
