import styles from './Intro.module.scss';
import React from 'react';

const Intro: React.FC = () => {
	return (
		<div className={styles['intro-resources']}>
			<div className={styles['section-container']}>
				<h1>ApertureDB Resources</h1>
				<h2>Explore our library of informative articles, videos, and product information to learn more about how ApertureDB can streamline multimodal data management for analytics.</h2>
				<a href='/contact-us'>Questions? Contact Us</a>
			</div>
		</div>
	);
};

export default Intro;
