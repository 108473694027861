export const seedAnnouncement = `<html>
<head>
	<meta content="text/html; charset=UTF-8" http-equiv="content-type">
</head>
<body class="c15 doc-content">
	<p class="c12 c7">
		<span class="c10"></span>
	</p>
	<p class="c2">
		<span class="c4">Where we are going next with our $3M Seed</span>
		<span>Funding.</span>
	</p>
	<p class="c2">
		<span>Databases have always been key to solving important data search and management problems, especially as the volume of</span>
		<span>data grows in magnitude</span>
		<span>. If you are dealing with</span>
		<span>numbers</span>
		<span>, emails, IDs, or even documents, it is now easy to find a database that will let you store and search across scores of these, quickly and easily.</span>
		<span class="c19"><i>However, if you are dealing with large quantities of images, videos, and related information such as objects within the image or source of capture, there isn&rsquo;t a database in the market that understands how to support these complex data types&hellip;until now!</i></span>
		<span class="c10">&nbsp;</span>
	</p>
	<h1 class="c14" id="h.xq9vccbs4auq">
		<span class="c8">The Problem</span>
	</h1>
	<p class="c2">
		<span>We have been viewing and streaming images and videos for quite a while now. Why is it suddenly important for organizations to search and access them any differently? Images and videos capture a significant amount of information in pixels. Data science, in particular m</span>
		<span>achine learning (ML) and computer vision (CV)-based techniques have now unlocked the inherent value of visual (image/video) data for real-world applications, without the need for manual inspection.</span>
		<span class="c10">Naturally, companies across various industries are increasingly using these techniques to power digital experiences such as better patient care through medical imaging, better product recommendations through visual similarity matching, sustainable farming through better farm views, detecting flaws in methodology through visual inspection and much more.</span>
	</p>
	<p class="c2 c7">
		<span class="c10"></span>
	</p>
	<p class="c2">
		<span>A few years back, when my co-founder,</span>
		<span class="c13">
			<a class="c0" href="https://www.linkedin.com/in/luis-remis/" target="_blank">Luis Remis,</a>
		</span>
		<span>&nbsp;and I were at Intel Labs, we observed how</span>
		<span>such applications rely on and create high volumes of visual content for these insights, and this volume is predicted to grow exponentially</span>
		<span>in the coming years</span>
		<span class="c10">. In addition, each individual image or video can itself be quite large. In such cases, metadata such as application context, labels, feature vectors and relationships among these become key to meaningfully using this data. With such a variety and volume of data types and access patterns to deal with, data science teams are left with the option of manually stitching up their own do-it-yourself (DIY) systems for managing visual data within their CV and ML workflows. &nbsp; &nbsp;</span>
	</p>
	<h1 class="c14" id="h.3f4f54c4t7aa">
		<span class="c8">Don&rsquo;t Do It Yourself</span>
	</h1>
	<p class="c9">
		<span class="c10">A typical DIY solution involves steps such as: &nbsp;</span>
	</p>
	<ul class="c11 lst-kix_n76e1d722ot1-0 start">
		<li class="c1 li-bullet-0">
			<span class="c10">Uniquely naming and storing images / videos in cloud buckets or file storage</span>
		</li>
		<li class="c1 li-bullet-0">
			<span class="c10">Storing metadata and annotations in files, databases, or both</span>
		</li>
		<li class="c1 li-bullet-0">
			<span class="c10">Writing scripts to search the metadata, find the links, and then fetch this data from wherever it resides</span>
		</li>
		<li class="c1 li-bullet-0">
			<span class="c10">In cases such as creating training data or displaying this data to other users, the data might need some preprocessing such as creating thumbnails.</span>
		</li>
		<li class="c1 li-bullet-0">
			<span class="c10">For use cases such as personalized recommendations, similarity search using visual embeddings can be quite powerful. Supporting this additional feature requires new tools or libraries, introducing yet another software component either off-the-shelf or developed in-house</span>
		</li>
	</ul>
	<p class="c4">
		<span>Aside from learning how to deal with each component and maintaining them, the API differences among these disparate systems not only require plumbing but also open up the data scientist to inconsistencies and bugs that can prove elusive. Data science, particularly ML, keeps improving as researchers improve their data and methods. That means the information that is extracted from the data continues to evolve. With fixed schema databases, this can prove challenging to update. DIY systems also lack robustness and often come at the expense of performance, particularly at scale. With DIY systems</span>
		<span class="c19"><i>45%+ of data scientists&rsquo; time is wasted because of ill-designed data infrastructure</i></span>
		<span class="c10">&nbsp;that doesn&rsquo;t meet their needs. &nbsp;</span>
	</p>
	<p class="c4">
		<span>This isn&rsquo;t just an end user engineering problem, it also has</span>
		<span class="c6"><b>significant ramifications for businesses</b></span>
		<span>that are viewing ML driven solutions as turnkey to stay ahead of their competitors and provide increasingly better experiences to their customers.</span>
	</p>
	<h1 class="c9 c23" id="h.wuk8mvyj38gb">
		<span>ApertureDB: A Purpose-Built Solution for Visual Data and Analytics</span>
	</h1>
	<p class="c4">
		<span>As part of our research at Intel, Luis and I experienced first hand the complexity of setting up visual data management for such applications since we couldn&rsquo;t find a single system that could address both visual data and data science requirements. The more we searched, the more we noticed infrastructure for visual data being a big challenge for teams of data scientists and CV / ML engineers given the DIY solutions described above. With the magnitude of the problem growing, the systems and computer vision experience we brought as a team gave us the confidence that we were the right people to solve this problem and redefine visual data management for data science and ML. We therefore spun out</span>
		<span class="c13">
			<a class="c0" href="http://aperturedata.io" target="_blank">ApertureData</a>
		</span>
		<span class="c10">.</span>
	</p>
	<p class="c2">
		<span>Our product is a specialized database, ApertureDB, for visual data such as images, videos, feature vectors, and associated metadata like annotations. ApertureDB stands uniquely differentiated from other databases and infrastructure tools because it natively supports images, videos, and annotations. Naturally, we also provide necessary preprocessing operations like zooming, cropping and sampling videos. We manage the metadata information as a knowledge graph to enable complex visual searches utilizing the relationships between various entities. Since feature vectors can be used to describe content in images or frames, we also offer similarity search using feature vectors.</span>
		<span class="c6"><b>For our users, all these capabilities are supported by one database behind a unified API.</b></span>
	</p>
	<p class="c2 c7">
		<span class="c10"></span>
	</p>
	<p class="imgContainer">
		<span>
			<img
				alt=""
				src="https://aperturedata-public.s3.us-west-2.amazonaws.com/website_images/seed_announcement/image1.png"
				title=""
			>
		</span>
	</p>
	<p class="c2">
		<span>With its unified approach around visual data, ApertureDB removes the need for teams to concoct and manage complex Frankenstein systems. We have tested ApertureDB with over 1.3+ billion metadata entities, connections, and over 300+ million images. ApertureDB is up to</span>
		<span class="c13">
			<a class="c0" href="http://vldb.org/pvldb/vol14/p3240-remis.pdf" target="_blank">35x faster</a>
		</span>
		<span>&nbsp;compared to popular DIY systems on metadata-based search queries performed over 100 million images.</span>
	</p>
	<p class="c2 c7">
		<span class="c10"></span>
	</p>
	<p class="c2">
		<span>Data scientists in the visual intelligence and camera intelligence teams at Fortune 100 companies use ApertureDB to save months of data engineering when accessing data for CV / ML pipelines. They use ApertureDB as a unified repository containing product images, labels, embeddings, and product metadata for data science teams. ApertureDB&rsquo;s</span>
		<span>easy-to-use</span>
		<span class="c13">
			<a class="c0" href="http://docs.aperturedata.io" target="_blank">API</a>
		</span>
		<span>&nbsp;and seamless integration with ML frameworks like PyTorch saves them days when training models, overlaying segmentation masks, and searching by labels.</span>
		<span>&nbsp;They use our similarity search features to build their visual recommendation engine. We have given them a way to not only manage labels along with images captured by retail cameras but also the ability to easily manage user access to simplify working with third party labelers and visualize existing annotations through our REST-based graphical frontend. We have also been working with</span>
		<span>companies</span>
		<span>&nbsp;in the</span>
		<span>healthcare,</span>
		<span>&nbsp;smart agriculture and visual inspection space where the importance and use of visual data is growing rapidly.</span>
	</p>
	<p class="c12 c7">
		<span class="c10"></span>
	</p>
	<h1 class="c14" id="h.73hr4ke57o73">
		<span class="c8">Where Our New Funding Will Take Us</span>
	</h1>
	<p class="c4">
		<span>We have raised $3M in funding led by Root Ventures with participation from 2048 VC, Work-Bench, Alumni Ventures Group, Graph Ventures, Magic Fund, Hustle Fund, and a number of high caliber angels from Datadog, Github, Docker</span>
		<span class="c13">
			<a class="c0" href="https://aperturedata.io/company" target="_blank">and more</a>
		</span>
		<span>&nbsp;(Read about it in</span>
		<span><a href="https://techcrunch.com/2022/02/08/aperturedata-is-building-a-database-focussed-on-images-with-3m-seed/" target="_blank">TechCrunch</a></span>
		<span>)</span>
		<span>. This funding will position us to grow our team and customer base.</span>
		<span>It will accelerate the development of ApertureDB&rsquo;s innovative ML-ready visual data management support, e.g. support for more complex annotations and integrations with more labeling / MLOps frameworks. It will also help us improve our enterprise features and offer ApertureDB as a managed service to our users.</span>
	</p>
	<h2 class="c21" id="h.vvn42hia8l4k">
		<span class="c5 c24">Partner with us - use ApertureDB</span>
	</h2>
	<p class="c4">
		<span class="c10">ApertureDB will alleviate the need for expertise in complex-data infrastructure &ndash; a scarce skill set for companies of all sizes. Given the growing shortage of qualified data scientists, it is beneficial for companies to invest in solutions that can improve a data science team&rsquo;s productivity. In short, ApertureDB will provide companies with a single unified system that integrates well with data science pipelines, enables rapid data engineering, and reduces the frustrations, costs, and implementation challenges of integrating multiple platforms.</span>
	</p>
	<p class="c2">
		<span>If your organization uses or intends to use ML on visual data (small or large team) or you are simply curious about our technology, our approach to infrastructure development, and where we are headed, please contact us at team@aperturedata.io or sign up</span>
		<span class="c13">
			<a class="c0" href="https://aperturedata.io/demo-request" target="_blank">&nbsp;for a free trial</a>
		</span>
		<span>. If you&rsquo;re excited to join an early stage startup and &nbsp;make a big difference,</span>
		<span class="c13">
			<a class="c0" href="https://aperturedata.io/join-us" target="_blank">we&rsquo;re hiring</a>
		</span>
		<span>. Last but not least, we will be documenting our journey on our blog, subscribe</span>
		<span class="c13">
			<a class="c0" href="https://forms.gle/BdxgdUndw5FcsBQP6" target="_blank">here</a>
		</span>
		<span class="c10">.</span>
	</p>
	<p class="c6">
		<span class="acknowledge" >I want to acknowledge the insights and valuable edits from the Work-Bench team,
            Steve Huber, Jaime Fawcett, and Romain Cledat.</span >
	</p>
	<p class="c7 c12">
		<span class="c10"></span>
	</p>
	<p class="c12 c7">
		<span class="c20"></span>
	</p>
</body>
</html>

`;
