import { CaseType } from '../../pages/UseCases/UseCases';
import visualInspectionIcon from '../images/Cases/Icons/visualInspectionIcon.png';
import mediaAnalyticsIcon from '../images/Cases/Icons/mediaAnalyticsIcon.png';
import medicalImagingIcon from '../images/Cases/Icons/medicalImagingIcon.png';
import eCommerceIcon from '../images/Cases/Icons/eCommerceIcon.png';
import smartRetailIcon from '../images/Cases/Icons/smartRetailIcon.png';
import smartAgricultureIcon from '../images/Cases/Icons/smartAgricultureIcon.png';
import eCommerceImg from '../images/Cases/ImageDetails/eCommerce.png';
import mediaAnalyticsImg from '../images/Cases/ImageDetails/mediaAnalytics.png';
import medicalImagingImg from '../images/Cases/ImageDetails/medicalImaging.png';
import smartAgricultureImg from '../images/Cases/ImageDetails/smartAgriculture.svg';
import smartRetailImg from '../images/Cases/ImageDetails/smartRetail.svg';
import visualInspectionImg from '../images/Cases/ImageDetails/visualInspection.png';

const cases: CaseType[] = [
	{
		icon: eCommerceIcon,
		name: 'E-COMMERCE',
		title: 'E-commerce',
		description:
			'Use metadata, images, and embeddings for personalized recommendations, customer insights, and attribute classification',
		image: eCommerceImg,
		bullets: [
			'Easily prepare training / classification datasets with video clips and annotations',
			'Update schema with ease as new models help recognize newer attributes on existing data',
			'Use labels, product and model metadata, and their relationships with images for insightful analytics before model retraining',
			'Rely on built-in similarity matching (K-NN) using visual features for creating personalized recommendations, attribute classification',
		],
	},
	{
		icon: smartRetailIcon,
		name: 'RETAIL',
		title: 'Smart Retail',
		description:
			'Use metadata and videos for customer insights, monitoring stores, cashierless checkout',
		image: smartRetailImg,
		bullets: [
			'Use store videos, frames, clips, and labels easily to prepare training/classification datasets',
			'Add new clips, update labels and schema with ease as new models or labeling tools help add newer attributes on existing data',
			'Use labels, product and model metadata, and their relationships with videos for insightful analytics',
			'Rely on built-in similarity matching (K-NN) using video features for person re-identification, object detection',
		],
	},
	{
		icon: visualInspectionIcon,
		name: 'VISUAL',
		title: 'Visual Inspection',
		description:
			'Find images, frames, and video intervals based on keywords, labels, similarity matches',
		image: visualInspectionImg,
		bullets: [
			'Easily prepare training / classification datasets with store video clips and annotations',
			'Quickly access video intervals and regions of interest in large images or video frames',
			'Use built-in similarity matching for high-dimensional feature vectors to help with classification and finding close matches',
			'Efficiently perform complex IoU operations to identify if an object falls within any regions of interest regardless of the shape of annotation',
		],
	},
	{
		icon: smartAgricultureIcon,
		name: 'AGRICULTURE',
		title: 'Smart Agriculture',
		description:
			'Find relevant images based on labels, geolocation, and regions of interest',
		image: smartAgricultureImg,
		bullets: [
			'Easily prepare training/classification datasets using geographically focused visual data',
			'Quickly access regions of interest on large images and video frames with native support for tagging',
			'Explore insightful analytics through a knowledge graph of metadata (crops, soil, other sensors, etc.) and associated images',
			'Visualize contents of the dataset whether they are images or videos',
		],
	},
	{
		icon: medicalImagingIcon,
		name: 'MEDICAL',
		title: 'Medical Imaging',
		description:
			'Prepare large scale, multi-modal medical scans for ML and clinical studies',
		image: medicalImagingImg,
		bullets: [
			'Ingest thousands of 2D/3D scans with metadata per second',
			'Utilize built-in support for regions of interest, tagging, and data pre-processing on the fly for simpler data pipelines and fewer copies of intermediate data',
			'Query metadata from multiple modalities of patient images to create cohorts or ML training/classification datasets',
			'Use on-the-fly visual pre-processing to evaluate various image augmentations to improve model performance',
		],
	},
	{
		icon: mediaAnalyticsIcon,
		name: 'MEDIA',
		title: 'Media Analytics',
		description:
			'Find relevant images based on labels, geolocation, and regions of interest',
		image: mediaAnalyticsImg,
		bullets: [
			'Easily prepare training/classification datasets using geographically focused visual data',
			'Quickly access regions of interest on large images and video frames with native support for tagging',
			'Explore insightful analytics through a knowledge graph of metadata (crops, soil, other sensors, etc.) and associated images',
			'Visualize contents of the dataset whether they are images or videos',
		],
	},
];

export default cases;
