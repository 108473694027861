import styles from "./FaqList.module.scss";
import FaqRow from "./FaqRow";
import { FAQType } from "../Faq";
import React from "react";

interface FaqListProps {
	faqs: FAQType[];
}

const header =
	"ApertureData offers a purpose-built database, ApertureDB, for multimodal data such as images, videos, feature vectors, and associated metadata including annotations.";

const FaqList: React.FC<FaqListProps> = ({ faqs }) => {
	return (
		<div className={styles["faq-list"]}>
			<div className={styles["section-container"]}>
				<div className={styles.header}>{header}</div>
				{faqs.map(
					(faq: FAQType): JSX.Element => (
						<FaqRow faq={faq} key={faq.title} />
					)
				)}
			</div>
		</div>
	);
};

export default FaqList;
