import styles from "./OurSolution.module.scss";
import { ReactComponent as MLReady } from "../../../assets/images/Landing/mlready.svg";
import React from "react";
import { ReactComponent as Robust } from "../../../assets/images/Landing/robust.svg";
import { ReactComponent as Unified } from "../../../assets/images/Landing/unified.svg";

const OurSolution: React.FC = () => {
	return (
		<div className={styles.ourSolutionContainer}>
			<div
				className={`${styles["section-container"]} ${styles["section-container-extension"]}`}
			>
				<span className={styles.firstTitle}>
					Our Solution: ApertureDB
				</span>
				<span className={styles.subTitle}>
					A purpose-built database that understands multimodal data and
					data science requirements
				</span>
				<div className={styles.reasons}>
					<div id="divUnifiedAccess" className={styles.reason}>
						<div className={styles.title}>
							<Unified className={styles.image} />
							<span>Unified Access</span>
						</div>
						<div className={styles.body}>
							<span>
								Rapid search and access through our unified
								API to metadata, images, videos, and
								pre-processing support. This approach results in
								a single source of truth, reducing complexity
								associated with multiple sources of data and
								metadata
							</span>
						</div>
					</div>
					<div id="divMLReady" className={styles.reason}>
						<div className={styles.title}>
							<MLReady className={styles.image} />
							<span>ML-ready</span>
						</div>
						<div className={styles.body}>
							<span>
								Seamless integration within existing and new
								analytic pipelines in a cloud-agnostic manner.
								Native support for ML features like labels,
								indexing descriptors, dataset management, and
								other rapidly evolving capabilities.
							</span>
						</div>
					</div>
					<div id="divRobust" className={styles.reason}>
						<div className={styles.title}>
							<Robust className={styles.image} />
							<span>Robust Architecture</span>
						</div>
						<div className={styles.body}>
							<span>
								High performance, scalable data management with
								essential security, privacy, and logging support
								required by multimodal data. Remove technical
								debt for significantly lower maintenance and
								subscription costs.
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OurSolution;
