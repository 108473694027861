import React from "react";
import styles from "./CaseRow.module.scss";
import { CaseStudyType } from "../../../CaseStudy/CaseStudy";
import { Button, Grid, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import pdf from "../../../../assets/images/CaseStudies/Icons/pdf.svg";
import { downloadPDF } from "../../../../helpers/downloadPDF";

interface CaseStudyProps {
	caseStudy: CaseStudyType;
}

const CaseRow: React.FC<CaseStudyProps> = (props) => {
	const navigate = useNavigate();

	return (
		<div className={styles.caseRowContainer}>
			<Grid container className={styles.caseRow} id={`${props.caseStudy.id}`}>
				<Grid item xs={4} className={styles.image}>
					<div className={styles.firstGridItem}>
						<img
							src={props.caseStudy.card.image}
							alt={props.caseStudy.card.title}
							className={styles.caseImage}
						/>
						{props.caseStudy.card.pdf &&
							props.caseStudy.card.pdf.length > 0 && (
								<Button
									className={styles.caseButton}
									style={{ textTransform: "lowercase" }}
									onClick={() =>
										downloadPDF(
											props.caseStudy.card.pdf || ""
										)
									}
								>
									{`download pdf version\u00A0`}
									<img src={pdf} alt="pdf" />
								</Button>
							)}
					</div>
				</Grid>
				<Grid item xs={8} className={styles.detailsRow}>
					<div className={styles.containerDetailsRow}>
						<div className={styles.middleGridItem}>
							<Typography className={styles.caseDate}>
								{props.caseStudy.card.date}
							</Typography>
							<Typography className={styles.caseTitle}>
								{props.caseStudy.card.title}
							</Typography>
							<Typography className={styles.caseDescription}>
								{props.caseStudy.card.description}
							</Typography>
							<Button
								className={styles.caseReadMore}
								style={{ textTransform: "lowercase" }}
								onClick={(): void => navigate(`./${props.caseStudy.id}`)}>
								{`read more`}
							</Button>
						</div>
						<div className={styles.lastGridItem}>
							<Typography className={styles.caseComment}>
								{`"${props.caseStudy.card.comment}"`}
							</Typography>
							<Typography className={styles.caseAuthorComment}>
								{props.caseStudy.card.authorComment}
							</Typography>
							<Typography className={styles.caseAuthorPosition}>
								{props.caseStudy.card.authorPosition}
							</Typography>
						</div>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

export default CaseRow;
