export const similaritySearch = `<html>
<head>
	<meta content="text/html; charset=UTF-8" http-equiv="content-type" />
</head>
<body class="c22 doc-content">
	<p class="c3 c7"><span class="c0"></span></p>
	<p class="c3">
		<span
			>Vector databases have recently been getting increased recognition due
			to the role they can play for LLMs and semantic search. </span
		><span class="c5"
			><a
				class="c2"
				href="https://towardsdatascience.com/milvus-pinecone-vespa-weaviate-vald-gsi-what-unites-these-buzz-words-and-what-makes-each-9c65a3bd0696"
				target="_blank">This article</a
			></span
		><span
			>&nbsp;does a great job summarizing quite a few well-known vector
			databases along with their pros and cons.
		</span>
	</p>
	<p class="c3 c7"><span class="c0"></span></p>
	<p class="c3">
		<span class="c0"
			>We work with, and talk to, many users focusing on images and videos
			(visual data) based analytics. Given that we have been working on vector
			search and classification for a few years now in the context of finding
			similar items for visual recommendations, and vector classification with
			our smart retail and e-commerce customers, we thought it would be
			interesting to understand what vector databases offer in the context of
			computer vision use cases, and evaluate them from the perspective of
			what is needed to simplify visual data pipelines for data scientists and
			engineers.</span
		>
	</p>
	<h1 class="c12" id="h.468r1a3zgk40">
		<span class="c1">Use Cases Based on Visual Embeddings</span>
	</h1>
	<p class="c3">
		<span class="c5"
			><a
				class="c2"
				href="https://cloud.google.com/blog/topics/developers-practitioners/meet-ais-multitool-vector-embeddings"
				target="_blank">This article</a
			></span
		><span class="c0"
			>&nbsp;does a great job summarizing what vector embeddings are and how
			they can be constructed for various types of data like text, and images.
			For this blog, let&rsquo;s focus on visual embeddings or embeddings
			derived from images or videos.
		</span>
	</p>
	<p class="c3 c7"><span class="c0"></span></p>
	<p class="imgContainer">
		<span><img
				alt=""
				src="https://aperturedata-public.s3.us-west-2.amazonaws.com/website_images/similarity_search/image3.png"
				title=""
		/></span>
	</p>
	<p class="c3">
		<span class="c17 c14"
			>Figure 1: <a href="https://blog.insightdatascience.com/the-unreasonable-effectiveness-of-deep-learning-representations-4ce83fc663cf" target="_blank">An example of visual embedding</a> would be the 256 or 128
			dimensional feature vector extracted from an example model</span
		><span class="c0">&nbsp;</span>
	</p>
	<p class="c3 c7"><span class="c0"></span></p>
	<p class="c3">
		<span class="c0"
			>A visual embedding or feature vector is a lower-dimensional
			representation of the image or video, typically a series of numbers
			taken from some of the last layers of a model used for inference.
			Basically, it is a vector of numbers which can be used for many tasks
			such as similarity search or classification to recognize items using
			content in images or videos rather than external keywords or labels as
			shown in Figure 1. While that sounds really cool to not need keywords or
			labels, in reality, the queries almost always require a combination of
			two or three of these search modalities as we will see from some
			representative use cases outlined below.</span
		>
	</p>
	<p class="c3 c7"><span class="c0"></span></p>
	<h2 class="c8" id="h.ohoub1wqjlc0">
		<span class="c11">Sharing or Reusing Embeddings</span>
	</h2>
	<p class="c3 c7"><span class="c0"></span></p>
	<p class="c3">
		<span class="c0"
			>We frequently encounter cases where our users have ways of extracting
			embeddings, but are missing efficient and scalable ways to index, reuse,
			and share them. One of the teams we work with described how their data
			scientists would query and download the image dataset, generate
			embeddings, and build an in-memory vector index each time they had to
			debug their visual recommendations, and eventually discard the index
			after solving the problem. If they could index those embeddings easily,
			the entire workflow would be as simple as running a query to use those
			embeddings rather than starting from building indexes each time they are
			needed. All such examples usually involve querying the dataset for a
			specific group of items (e.g. &ldquo;shoes&rdquo;), which can over time
			involve complex metadata queries. The ability to index and find
			embeddings can also simplify collaboration across teams since people can
			build on each other&rsquo;s work more efficiently.</span
		>
	</p>
	<p class="c3 c7"><span class="c0"></span></p>
	<h2 class="c8" id="h.lrjmx3lb0t3d">
		<span class="c11">Visual Recommendations</span>
	</h2>
	<p class="c3">
		<span
			>The vector representations are designed to produce similar vectors for
			similar images or </span
		><span>video content</span
		><span class="c0"
			>, where similar vectors are those that are nearby in Euclidean space.
			This can be very helpful when recommending products to users in
			e-commerce use cases, finding images or videos with similar defects in
			visual inspection cases, searching for similar tumor impressions in
			medical imaging and so on.</span
		>
	</p>
	<p class="c3 c7"><span class="c0"></span></p>
	<p class="imgContainer">
		<span><img
				alt=""
				src="https://aperturedata-public.s3.us-west-2.amazonaws.com/website_images/similarity_search/image4.png"
				title=""
		/></span>
	</p>
	<p class="c3">
		<span class="c18 c17 c14"
			>Figure 2: <a href="https://api.semanticscholar.org/CorpusID:202784690&amp;sa=D&amp;source=editors&amp;ust=1697447114504402&amp;usg=AOvVaw2IE6ZQfqi-MOOep1ej5Uy5" target="_blank">Recommendations</a> based on visual similarity combine similarity
			search with other metadata to get desired results</span
		>
	</p>
	<p class="c3 c7"><span class="c0"></span></p>
	<p class="c3">
		<span
			>Our customers often create multiple feature vectors for the same group
			of images using different models to compare which ones give the best
			recommendations. They also sometimes designate best images per product
			(common metadata in e-commerce applications) and create recommendations
			only for those per group of products. Naturally, there is quite a lot of
			metadata search and actual image access and visualization required for
			building applications such as these.</span
		>
	</p>
	<h2 class="c8" id="h.c27lh8hqawk3">
		<span>C</span><span class="c11">lassification</span>
	</h2>
	<p class="c3 c7"><span class="c0"></span></p>
	<p class="c3">
		<span class="c0"
			>Another application for vector representation is classification. A
			feature vector classification algorithm can help determine what the
			right group or label is, for a given query image as shown in Figure
			3.</span
		>
	</p>
	<p class="c3 c7"><span class="c0"></span></p>
	<p class="imgContainer">
		<span><img
				alt=""
				src="https://aperturedata-public.s3.us-west-2.amazonaws.com/website_images/similarity_search/image2.png"
				title=""
		/></span>
	</p>
	<p class="c3">
		<span class="c17 c14 c18"
			>Figure 3: <a href="https://www.researchgate.net/figure/Illustration-of-generic-vs-fine-grained-classification_fig1_336704893" target="_blank">Vector classification</a> can help use embeddings from a query
			image to find the corresponding label</span
		>
	</p>
	<p class="c3 c7"><span class="c0"></span></p>
	<p class="c3 c7"><span class="c0"></span></p>
	<p class="c3">
		<span
			>For example, one of our smart retail customers relies on visually
			identifying items that have been misplaced (<a href="/case-studies/1" target="_blank">read case study here</a>).
            They start with a map of
			items that they expect to find at specific locations and a library of
			images of those items. At regular intervals, they capture images of the
			locations on the map, and extract feature vectors from these images. To
			determine whether the images contain the items they expect, they use
			vector classification combined with a K-nearest neighbor search. If they
			find an image where the classification does not match, and the expected
			item does not appear among any of the nearest neighbor images, they flag
			that location as a possible misplacement. These queries can involve a
			lot of </span
		><span>connected metadata and data</span><span class="c0">.</span>
	</p>
	<p class="c3 c7"><span class="c0"></span></p>
	<h1 class="c12" id="h.kbc76bczk3s1">
		<span class="c1">Managing Embeddings Today</span>
	</h1>
	<p class="c3">
		<span>As summarized in </span
		><span class="c5"
			><a
				class="c2"
				href="https://towardsdatascience.com/milvus-pinecone-vespa-weaviate-vald-gsi-what-unites-these-buzz-words-and-what-makes-each-9c65a3bd0696"
				target="_blank">this blog</a
			></span
		><span class="c0"
			>, there are quite a few vector databases for users to choose from e.g.
			Pinecone, Milvus, Qdrant, Vald, to name a few. In fact, existing
			databases now offer support for vector search like with pgvector, Redis
			similarity search, and others. The right choice for any application
			depends not only on the specific use case, but also on cost, scale,
			algorithms, distance metrics, SaaS vs. on-premise decisions, automated
			extraction of embeddings, and capabilities expected beyond similarity
			search and classification. When it comes to visual data, the choice goes
			even further beyond challenges addressed by these solutions.</span
		>
	</p>
	<p class="c3 c7"><span class="c0"></span></p>
	<p class="c3">
		<span
			>Through our user interactions, we have learnt how some of these vector
			databases can be deployed for use cases similar to the ones that we have
			discussed above. </span
		><span
			>While these vector databases are able to help them solve the vector
			indexing and search challenges they faced, when we started digging
			deeper what caught our attention was </span
		><span class="c14"
			>what <i>the overall data pipeline and architecture looked like for their
			visual use cases</i></span
		><span class="c0">. </span>
	</p>
	<p class="c3 c7"><span class="c0"></span></p>
	<p class="imgContainer">
		<span><img
				alt=""
				src="https://aperturedata-public.s3.us-west-2.amazonaws.com/website_images/similarity_search/image5.png"
				title=""
		/></span>
	</p>
	<p class="c3">
		<span class="c18 c20 c14"
			>Figure 4: A do-it-yourself (DIY) spaghetti solution to put all data
			infrastructure pieces together. Vector search and classification (shown
			in red) is often just one piece of the puzzle.</span
		>
	</p>
	<p class="c3 c7"><span class="c0"></span></p>
	<p class="c3 c7"><span class="c0"></span></p>
	<p class="c3">
		<span
			>Invariably, they had to create a relational database mapping between
			the feature vector identifiers returned by the chosen vector database
			and other metadata e.g. about the product whose images were used to
			produce the embeddings</span
		><span>,</span><span>&nbsp;</span
		><span>requiring more complex queries across different </span
		><span>metadata parameters</span
		><span class="c0"
			>. Sometimes the embeddings represent only a region of interest in an
			image which means they also need to be linked to both the image itself
			and the region of interest with the ability to trace back to the
			original images and annotations when displaying the result.</span
		>
	</p>
	<p class="c3 c7"><span class="c0"></span></p>
	<p class="c3">
		<span
			>For these very reasons, even though a lot of the vector database
			products started out as purely vector indexing and search tools, they
			have realized how those functionalities are often insufficient, because
			their users have to continue investing </span
		><span>in</span
		><span class="c0"
			>&nbsp;DIY systems as shown in Figure 4. As a result, they have been
			introducing metadata filter capabilities at various granularities
			ranging from a simple keyword filter to some understanding of a
			graph-like structure. But often because these filters are being overlaid
			on a primarily vector search solution, it continues to be hard for users
			to link their existing, rich metadata from various databases,
			annotations, and data storage with these vector databases. The onus of
			consistent naming and identification falls on the data engineers setting
			up the data pipelines.</span
		>
	</p>
	<p class="c3 c7"><span class="c0"></span></p>
	<p class="c3">
		<span class="c0"
			>Another challenge in some vector database solutions is their hosted
			nature. While these solutions can work in a lot of cases, our
			conversations with people dealing with images and video indicate their
			strong hesitations in uploading data to another cloud account as a long
			term solution either due to privacy and/or cost concerns.
		</span>
	</p>
	<p class="c3 c7"><span class="c0"></span></p>
	<h1 class="c12" id="h.38xplvajemcj">
		<span class="c1">Why Use ApertureDB?</span>
	</h1>
	<p class="c3">
		<span>Our biggest value proposition is that</span
		><span class="c14"
			>&nbsp;<i>we integrate functionalities of a vector database, intelligence
			graph, and visual data to seamlessly query across data domains</i>.</span
		><span class="c0"
			>&nbsp;Data scientists and engineers looking at visual machine learning
			find ApertureDB appealing because it offers a simple API to access
			whatever visual assets you need from a shared repository containing
			images, videos, annotations, embeddings, and more.
		</span>
	</p>
	<p class="c3 c7"><span class="c0"></span></p>
	<p class="c3">
		<span
			>Our metadata, stored in an in-memory property graph behind the scenes,
			is native and more than a simple one word filter. Embeddings, extracted
			from any method and type of data, live with their source images / videos
			or regions of interest and other related metadata counterparts. </span
		><span class="c10"
			><b>You can use our API to run vector queries, metadata filtering, and
			image or video manipulation within a single transaction and rely on
			ApertureDB to keep scaling as your application requirements and data
			grow</b>.</span
		>
	</p>
	<p class="c3 c7"><span class="c0"></span></p>
	<p class="imgContainer">
		<span><img
				alt=""
				src="https://aperturedata-public.s3.us-west-2.amazonaws.com/website_images/similarity_search/image1.png"
				title=""
		/></span>
	</p>
	<p class="c3">
		<span class="c14 c17"
			>Figure 5: A purpose-built database for visual analytics like ApertureDB
			shifts focus back to analytics</span
		>
	</p>
	<h2 class="c8" id="h.xj4jsd2yae8u">
		<span class="c11">Vector Search and Classification with ApertureDB</span>
	</h2>
	<p class="c3">
		<span
			>ApertureDB vector search and classification functionality is offered as
			part of our unified API. </span
		><span>We offer a </span
		><span class="c5"
			><a
				class="c2"
				href="https://docs.aperturedata.io/HowToGuides/Basic/Image%20Similarity%20Search.html"
				target="_blank">flexible API for exact or ANN</a
			></span
		><span>&nbsp;powered by </span
		><span class="c5"
			><a
				class="c2"
				href="https://engineering.fb.com/2017/03/29/data-infrastructure/faiss-a-library-for-efficient-similarity-search/"
				target="_blank">FAISS</a
			></span
		><span
			>, modified for CRUD and memory efficiency. We support multiple ANN
			algorithms and/or configurations simultaneously as well as all major
			distance metrics. The entire database can be self-hosted or managed.
		</span>
	</p>
	<p class="c3 c7"><span class="c0"></span></p>
	<p class="c3">
		<span class="c0"
			>For the smart retail customer use case referred above, they have a
			library of reference images, feature vector embeddings for each image,
			metadata describing which items are depicted, and locations where they
			expect to find those items. They then need to query this data at scale
			across all of these data modalities. This is all achieved using a single
			ApertureDB query.</span
		>
	</p>
	<p class="c3 c7"><span class="c0"></span></p>
	<p class="c3">
		<span>Even with a single-node deployment, </span
		><span>this customer found that</span
		><span class="c23"
			>&nbsp;<b>ApertureDB is 2.5X as fast with the same accuracy</b></span
		><span
			>&nbsp;as their previous solution (a popular vector database), prompting
			them to replace their inventory management solution with ApertureDB in
			production as they scale to 10s of millions of embeddings per
            index (<a href="/case-studies/1" target="_blank">read case study here</a>). Our </span
		><span>distributed version</span
		><span
			>, will allow them to scale further in both throughput and recall (topic
			for a future blog). &nbsp;
		</span>
	</p>
	<h1 class="c12" id="h.4bzvj9k1ug10"><span class="c1">Conclusion</span></h1>
	<p class="c3 c7"><span class="c9"></span></p>
	<p class="c3">
		<span
			>If you are planning to implement or are currently utilizing ML data
			infrastructure for operating on pixel data like images and videos, is
			vector indexing and search needed?</span
		><span>&nbsp;The answer is yes quite often, though not always. Are</span
		><span class="c0"
			>&nbsp;the current vector databases enough to achieve application end
			goals? Our customer discussions indicate that often they are not. While
			they solve one piece of the problem, practical implementations typically
			require stitching in a few more pieces such as a metadata store, actual
			data buckets, processing and augmentation libraries, label management,
			just to name a few.
		</span>
	</p>
	<p class="c3 c7"><span class="c0"></span></p>
	<p class="c3">
		<span
			>So what if you are working with embeddings and metadata, but not visual
			data? We get this question often. We are agnostic to how the embeddings
			are derived, the metadata can be adapted to any application, and any
			source data can be stored as </span
		><span class="c5"
			><a
				class="c2"
				href="https://docs.aperturedata.io/query_language/Reference/blob_commands/AddBlob.html"
				target="_blank">generic binary blobs</a
			></span
		><span
			>&nbsp;in ApertureDB. Stay tuned for more on this, but in the meantime,
			please come </span
		><span class="c5"
			><a
				class="c2"
				href="https://aperturedata.io/contact-us"
				target="_blank">talk to us</a
			></span
		><span class="c0">.</span>
	</p>
	<p class="c6">
		<span
			>If your organization uses or intends to use ML on visual data (small or
			large team) or you are simply curious about our technology, our approach
			to infrastructure development, or where we are headed, please contact us
			at team@aperturedata.io or try out </span
		><span class="c5"
			><a
				class="c2"
				href="https://aperturedata.io/demo-request"
				target="_blank">ApertureDB on pre-loaded datasets</a
			></span
		><span
			>. If you&rsquo;re excited to join an early stage startup and make a big
			difference, </span
		><span class="c5"
			><a
				class="c2"
				href="https://aperturedata.io/join-us"
				target="_blank">we&rsquo;re hiring</a
			></span
		><span
			>. Last but not least, we will be documenting our journey and explaining
			all the components listed above on our blog, subscribe </span
		><span class="c5"
			><a
				class="c2"
				href="https://forms.gle/BdxgdUndw5FcsBQP6"
				target="_blank">here</a
			></span
		><span class="c0">.</span>
	</p>
	<p class="c6 c7"><span class="c0"></span></p>
	<p class="c6">
		<span class="acknowledge"
			>Acknowledging here the valuable insights and edits by Josh Stoddard,
			Steve Huber, Romain Cledat, Luis Remis, and Drew Ogle.</span
		>
	</p>
	<p class="c3 c7"><span class="c0"></span></p>
	<p class="c3 c7"><span class="c0"></span></p>
</body>
</html>
`;
