import styles from './CaseRow.module.scss';
import { CaseType } from '../../UseCases';
import Dot from '@material-ui/icons/FiberManualRecord';
import React from 'react';

interface CaseProps {
	case: CaseType;
	imageAtLeft: boolean;
}

const CaseRow: React.FC<CaseProps> = (props) => {
	return (
		<div className={styles['case-row']} id={props.case.title}>
			{props.imageAtLeft && (
				<div className={styles['case-top']} style={{ marginRight: '2.34vw' }}>
					<img
						src={props.case.image}
						alt={props.case.title}
						className={styles['case-image']}
					/>
				</div>
			)}
			<div className={styles['case-bottom']}>
				<div style={{ display: 'flex' }}>
					<img
						src={props.case.icon}
						alt={props.case.title}
						className={styles['case-icon']}
					/>
					<span className={styles['case-title']}>{props.case.title}</span>
				</div>
				<span className={styles['case-description']}>
					{props.case.description}
				</span>
				<span className={styles['case-bullets']}>
					{props.case.bullets.map((bullet, index) => {
						return (
							<div className={styles['case-bullets-extension']} key={index}>
								<Dot className={styles.dot} />
								<span>{bullet}</span>
							</div>
						);
					})}
				</span>
			</div>
			{!props.imageAtLeft && (
				<div className={styles['case-top']} style={{ marginLeft: '2.34vw' }}>
					<img
						src={props.case.image}
						alt={props.case.title}
						className={styles['case-image']}
					/>
				</div>
			)}
		</div>
	);
};

export default CaseRow;
