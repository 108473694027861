import styles from './Team.module.scss';
import React from 'react';
import TeamMember from './TeamMember/TeamMember';
import teamMembers from '../../../assets/content/Team';

export interface TeamMemberType {
	name: string;
	position: string;
	description: string;
	image: string;
	url?: string;
}

const Team: React.FC = () => {
	return (
		<div className={styles.team}>
			<div
				className={`${styles['section-container']} ${styles['section-container-extension']}`}
			>
				<div className={styles['title-column']}>Team</div>
				<div className={styles['content-column']}>
					{teamMembers.map((teamMember) => (
						<TeamMember teamMember={teamMember} key={teamMember.name} />
					))}
				</div>
			</div>
		</div>
	);
};

export default Team;
