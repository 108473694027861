import styles from './ContactForm.module.scss';
import React from 'react';
import {
	FormControl,
	Link,
	Select,
	InputLabel,
	CircularProgress,
	Grid,
	TextField,
	Snackbar
} from '@material-ui/core';
import ActionButton from '../../../components/Buttons/ActionButton';
import cases from '../../../assets/content/DemoUseCases';
import ContactFormCarousel from './ContactFormCarousel';
import { DemoCaseType } from '../DemoRequest';
import MailRepository from '../../../api/mail';
import MailService from '../../../services/mail';

const style = {
	submit: {
		backgroundColor: '#4648c6',
		color: '#fff',
		borderRadius: 3,
		width: 150,
		height: 48,
		fontSize: 14,
		letterSpacing: 0.2,
		textTransform: 'none',
		fontWeight: 'bold',
		marginLeft: 20,
	},
};

const ContactForm: React.FC = () => {
	const [email, setEmail] = React.useState('');
	const [demoType, setDemoType] = React.useState('non-selected');
	const [snackbarMessage, setSnackbarMessage] = React.useState('');
	const [openSnackbar, setOpenSnackbar] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [url, setUrl] = React.useState<string | undefined>(undefined);

	const showSnackbarMessage = (): void => {
		setOpenSnackbar(true);
		setTimeout(() => {
			setOpenSnackbar(false);
		}, 3000);
	};

	const checkAndSubmit = (): void => {
		if (!email || !MailService.isValidEmail(email)) {
			setSnackbarMessage(
				'The email is not valid. Please review the data and try again.'
			);
			showSnackbarMessage();
		} else if (!demoType) {
			setSnackbarMessage(
				'The demo type is not set. Please review it and try again.'
			);
			showSnackbarMessage();
		} else {
			setIsLoading(true);
			setUrl(undefined);
			MailRepository.requestDemoMail(email, demoType)
				.then((result: string | undefined) => {
					if (result) {
						setEmail('');
						setDemoType('non-selected');
						setUrl(result);
					} else {
						setSnackbarMessage(
							'There was an error while trying to send email. Please review the data and try again.'
						);
						showSnackbarMessage();
					}

					setIsLoading(false);
				})
				.catch(() => {
					setSnackbarMessage(
						'There was an error while trying to send email. Please review the data and try again.'
					);
					setIsLoading(false);
					showSnackbarMessage();
				});
		}
	};

	const onDemoTypeChange = (
		event: React.ChangeEvent<{ name?: string; value: unknown }>
	): void => {
		if (event.target && event.target.value) {
			setDemoType(event.target.value as string);
		} else {
			setDemoType('non-selected');
		}
	};

	return (
		<div className={styles['contact-section']}>
			<div className={styles['contact-row']}>
				<FormControl
					variant='outlined'
					className={styles['contact-field']}
				>
					<InputLabel htmlFor='outlined-age-native-simple'>
						Choose Your Option
					</InputLabel>
					<Select
						native
						value={demoType}
						onChange={onDemoTypeChange}
						label='Choose Your Option'
						inputProps={{
							name: 'age',
							id: 'outlined-age-native-simple',
						}}
					>
						<option aria-label='None' value='' />
						{cases.map((specificCase: DemoCaseType) => (
							<option key={specificCase.name} value={specificCase.name}>
								{specificCase.title}
							</option>
						))}
					</Select>
				</FormControl>
			</div>
			<div className={styles['contact-row']}>
				<TextField
					label='Your Email Here'
					variant='outlined'
					required={true}
					className={styles['contact-field']}
					type='mail'
					value={email}
					onChange={(
						event: React.ChangeEvent<HTMLInputElement>
					): void => setEmail(event.target.value)}
				/>
				<ActionButton
					name='Get Started'
					onClick={checkAndSubmit}
					style={style.submit}
					isDisabled={
						!(email && demoType !== 'non-selected' && !isLoading)
					}
				/>
			</div>
			{url && (
				<div className={styles['instance-link']}>
					<span>
						Go to <Link href={url} target='_blank'>{url}</Link> to connect to the instance.
					</span>
				</div>
			)}
			<div className={styles['grid-container']}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={4}>
						<span className={styles['contact-field']}>
							<b>Note:</b> Look out for an email with
								instructions and access information to the selected
								ApertureDB instance, *typically in under a minute*. <br /> <br />
								For your own read-write sandbox, reach out to us at team@aperturedata.io
								or join our slack (link above).
						</span>
					</Grid>
					<Grid item xs={12} md={8}>
						<ContactFormCarousel />
					</Grid>
				</Grid>
				<div style={{ position: 'relative' }}>
					{isLoading && (
						<CircularProgress
							size={24}
							style={{
								position: 'absolute',
								zIndex: 1,
								left: 88,
								top: 12,
							}}
						/>
					)}
				</div>
			</div>
			<Snackbar
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				open={openSnackbar}
				autoHideDuration={20000}
				message={snackbarMessage}
			/>
		</div>
	);
};

export default ContactForm;
