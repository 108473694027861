import { FilterOptionType } from "../../components/FilterOptions/FilterOptionInterface";

const BlogsOptions: FilterOptionType[] = [
	{
		type: "Technology",
	},
	{
		type: "News",
	},
	{
		type: "Updates",
	},
	{
		type: "Product",
	},
	{
		type: "Applied",
	},
];

export default BlogsOptions;
