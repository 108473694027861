import styles from './SelfSetup.module.scss';
import React from 'react';
import ContactUs from '../Landing/sections/ContactUs';
import DockerSection from './sections/DockerSection';
import Intro from './sections/Intro';

const SelfSetup: React.FC = () => {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className={styles['self-setup']}>
			<Intro />
			<DockerSection />
			<ContactUs text={"If you’d like to request a sandbox to try your own use case, have specific questions, or simply want to chat, drop us a line below."} />
		</div>
	);
};

export default SelfSetup;
