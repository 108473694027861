import React from 'react';
import style from './ResourceBigCard.module.scss';
import { ResourceType } from '../Resources';

interface BlogCardProps {
	blog: ResourceType;
}

const ResourceBigCard: React.FC<BlogCardProps> = (props) => {
	return (
		<div className={style.container}>
			<div className={style.imgContainer}>
				<img src={props.blog?.image} alt={props.blog?.title} />
			</div>
			<div className={style.dataContainer}>
				<div className={style.type}>{props.blog?.type}</div>
				<div className={style.title}>{props.blog?.title}</div>
				<div className={style.description}>{props.blog?.description}</div>
				<a className={style.readMore} href={(props.blog?.link)} target='_blank' rel='noreferrer'>read more</a>
			</div>
		</div>
	);
};

export default ResourceBigCard;
