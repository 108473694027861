import React from 'react';

export const UseViewPortWidth = () => {
	const [viewPortWidth, setViewPortWidth] = React.useState(window.innerWidth);

	React.useEffect(() => {
		const handleResize = () => {
		setViewPortWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);

		return () => {
		window.removeEventListener('resize', handleResize);
		};
	}, []);

  return viewPortWidth;
};

