import React from 'react';
import Intro from './sections/Intro';
import styles from './Blog.module.scss';
import ContactUs from '../Landing/sections/ContactUs';
import FilterOptions from '../../components/FilterOptions/FilterOptions';
import BlogsOptions from '../../assets/content/BlogsOptions';
import BlogsList from './sections/BlogsList';
import blogsData from '../../assets/content/BlogsData';
import { useLocation, useNavigate } from 'react-router-dom';

export interface BlogType {
	id: string;
	title: string;
	description: string;
	image: string;
	type: string;
	bodyText: string;
	date: string;
	author: { name: string, image: string }[];
	tags: string[];
}

const Blog = () => {
	const [filterOption, setFilterOption] = React.useState<string>('All');
	const [filterResult, setFilterResult] = React.useState<BlogType[]>([]);
	const location = useLocation();
	const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const tag = searchParams.get('tag');

	const handleFilterOptionSelected = (state: string) => {
		setFilterOption(state);

		if (tag !== null) {
			navigate(`/blog`);
		}
	};

	const scrollToTheTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'auto',
		});
	};

	React.useEffect(() => {
		const handleFilterPost = () => {
			if (filterOption !== 'All' && tag === null) {
				const resultFilter = blogsData.filter(item => item.type.toLocaleLowerCase() === filterOption.toLocaleLowerCase());

				setFilterResult(resultFilter);
			} else {
				if(tag !== null) {
					const resultFilter = blogsData.filter(item => item.tags.includes(tag));

					setFilterResult(resultFilter);
				} else {
					setFilterResult(blogsData);
				}
			}
		};

		handleFilterPost();
		scrollToTheTop();
	}, [filterOption, tag]);

	return (
		<div className={styles.blogs}>
			<Intro />
			<FilterOptions cases={BlogsOptions} handleFilterOptionSelected={handleFilterOptionSelected} filterTag={tag ?? ""}/>
			<BlogsList blogs={filterResult} optionState={tag ? `${tag} Tag` : filterOption} allBlogs={blogsData} />
			<ContactUs />
		</div>
	);
};

export default Blog;
