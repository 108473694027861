import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BlogType } from '../Blog';
import style from './BlogBigCard.module.scss';

interface BlogCardProps {
	blog: BlogType;
}

const BlogBigCard: React.FC<BlogCardProps> = (props) => {
	const navigate = useNavigate();

	const handleNavigate = (id: string) => {
		navigate(`/blog/${id}`);
	};

	return (
		<div className={style.container}>
			<div className={style.imgContainer}>
				<img src={props.blog.image} alt={props.blog.title} />
			</div>
			<div className={style.dataContainer}>
				<div className={style.date}>{props.blog.date}</div>
				<div className={style.title}>{props.blog.title}</div>
				<div className={style.description}>{props.blog.description}</div>
				<div className={style.readMore} onClick={() => handleNavigate(props.blog.id)}>read more</div>
			</div>
		</div>
	);
};

export default BlogBigCard;
