import styles from './Intro.module.scss';
import React from 'react';

const Intro: React.FC = () => {
	return (
		<div className={styles['intro-contact-us']}>
			<div
				className={`${styles['section-container']} ${styles['section-container-extension']}`}
			>
				<h1>Try our ApertureDB Docker image</h1>
				<h2>Simple multimodal data management, on-prem or on your own cloud instance.</h2>
				<div className={styles.linkQuestions}>
					<span>
						<a href="/contact-us">Questions? Contact Us</a>
					</span>
				</div>
			</div>
		</div>
	);
};

export default Intro;
