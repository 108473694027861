import styles from './CaseStudiesList.module.scss';
import CaseRow from './Case/CaseRow';
import { CaseStudyType } from '../../CaseStudy/CaseStudy';
import React from 'react';

interface CaseStudiesListProps {
	caseStudies: CaseStudyType[];
}

const CaseStudiesList: React.FC<CaseStudiesListProps> = ({ caseStudies }) => {
	return (
		<div className={styles.caseStudiesList}>
			<div className={styles.container}>
				{caseStudies.map(
					(caseStudy: CaseStudyType): JSX.Element => (
						<CaseRow
							caseStudy={caseStudy}
							key={caseStudy.card.title}
						/>
					)
				)}
			</div>
		</div>
	);
};

export default CaseStudiesList;
