import { Navigate, Route, Routes } from "react-router-dom";
import Company from "./pages/Company";
import ContactUs from "./pages/ContactUs";
import DemoRequest from "./pages/Demo";
import { Error } from "./pages";
import JoinUs from "./pages/JoinUs";
import Landing from "./pages/Landing";
import PrivacyPolicy from "./pages/Company/sections/PrivacyPolicy";
import React from "react";
import TermsOfService from "./pages/Company/sections/TermsOfService";
import UseCases from "./pages/UseCases";
import Resources from "./pages/Resources";
import News from "./pages/News";
import FAQ from "./pages/FAQ";
import SupportPolicy from "./pages/Company/sections/SupportPolicy";
import CaseStudies from "./pages/CaseStudies";
import CaseStudy from "./pages/CaseStudy";
import Blog from "./pages/Blog/Blog";
import BlogsDetails from "./pages/Blog/BlogsDetails/BlogsDetails";
import SelfSetup from "./pages/SelfSetup";
import DockerLicense from "./pages/Company/sections/DockerLicense";

const Navigation = (): JSX.Element => {
	return (
		<Routes>
			<Route key="/home" path="/home" element={<Landing />} />
			<Route key="/use-cases" path="/use-cases" element={<UseCases />} />
			<Route key="/case-studies" path="/case-studies" element={<CaseStudies />} />
			<Route path="/case-studies/:studyId" element={<CaseStudy />} />
			<Route key="/resources" path="/resources" element={<Resources />} />
			<Route key="/news" path="/news" element={<News />} />
			<Route key="/fag" path="/faq" element={<FAQ />} />
			<Route path="/join-us/:sectionId/:jobId" element={<JoinUs />} />
			<Route key="/join-us" path="/join-us/" element={<JoinUs />} />
			<Route key="/company" path="/company" element={<Company />} />
			<Route key="/blog" path="/blog" element={<Blog />} />
			<Route key="/blog/id" path="/blog/:id" element={<BlogsDetails />} />
			<Route
				key="/contact-us"
				path="/contact-us"
				element={<ContactUs />}
			/>
			<Route key="/faq" path="/faq" element={<FAQ />} />
			<Route
				key="/demo-request"
				path="/demo-request"
				element={<DemoRequest />}
			/>
			<Route
				key="/download"
				path="/download"
				element={<SelfSetup />}
			/>
			<Route key="/error" path="/error" element={<Error />} />
			<Route
				key="/terms-of-service"
				path="/terms-of-service"
				element={<TermsOfService />}
			/>
			<Route
				key="/privacy-policy"
				path="/privacy-policy"
				element={<PrivacyPolicy />}
			/>
			<Route
				key="/support-policy"
				path="/support-policy"
				element={<SupportPolicy />}
			/>
			<Route
				key="/docker-license"
				path="/docker-license"
				element={<DockerLicense />}
			/>
			<Route path="/" element={<Navigate to="/home" />} />
			<Route path="*" element={<Navigate to="/error" />} />
		</Routes>
	);
};

export default Navigation;
