import styles from './ContactUsLandingPage.module.scss';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@material-ui/core';
import slackImg from '../../../assets/images/Landing/slack.svg';

const ContactUsLandingPage: React.FC = () => {
  const navigate = useNavigate();
  const defaultText = `If you’d like to see ApertureDB in action, have specific questions, or simply want to chat, drop us a line below.`;

  return (
		<div className={styles['contact-us-section']}>
			<div
				className={`${styles['section-container']} ${styles['section-container-extension']}`}
			>
				<div className={`${styles['contact-info']} ${styles['extra-height']}`}>
          <h2>{defaultText}</h2>
          <div className={styles.grid}>
            <Button
              variant="contained"
              className={styles['with-background-color']}
              onClick={(): void => navigate('/demo-request')}
            >
              Try Free
            </Button>
            <Button
              variant="contained"
              className={styles['with-background-color']}
              onClick={() => navigate('/download')}
            >
              Download
            </Button>
            <Button
              variant="outlined"
              className={styles['without-background-color']}
              onClick={() => navigate('/contact-us')}
            >
              Contact Us
            </Button>
            <Button
              variant="outlined"
              className={styles['without-background-color']}
              startIcon={<img src={slackImg} alt="Slack logo" />}
              target="_blank"
							rel="noreferrer"
                            href={`https://join.slack.com/t/aperturedb/shared_invite/zt-2ffp3chqv-QkGQdvDQQxv8NSjMBoy5RA`}
            >
              Connect with <span className={styles.boldText}>Slack</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsLandingPage;
