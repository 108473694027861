import styles from './Intro.module.scss';
import React from 'react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Snackbar from '@material-ui/core/Snackbar';
import MailService from '../../../services/mail';
import MailRepository from '../../../api/mail';

const Intro: React.FC = () => {
	const [email, setEmail] = React.useState('');
	const [snackbarMessage, setSnackbarMessage] = React.useState('');
	const [openSnackbar, setOpenSnackbar] = React.useState(false);

	const showSnackbarMessage = (): void => {
		setOpenSnackbar(true);
		setTimeout(() => {
			setOpenSnackbar(false);
		}, 3000);
	};

	const checkAndSubmit = (): void => {
		if (!email || !MailService.isValidEmail(email)) {
			setSnackbarMessage(
				'The email is not valid. Please review the data and try again.'
			);
			showSnackbarMessage();
		} else {
			MailRepository.blogSuscribe(
				email
			).then((result: boolean) => {
				if (result) {
					setSnackbarMessage('Thanks for subscribing!');
					setEmail('');
				} else {
					setSnackbarMessage(
						'There was an error while trying to send email. Please review the data and try again.'
					);
				}

				showSnackbarMessage();
			});
		}
	};

	return (
		<div className={styles['intro-resources']}>
			<div className={styles['section-container']}>
				<h1>ApertureData Blog</h1>
				<h2>Insights, company news, technical deep dives, and all things Aperture.</h2>
				<div className={styles.inputEmail}>
				<input type="text" placeholder='Enter email to subscribe' onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
						setEmail(event.target.value)
					} />
				<div className={styles.buttonSend}><ArrowForwardIosIcon onClick={checkAndSubmit} /></div>
				</div>
			</div>
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				open={openSnackbar}
				autoHideDuration={6000}
				message={snackbarMessage}
			/>
		</div>
	);
};

export default Intro;
