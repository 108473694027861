import styles from './ContactUs.module.scss';
import ActionButton from '../../../components/Buttons/ActionButton';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface ContactUsProps {
  text?: string;
  secondText?: string;
  buttonText?: string;
  buttonPosition?: 'TOP' | 'BOTTOM' | 'BETWEEN';
  buttonStyleWithBorder?: boolean;
  firstTextStyle?: React.CSSProperties;
  secondTextStyle?: React.CSSProperties;
}

const ContactUs: React.FC<ContactUsProps> = ({
  text,
  secondText,
  buttonText = 'Contact Us',
  buttonPosition = 'BOTTOM',
  buttonStyleWithBorder = true,
  firstTextStyle,
  secondTextStyle,
}) => {
  const navigate = useNavigate();

  const defaultText = `If you’d like to see ApertureDB in action, have specific questions, or simply want to chat, drop us a line below.`;

	const getButtonStyle = (): React.CSSProperties => {
    const style: React.CSSProperties = {
			backgroundColor: '#FF8D22',
			borderRadius: 8,
			border: '0.7px solid #FFFFFF',
			color: '#fff',
			width: 'clamp(117.82px, 12.40vw, 195.96px)',
			height: 'clamp(27.83px, 2.93vw, 46.29px)',
			fontSize: 'clamp(11.13px, 1.17vw, 18.52px)',
			fontStyle: 'normal',
			fontWeight: 700,
			letterSpacing: 0.2,
			textTransform: 'none',
    };

    if (!buttonStyleWithBorder) {
      style.border = 'none';
      style.borderRadius = 3;
    }

    if (buttonPosition === 'BETWEEN') {
      style.margin = '1vw 0vw';
    }

    return style;
  };

  const renderButton = () => (
    <ActionButton
      name={buttonText}
      onClick={() => navigate('/contact-us')}
      style={getButtonStyle()}
    />
  );

	const renderText = (text: string, style: React.CSSProperties) => (
    <h2 style={style}>{text}</h2>
  );

  return (
		<div className={styles['contact-us-section']}>
			<div
				className={`${styles['section-container']} ${styles['section-container-extension']}`}
			>
				<div className={`${styles['contact-info']} ${secondText && styles['extra-height']}`}>
					{buttonPosition === 'TOP' && renderButton()}
          {renderText(text ?? defaultText, firstTextStyle || {})}
          {buttonPosition === 'BETWEEN' && renderButton()}
          {secondText && renderText(secondText ?? '', secondTextStyle || {})}
          {buttonPosition === 'BOTTOM' && renderButton()}
				</div>
			</div>
		</div>

  );
};

export default ContactUs;
