import headerStyles from './Header.module.scss';
import { Button, Drawer, List, ListItem, ListItemText } from '@material-ui/core';
import ActionButton from '../Buttons/ActionButton';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import Logo from '../../assets/images/Header/logo-h.png';
import LogoShort from '../../assets/images/Header/logo-short-color.png';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import slackImg from '../../assets/images/Landing/slack.svg';

const styles = {
	requestDemo: {
		backgroundColor: '#FF8D22',
		color: '#fff',
		borderRadius: 4,
		textTransform: 'none',
		fontWeight: 700,
		width: "clamp(64.94px, 6.84vw, 123.05px)",
		fontSize: "clamp(10.20px, 1.07vw, 16.97px)",
		marginLeft: "clamp(7.03px, 0.98vw, 15.43px)",
		height: "clamp(25px, 2.65vw, 42px)",
	},
};

const Header: React.FC = () => {
	const [openDrawer, setOpenDrawer] = React.useState(false);
	const navigate = useNavigate();

	const toggleDrawer =
		(open: boolean) =>
			(event: React.KeyboardEvent | React.MouseEvent): void => {
				if (
					event.type === 'keydown' &&
					((event as React.KeyboardEvent).key === 'Tab' ||
						(event as React.KeyboardEvent).key === 'Shift')
				) {
					return;
				}

				setOpenDrawer(open);
			};

	const list = (): JSX.Element => (
		<div
			className={headerStyles.list}
			role='presentation'
			onClick={toggleDrawer(false)}
			onKeyDown={toggleDrawer(false)}
		>
			<List>
				<ListItem button onClick={(): void => navigate('home')}>
					<ListItemText primary={'Home'} />
				</ListItem>
				<ListItem button onClick={(): void => navigate('use-cases')}>
					<ListItemText primary={'Use Cases'} />
				</ListItem>
				{/* <ListItem button onClick={(): void => navigate('case-studies')}>
					<ListItemText primary={'Case Studies'} />
				</ListItem> */}
				<ListItem button onClick={(): void => navigate('resources')}>
					<ListItemText primary={'Resources'} />
				</ListItem>
				<ListItem button onClick={(): Window | null => window.open('https://docs.aperturedata.io', '_blank', 'noreferrer')}>
					<ListItemText primary={'Documentation'} />
				</ListItem>
				<ListItem button onClick={(): void => navigate('blog')}>
					<ListItemText primary={'Blog'} />
				</ListItem>
			</List>
		</div>
	);

	return (
		<div
			className={headerStyles.header}>
			<div
				className={headerStyles['section-container']}
				style={{ flexDirection: 'row', paddingTop: 40, paddingBottom: 40, maxWidth: "1750px" }}
			>
				<div className={headerStyles['header-logo']}>
					<a href='/'>
						<img src={Logo} alt='logo' className={headerStyles.logo} />
						<img
							src={LogoShort}
							alt='logo-short'
							className={headerStyles['logo-short']}
						/>
					</a>
				</div>
				<div className={headerStyles.divider} />
				<div className={headerStyles['header-actions']}>
					<div className={headerStyles['header-actions-web']}>
						<a className={headerStyles['link-button']} href='/'>
							Home
						</a>
						<a className={headerStyles['link-button']} href='/use-cases'>
							Use Cases
						</a>
						<a className={headerStyles['link-button']} href='/resources'>
							Resources
						</a>
						<a
							className={headerStyles['link-button']}
							href='https://docs.aperturedata.io'
							target='_blank'
							rel='noopener noreferrer'
						>
							Documentation
						</a>
						<a
							className={headerStyles['link-button']}
							href='/blog'
							rel='noopener noreferrer'
						>
							Blog
						</a>
						<Button
							className={headerStyles['slack-button']}
							href={`https://join.slack.com/t/aperturedb/shared_invite/zt-2ffp3chqv-QkGQdvDQQxv8NSjMBoy5RA`}
							target="_blank"
							rel="noreferrer"
						>
							<img src={slackImg} className={headerStyles['slack-img']} />
						</Button>
						<ActionButton
							name='Try Free'
							onClick={(): void => navigate('/demo-request')}
							style={styles.requestDemo}
						/>
						<ActionButton
							name='Download'
							onClick={(): void => navigate('/download')}
							style={styles.requestDemo}
						/>
					</div>
					<div className={headerStyles['header-actions-mobile']}>
						<IconButton
							aria-label='open drawer'
							onClick={toggleDrawer(true)}
							edge='start'
							className={clsx(
								headerStyles['menu-button'],
								openDrawer && headerStyles.hide
							)}
						>
							<MenuIcon />
						</IconButton>
						<Drawer
							anchor={'right'}
							open={openDrawer}
							onClose={toggleDrawer(false)}
						>
							{list()}
						</Drawer>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
