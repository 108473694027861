import styles from './PreFooter.module.scss';
import Logo from '../../assets/images/PreFooter/logo_prefooter.png';
import React from 'react';

const PreFooter: React.FC = () => {
	return (
		<div className={styles.prefooter}>
			<div className={styles['section-container']}>
				<div className={styles['prefooter-image-container']}>
					<img src={Logo} alt='' className={styles['prefooter-image']} />
				</div>
				<div className={styles.divider} />
				<div className={styles['link-list']}>
					<div className={styles['link-column']}>
						<div className={styles['link-title']}>Company</div>
						<div className={styles.links}>
							<a href='/company'>About Us</a>
							<a href='/join-us'>Join Us</a>
							<a href='news'>News</a>
						</div>
					</div>
					<div className={styles['link-column']}>
						<div className={styles['link-title']}>Product</div>
						<div className={styles.links}>
							<a href='faq'>FAQ</a>
							<a href='/terms-of-service'>Terms of service</a>
							<a href='/privacy-policy'>Privacy policy</a>
							<a href='/support-policy'>Support policy</a>
							<a href='/docker-license'>Docker License</a>
						</div>
					</div>
					<div className={styles['link-column']}>
						<div className={styles['link-title']}>Social</div>
						<div className={styles.links}>
							<a
								href='https://www.linkedin.com/company/aperturedata/'
								target='blank'
							>
								LinkedIn
							</a>
							<a href='https://github.com/aperture-data' target='blank'>
								GitHub
							</a>
							<a href={`https://join.slack.com/t/aperturedb/shared_invite/zt-2ffp3chqv-QkGQdvDQQxv8NSjMBoy5RA`} target='blank'>
								Slack
							</a>
							<a href='https://youtube.com/@ApertureData' target='blank'>
								Videos
							</a>
							<a href='https://twitter.com/ApertureData' target='blank'>
								Twitter
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PreFooter;
