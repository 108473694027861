import styles from './JobDetails.module.scss';
import ActionButton from '../../../components/Buttons/ActionButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import JobForm from './JobForm';
import { JobType } from '../JoinUs';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import React from 'react';
import ScheduleIcon from '@material-ui/icons/Schedule';

const style = {
	applyNow: {
		backgroundColor: '#4648c6',
		color: '#fff',
		borderRadius: 8,
		width: 200,
		height: 48,
		fontSize: 18,
		letterSpacing: 0.2,
		textTransform: 'none',
		fontWeight: 'bold',
	},
	submit: {
		backgroundColor: '#4648c6',
		color: '#fff',
		borderRadius: 8,
		width: 200,
		height: 48,
		fontSize: 18,
		letterSpacing: 0.2,
		textTransform: 'none',
		fontWeight: 'bold',
		marginRight: 10,
	},
};

interface JobDetailsProps {
	job: JobType;
	jobSection: string;
}

const JobDetails: React.FC<JobDetailsProps> = ({ job, jobSection }) => {
	const [showForm, setShowForm] = React.useState(false);

	React.useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, []);

	return (
		<div className={styles['job-details']}>
			<div
				className={`${styles['section-container']} ${styles['section-container-extension']}`}
			>
				<a href='/join-us' className={styles['go-back']}>
					<ArrowBack style={{ marginRight: 10, fontSize: 40 }} /> Back to listing
				</a>
				<span className={styles['job-section-title']}>{jobSection}</span>
				<div className={styles['job-title-container']}>
					<span className={styles['job-title']}>{job.title}</span>
					<div className={styles['job-contract-type']}>
						<ScheduleIcon style={{ marginRight: 10, marginLeft: 30 }} />
						<span>{job.contractType}</span>
					</div>
					<div className={styles['job-location']}>
						<LocationOnIcon style={{ marginRight: 10, marginLeft: 30 }} />
						<span>{job.location}</span>
					</div>
				</div>
				<span className={styles['job-description']}>{job.description}</span>
				<div className={styles['job-details-section']}>
					<span className={styles['job-details-section-title']}>
						Expectations
					</span>
					<span className={styles['job-details-section-intro']}>
						{job.minimumQualificationsIntro}
					</span>
					<ul>
						{job.minimumQualifications.map((minimumQualification, index) => (
							<li key={index}>{minimumQualification}</li>
						))}
					</ul>
				</div>
				<div className={styles['job-details-section']}>
					<span className={styles['job-details-section-title']}>
						Additional qualifications
					</span>
					<span className={styles['job-details-section-intro']}>
						{job.additionalQualificationsIntro}
					</span>
					<ul>
						{job.additionalQualifications.map(
							(additionalQualification, index) => (
								<li key={index}>{additionalQualification}</li>
							)
						)}
					</ul>
				</div>
				<div className={styles['job-details-section']}>
					<span className={styles['job-details-section-title']}>
						Our promise
					</span>
					<span className={styles['job-details-section-intro']}>
						{job.ourPromiseIntro}
					</span>
					<ul>
						{job.ourPromise.map((promise, index) => (
							<li key={index}>{promise}</li>
						))}
					</ul>
				</div>
				<ActionButton
					name='Apply now'
					onClick={(): void => setShowForm(true)}
					style={style.applyNow}
				/>
				{showForm && <JobForm jobTitle={job.title} />}
			</div>
		</div>
	);
};

export default JobDetails;
