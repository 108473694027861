import styles from "./DockerForm.module.scss";
import React, { useState } from "react";
import {
	Button,
	CircularProgress,
	Grid,
	TextField,
	Snackbar,
	useMediaQuery
} from "@material-ui/core";
import ActionButton from "../../../components/Buttons/ActionButton";
import MailRepository from '../../../api/mail';
import MailService from "../../../services/mail";
import dockerIcon from "../../../assets/images/SelfSetup/dockerIcon.svg";
import { FileCopy as FileCopyIcon, Check as CheckIcon } from '@material-ui/icons';

const style = {
	submit: {
		backgroundColor: "#4648c6",
		color: "#fff",
		borderRadius: 8,
		width: 200,
		height: 48,
		fontSize: 18,
		letterSpacing: 0.2,
		textTransform: "none",
		fontWeight: "bold",
		marginLeft: 20,
	},
};

const DockerForm: React.FC = () => {
	const [email, setEmail] = useState("");
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const isSmallScreen = useMediaQuery("(max-width:950px)");
	const [copied, setCopied] = useState(false);

	const text = 'docker run -p 55555:55555 aperturedata/aperturedb-standalone';

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 1500);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

	const showSnackbarMessage = (): void => {
		setOpenSnackbar(true);
		setTimeout(() => {
			setOpenSnackbar(false);
		}, 3000);
	};

	const checkAndSubmit = async (): Promise<void> => {
		if (!email || !MailService.isValidEmail(email)) {
			setSnackbarMessage(
				'The email is not valid. Please review the data and try again.'
			);
			showSnackbarMessage();

			return;
		}

		setIsLoading(true);

		try {
			const resultStatusCode = await MailRepository.requestSelfSetupMail(email);

			if (resultStatusCode === '200') {
				setEmail('');
			} else {
				setSnackbarMessage(
					'There was an error while trying to send email. Please review the data and try again.'
				);
				showSnackbarMessage();
			}
		} catch (error) {
			setSnackbarMessage(
				'There was an error while trying to send email. Please review the data and try again.'
			);
			showSnackbarMessage();
		} finally {
			setIsLoading(false);
		}

	};

	return (
		<div className={styles["docker-form"]}>
			<div className={styles["grid-container"]}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={6} className={isSmallScreen ? styles["order-3"] : undefined}>
						<div className={styles.text}>
							{`Get started here to easily evaluate ApertureDB using your own dataset.`}
							<div className={styles["header-box"]}>
								<div className={styles["docker-icon"]}>
									<Button
										className={styles["docker-button"]}
										href={`https://hub.docker.com/r/aperturedata/aperturedb-standalone`}
										target="_blank"
										rel="noreferrer"
									>
										<img src={dockerIcon} alt="Docker_Icon" className={styles["docker-img"]}/>
									</Button>
								</div>
								<div className={styles["copy-action"]} onClick={copyToClipboard}>
									{copied ? <CheckIcon className={styles.icon} /> : <FileCopyIcon className={styles.icon} />}
									{copied && <span className={styles["copy-text"]}>Copied!</span>}
								</div>
							</div>
							<div className={styles["copy-box"]}>
								<div className={styles.text}>{text}</div>
							</div>
							{`Take a look at our `}
							<a href="https://docs.aperturedata.io/HowToGuides/start/Setup#run-aperturedb-along-with-webui-using-docker-compose" className={styles["link"]} target="_blank" rel="noopener noreferrer">{`Quick Start Guide`}</a>.</div>
					</Grid>
					<Grid item xs={12} md={6} className={isSmallScreen ? styles["order-1"] : undefined}>
						<div className={styles["video-container"]}>
								<iframe
									className={styles.video}
									width="100%"
									height="100%"
                                    frameBorder="0"
									src="https://www.youtube.com/embed/VKtXzTOMTL0?si=-VR3c23hlBFVNkTr"
									title="Installing ApertureDB"
									allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowFullScreen
								/>
						</div>
					</Grid>
					<Grid item xs={12} md={12} className={isSmallScreen ? styles["order-2"] : undefined}>
						<div className={styles["email-row"]}>
							<TextField
								label="Your Email Here"
								variant="outlined"
								required={true}
								className={styles["email-field"]}
								type="mail"
								value={email}
								onChange={(
									event: React.ChangeEvent<HTMLInputElement>
								): void => setEmail(event.target.value)}
							/>
							<ActionButton
								name="Get Started"
								onClick={checkAndSubmit}
								style={style.submit}
								isDisabled={!(email && !isLoading)}
							/>
						</div>
						<span className={styles["text-helper"]}>
							{`* To receive instructions that include our frontend
							setup and relevant documentation via email, please provide your email above.`}
						</span>
					</Grid>
				</Grid>
				{isLoading && (
					<div className={styles.spinner}>
						<CircularProgress size={24} />
					</div>
				)}
			</div>
			<Snackbar
				anchorOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				open={openSnackbar}
				autoHideDuration={20000}
				message={snackbarMessage}
			/>
		</div>
	);
};

export default DockerForm;
