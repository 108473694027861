import styles from './Intro.module.scss';
import React from 'react';
import { BlogType } from '../../Blog';
import { ReactComponent as XSocialMedia } from '../../../../assets/images/Blogs/x.svg';
// import { ReactComponent as Reddit } from '../../../../assets/images/Blogs/reddit.svg';
import { ReactComponent as Linkeding } from '../../../../assets/images/Blogs/linke.svg';
import { ReactComponent as Share } from '../../../../assets/images/Blogs/share.svg';
import { UseViewPortWidth } from '../../Hook/UseViewPortWidth';

interface BlogsDetailsProps {
	data: BlogType;
}
const Intro: React.FC<BlogsDetailsProps> = ({ data }) => {
	const viewPortWidth = UseViewPortWidth();

	function handleCopyUrlToShare(texto: string) {
		const input = document.createElement('input');

		input.value = texto;
		document.body.appendChild(input);

		input.select();
		input.setSelectionRange(0, 99999);

		document.execCommand('copy');

		document.body.removeChild(input);
	}

	return (
		<div className={styles.introResources}>
			<div className={styles.sectionContainer}>
				<div className={styles.textContainer}>
					<div className={styles.headContainer}>
						<div className={styles.type}>{data?.type}</div>
						{viewPortWidth <= 950 &&

							<div className={styles.optionsContainer}>
								<a href={`https://twitter.com/intent/tweet?text=Hey!%20Check%20out%20this%20post!%20${window.location.href}%20%40ApertureData&original_referer=https://aperturedata.io/`} target='_blank' rel='noreferrer'>
									<XSocialMedia />
								</a>
								{/* <a href={data[0]?.reddit} target='_blank' rel='noreferrer'>
									<Reddit />
								</a> */}
								<a href={`https://www.linkedin.com/share?text=Hey!%20Check%20out%20this%20post!%20${window.location.href}%20%40ApertureData&original_referer=https://aperturedata.io/`} target='_blank' rel='noreferrer'>
									<Linkeding />
								</a>
								<a href={'#'}>
									<Share onClick={() => handleCopyUrlToShare(window.location.href)} />
								</a>
							</div>
						}
					</div>
					<div className={styles.title}>{data?.title}</div>
					<div className={styles.date}>{data?.date}</div>
				</div>
				<div className={styles.imgContainer}>
					<img src={data?.image} alt={data?.title} className={styles.img}/>
				</div>
			</div>
		</div>
	);
};

export default Intro;
