import styles from './BlogCard.module.scss';
import React from 'react';
import {
	Card,
	CardMedia,
	CardContent,
	Grid,
	Typography
} from '@material-ui/core';
import { BlogType } from '../Blog';
import { useNavigate } from 'react-router-dom';

interface BlogCardProps {
	blog: BlogType;
}

const BlogCard: React.FC<BlogCardProps> = (props) => {
	const navigate = useNavigate();

	const handleNavigate = (id: string) => {
		navigate(`/blog/${id}`);
	};

	return (
		<Grid item>
			<div className={styles.button} onClick={() => handleNavigate(props.blog.id)}>
				<Card className={styles.blog} elevation={0}>
					<div className={styles.imageContainer}>

						<CardContent className={styles['container-type']}>
							<Typography
								gutterBottom
								variant='h6'
								component='h2'
								className={styles.type}>
								{props.blog.type}
							</Typography>
						</CardContent>
						<CardMedia
							alt={props.blog.title}
							className={styles.media}
							component='img'
							image={props.blog.image}
							title={props.blog.title} />
					</div>
					<CardContent className={styles['container-text']}>
						<Typography
							gutterBottom
							className={styles.date}>
							{props.blog.date}
						</Typography>
						<Typography
							gutterBottom
							className={styles.title}>
							{props.blog.title}
						</Typography>
						<Typography
							className={styles.description}>
							{props.blog.description}
						</Typography>
					</CardContent>
				</Card>
			</div>
		</Grid>
	);
};

export default BlogCard;
