import styles from './JoinUs.module.scss';
import CompanyDescription from './sections/CompanyDescription';
import Intro from './sections/Intro';
import JobDetails from './sections/JobDetails';
import JobsSection from './sections/JobsSection';
import jobsSections from '../../assets/content/Jobs';
import React from 'react';
import { useParams } from 'react-router-dom';
import ContactUs from '../Landing/sections/ContactUs';

export type ContractType = 'Contract' | 'Full Time' | 'Part Time';

export interface JobType {
	id: number;
	title: string;
	contractType: ContractType;
	location: 'Remote' | string;
	description: string;
	minimumQualificationsIntro: string;
	minimumQualifications: string[];
	additionalQualificationsIntro: string;
	additionalQualifications: string[];
	ourPromiseIntro: string;
	ourPromise: string[];
}

export interface JobsSectionType {
	id: number;
	title: string;
	jobs: JobType[];
}

const showJobDetails = (
	sectionId: string | undefined,
	jobId: string | undefined
): JSX.Element => {
	const sectionIdNumber = Number.parseInt(sectionId || '0');
	const jobIdNumber = Number.parseInt(jobId || '0');

	if (Number.isNaN(sectionIdNumber) || Number.isNaN(jobIdNumber)) {
		return (
			<div style={{ marginTop: 70, marginBottom: 70 }}>
				We could not find this position. Please try again later.
			</div>
		);
	}

	const jobSection = jobsSections.find(
		(jobsSection) => jobsSection.id === sectionIdNumber
	);
	const job = jobSection
		? jobSection.jobs.find((job) => job.id === jobIdNumber)
		: undefined;

	return jobSection === undefined || job === undefined ? (
		<div className={styles['job-details-extension']}>
			We could not find this position. Please try again later.
		</div>
	) : (
		<JobDetails job={job} jobSection={jobSection.title} />
	);
};

const JoinUs: React.FC = () => {
	const { sectionId, jobId } = useParams();
	const showJob = sectionId !== undefined && jobId !== undefined;

	return (
		<div className={styles['join-us']}>
			<Intro />
			{!showJob ? (
				<>
					<CompanyDescription />
					{jobsSections.map((jobsSection: JobsSectionType, index: number) => (
						<>
							<JobsSection
								jobsSection={jobsSection}
								key={'section-' + jobsSection.id}
							/>
							{index < jobsSections.length - 1 && (
								<div className={styles['job-extension']}>
									<div className={styles.line} />
								</div>
							)}
						</>
					))}
				</>
			) : (
				showJobDetails(sectionId, jobId)
			)}
			<ContactUs />
		</div>
	);
};

export default JoinUs;
