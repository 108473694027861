import styles from './Faq.module.scss';
import FaqList from './sections/FaqList';
import FaqMenu from './sections/FaqMenu';
import faqs from '../../assets/content/FAQs';
import React from 'react';
import Intro from './sections/Intro';
import ContactUs from '../Landing/sections/ContactUs';

export interface BulletType {
	question: string;
	answer: string;
}

export interface FAQType {
	title: string;
	bullets: BulletType[];
}

const Faq: React.FC = () => {
	return (
		<div className={styles.faq}>
			<Intro />
			<div className={styles.row}>
				<div className={styles.menu}>
					<FaqMenu faqs={faqs} />
				</div>
				<div className={styles.list}>
					<FaqList faqs={faqs} />
				</div>
			</div>
			<ContactUs />
		</div>
	);
};

export default Faq;
