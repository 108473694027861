import NewList from './sections/NewList';
import news from '../../assets/content/News';
import React from 'react';
import styles from './News.module.scss';
import Intro from './sections/Intro';
import ContactUs from '../Landing/sections/ContactUs';

export interface NewType {
	title: string;
	description: string;
	date: string;
	image: string;
	link: string;
}

const News: React.FC = () => {
	return (
		<div className={styles.news}>
			<Intro />
			<NewList news={news} />
			<ContactUs />
		</div>
	);
};

export default News;
