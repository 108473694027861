import styles from "./Study.module.scss";
import { CaseStudyType } from "../CaseStudy";
import React from "react";
import Comment from "../../../components/Comment";

interface StudyProps {
	study: CaseStudyType;
}

const Study: React.FC<StudyProps> = ({ study }) => {

	const renderedParagraphs = (text: string) => text.split('\n');

	React.useEffect (() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className={styles.study}>
			<div className={styles.container}>
				<div className={styles.introduction}>
				<div className={styles.title}>{study.introduction.title}</div>
					{study.introduction.companyLogo ? (
						<div className={styles.text}>
							<div style={{ margin: "20px 40px 20px 20px" }}>{study.introduction.text}</div>
							<div className={styles.imageContainer}>
								<img
									key={study.introduction.title}
									src={study.introduction.companyLogo}
									alt={study.card.title}
									className={styles.image}
								/>
							</div>
						</div>
					) : (
						<div className={styles.text}>{study.introduction.text}</div>
					)}
					<div className={styles.images}>
						{study.introduction.images.map((image, index) => (
							<img key={index} src={image} alt={study.card.title} className={styles.image} />
						))}
					</div>
				</div>
				<div className={styles.textContainer}>
					<div className={styles.text}>
						{renderedParagraphs(study.text).map((line, index) => (
							<p key={index}>{line}</p>
						))}
					</div>
				</div>
				<div className={styles.articles}>
					{study.articles.map((article, index) => (
						<div className={styles.containerArticle} key={index}>
							<Comment comment={article.article.comment} author={article.article.authorComment} position={article.article.authorPosition}/>
							<div>
								{article.article.notices.map((notice, index) => (
									<div
										className={
											index % 2 !== 0
												? styles.containerNoticeWhiteBackground
												: notice.source &&
												notice.source.image &&
												notice.source.position &&
												notice.source.position === "BOTTOM" ?
													styles.containerNoticeLavenderWhiteBackground :
													`${styles.containerNoticeLavenderWhiteBackground} ${styles.containerPaddingBottom}`
										}
										key={index}
									>
										<div className={styles.containerTitle}>
										<div className={styles.title}>
											{notice.title}
											</div>
											</div>
										{notice.source &&
										notice.source.image &&
										notice.source.position &&
										notice.source.position === "BOTTOM" ? (
											<div className={styles.containerWidthDescription}>
												<div className={styles.text}>
													{renderedParagraphs(notice.text).map((line, index) => (
														<p key={index}>{line}</p>
													))}
												</div>
												<div className={styles.containerLargeImage}>
													<img key={index} src={notice.source.image} alt={notice.title} className={styles.image} />
												</div>
											</div>
										) : notice.source &&
											notice.source.image &&
											notice.source.position &&
											notice.source.position === "UP" ? (
											<div className={styles.containerWidthDescription}>
												<div className={styles.containerLargeImage}>
													<img key={index} src={notice.source.image} alt={notice.title} className={styles.image} />
												</div>
												<div className={styles.text}>
													{renderedParagraphs(notice.text).map((line, index) => (
														<p key={index}>{line}</p>
													))}
												</div>
											</div>
										) : notice.source && notice.source.image ? (
											<div className={styles.containerDescription}>
												<div className={styles.containerImage}>
													<img key={index} src={notice.source.image} alt={notice.title} className={styles.image} />
												</div>
												<div className={styles.text}>
													{renderedParagraphs(notice.text).map((line, index) => (
														<p key={index}>{line}</p>
													))}
												</div>
											</div>
										) : (
											<div className={styles.containerDescription}>
												<div className={styles.text}>
													{renderedParagraphs(notice.text).map((line, index) => (
														<p key={index}>{line}</p>
													))}
												</div>
											</div>
										)}
									</div>
								))}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Study;
