import { Button } from '@material-ui/core';
import React from 'react';

interface ActionButtonProps {
	onClick: () => void;
	name: string;
	isDisabled?: boolean;
	startIcon?: JSX.Element;
	endIcon?: JSX.Element;
	style?: object;
}

const ActionButton: React.FC<ActionButtonProps> = (props) => {
	let style: React.CSSProperties = props.style || {};

	if (props.isDisabled) {
		style = { ...style, backgroundColor: '#999' };
	}

	return (
		<Button
			style={style}
			onClick={props.onClick}
			disabled={props.isDisabled}
			startIcon={props.startIcon}
			endIcon={props.endIcon}
		>
			{
				props.name
			}
		</Button>
	);
};

export default ActionButton;
