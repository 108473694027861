import LuisImage from '../images/Company/luis.png';
import { TeamMemberType } from '../../pages/Company/sections/Team';
import VishakhaImage from '../images/Company/vishakha.jpg';

const teamMembers: TeamMemberType[] = [
	{
		name: 'Vishakha Gupta',
		position: 'CEO',
		description: 'Vishakha has over 7 years of experience at Intel Labs in designing and developing various systems technologies optimized for new hardware after a PhD in Computer Science from Georgia Tech and MS in Information Networking from Carnegie Mellon University.',
		image: VishakhaImage,
		url: 'https://www.linkedin.com/in/vishakha/',
	},
	{
		name: 'Luis Remis',
		position: 'CTO',
		description: 'Luis has over 5 years of industry experience in designing and developing accelerated signal processing algorithms, machine learning pipelines and systems software after MS in Computer Science from University of Illinois at Urbana-Champaign.',
		image: LuisImage,
		url: 'https://www.linkedin.com/in/luis-remis-08a316195/',
	},
];

export default teamMembers;
