import styles from "./FaqMenuItem.module.scss";
import { FAQType } from "../Faq";
import React from "react";

interface FaqMenuItemProps {
	faq: FAQType;
	showDivider: boolean;
}

const FaqMenuItem: React.FC<FaqMenuItemProps> = (props) => {
	return (
		<>
			<a href={"#" + props.faq.title} style={{ textDecoration: "none" }} className={styles["menu-item"]}>
				{props.faq.title}
			</a>
			{props.showDivider && <div className={styles.line} />}
		</>
	);
};

export default FaqMenuItem;
