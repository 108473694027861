import './index.scss';
import App from './App';
import React from 'react';
import { render } from 'react-dom';

const container = document.getElementById('root')!;

render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
container);
