import styles from './ContactUs.module.scss';
import ContactSection from './sections/ContactSection';
import Intro from './sections/Intro';
import React from 'react';

const ContactUs: React.FC = () => {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className={styles['contact-us-page']}>
			<Intro />
			<ContactSection />
		</div>
	);
};

export default ContactUs;
