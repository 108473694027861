import React, { useState } from 'react';
import styles from './CaseStudiesCarousel.module.scss';
import { CaseStudyType } from '../../CaseStudy/CaseStudy';
import { useNavigate } from "react-router-dom";

interface CaseStudiesCarouselProps {
	caseStudies: CaseStudyType[];
}

const CaseStudiesCarousel: React.FC<CaseStudiesCarouselProps> = ({ caseStudies }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
	const navigate = useNavigate();

  const nextSlide = () => {
    const newSlide = (currentSlide + 1) % caseStudies.length;

    setCurrentSlide(newSlide);
  };

  const prevSlide = () => {
    const newSlide = currentSlide - 1 < 0 ? caseStudies.length - 1 : currentSlide - 1;

    setCurrentSlide(newSlide);
  };

  return (
    <div className={styles.carousel}>
    <div className={styles.carouselContainer}>
      <div className={styles.carouselContent}>
        {caseStudies.length >= 2 && (
          <button className={styles.carouselPrev} onClick={prevSlide}/>
        )}
        <div className={styles.carouselText}>
          <h2 className={styles.carouselTitle}>{caseStudies[currentSlide].card.titleCarousel}</h2>
          <p className={styles.carouselDescription}>{caseStudies[currentSlide].card.descriptionCarousel}</p>
        </div>
      </div>
      <div className={styles.carouselImage}>
        <img className={styles.image} src={caseStudies[currentSlide].card.image} alt={caseStudies[currentSlide].card.title} />
        <div className={styles.overlayContent}>
          <span className={styles.overlayText}>{`CASE STUDY`}</span>
          <button className={styles.overlayReadMore} onClick={(): void => navigate(`../case-studies/${caseStudies[currentSlide].id}`)}>{`read more`}</button>
        </div>
        {caseStudies.length >= 2 && (
          <button className={styles.carouselNext} onClick={nextSlide}/>
        )}
      </div>
    </div>
    </div>
  );
};

export default CaseStudiesCarousel;
