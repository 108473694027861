import React from 'react';
import ContactUs from '../../Landing/sections/ContactUs';
import styles from './PrivacyPolicy.module.scss';

const PrivacyPolicy: React.FC = () => {
	return (
		<div>
			<div className={styles['intro-privacy-policy']}>
				<div className={styles['section-container']}>
					<h1>Privacy Policy</h1>
				</div>
			</div>
			<div className={styles['title-container']}>
				<p style={{ textAlign: 'center' }}>
					<strong>ApertureData Privacy Policy&nbsp;</strong>
				</p>
				<p>
					<span className={styles.span}>
						ApertureData Inc. (“ApertureData,” “we,” “our,” and/or “us”) values
						the privacy of individuals&nbsp; who use our websites and related
						services (collectively, our “Services”). This privacy policy
						(the&nbsp; “Privacy Policy”) explains how we collect, use, and share
						information from users of our Services&nbsp; (“Users”). By using our
						Services, you agree to the collection, use, disclosure, and
						procedures&nbsp; this Privacy Policy describes. Beyond the Privacy
						Policy, your use of our Services is also subject&nbsp; to our{' '}
						<a href='terms-of-service'>Terms of Service</a>.&nbsp;
					</span>
				</p>
				<p>
					<strong>Information We Collect&nbsp;</strong>
				</p>
				<p>
					<span className={styles.span}>
						We may collect a variety of information from or about you or your
						devices from various&nbsp; sources, as described below.&nbsp;
					</span>
				</p>
				<ol type='A'>
					<li>
						<strong> Information You Provide to Us.&nbsp;</strong>
					</li>
					<p>
						<strong>Communications</strong>
						<span className={styles.span}>
							. If you contact us directly, we may receive information about
							you. For&nbsp; example, when you contact us, we will receive your
							name, email address, phone number, the&nbsp; contents of a message
							or attachments that you may send to us, and other information
							you&nbsp; choose to provide. When we send you emails, we may track
							whether you open them to learn&nbsp; how to deliver a better
							customer experience and improve our Services.&nbsp;
						</span>
					</p>
					<p>
						<strong>Careers. </strong>
						<span className={styles.span}>
							If you decide that you wish to apply for a job with us, you may
							submit your contact&nbsp; information and your resume online. We
							will collect the information you choose to provide on&nbsp; your
							resume, such as your education and employment experience. You may
							also apply through&nbsp; job sites such as LinkedIn, AngelList, or
							Handshake. If you do so, we will collect the information&nbsp; you
							make available to us on those sites.&nbsp;&nbsp;
						</span>
					</p>
					<li>
						<strong> Information We Collect When You Use Our Services</strong>
						<span className={styles.span}>.&nbsp;&nbsp;</span>
					</li>
					<p>
						<strong>Location Information</strong>
						<span className={styles.span}>
							. When you use our Services, we may infer your general
							location&nbsp; information, for example by using your internet
							protocol (IP) address.&nbsp;&nbsp;
						</span>
					</p>
					<p>
						<strong>Device Information</strong>
						<span className={styles.span}>
							. We receive information about the device and software you use to
							access&nbsp; our Services, including IP address, web browser type,
							operating system version.&nbsp;&nbsp;
						</span>
					</p>
					<p>
						<strong>Usage Information</strong>
						<span className={styles.span}>
							. To help us understand how you use our Services and to help us
							improve&nbsp; them, we automatically receive information about
							your interactions with our Services, like the&nbsp; pages or other
							content you view, the searches you conduct, and the dates and
							times of your&nbsp; visit.&nbsp;
						</span>
					</p>
					<p>
						<strong>Information from Cookies and Similar Technologies. </strong>
						<span className={styles.span}>
							We and third party partners collect{' '}
						</span>
						<span className={styles.span}>&nbsp;</span>
						<span className={styles.span}>
							information using cookies, pixel tags, or similar technologies.{' '}
						</span>
						<span className={styles.span}>
							Our third party partners, such as&nbsp;
						</span>
					</p>
					<p>
						<span className={styles.span}>
							analytics and advertising partners, may use these technologies to
							collect information about&nbsp; your online activities over time
							and across different services.{' '}
						</span>
						<span className={styles.span}>Cookies are small text files </span>
						<span className={styles.span}>&nbsp;</span>
						<span className={styles.span}>
							containing a string of alphanumeric characters. We may use both
							session cookies and persistent{' '}
						</span>
						<span className={styles.span}>&nbsp;</span>
						<span className={styles.span}>
							cookies. A session cookie disappears after you close your browser.
							A persistent cookie remains{' '}
						</span>
						<span className={styles.span}>&nbsp;</span>
						<span className={styles.span}>
							after you close your browser and may be used by your browser on
							subsequent visit
						</span>
						<span className={styles.span}>s to our&nbsp; Services.&nbsp;</span>
					</p>
					<p>
						<span className={styles.span}>
							Please review your web browser’s “Help” file to learn the proper
							way to modify your cookie
						</span>
						<span className={styles.span}>&nbsp; </span>
						<span className={styles.span}>
							settings. Please note that if you delete or choose not to accept
							cookies from the Service, you{' '}
						</span>
						<span className={styles.span}>&nbsp;</span>
						<span className={styles.span}>
							may not be able to utilize the features of the Service to their
							fullest potential.
						</span>
						<span className={styles.span}>&nbsp;</span>
					</p>
				</ol>
				<p>
					<strong>How We Use the Information We Collect&nbsp;</strong>
				</p>
				<p>
					<span className={styles.span}>
						We use the information we collect:&nbsp;
					</span>
				</p>
				<ul>
					<li>
						<span className={styles.span}>
							{' '}
							To provide, maintain, improve, and enhance our
							Services;&nbsp;&nbsp;
						</span>
					</li>
					<li>
						<span className={styles.span}>
							{' '}
							To understand and analyze how you use our Services and develop new
							products,&nbsp; services, features, and functionality;&nbsp;
						</span>
					</li>
					<li>
						<span className={styles.span}>
							{' '}
							To communicate with you, provide you with updates and other
							information relating to&nbsp; our Services, provide information
							that you request, respond to comments and&nbsp; questions, and
							otherwise provide customer support;&nbsp;
						</span>
					</li>
					<li>
						<span className={styles.span}>
							{' '}
							To generate anonymized, aggregate data containing only
							de-identified, non-personal&nbsp; information that we may use for
							any lawful purpose;&nbsp;
						</span>
					</li>
					<li>
						<span className={styles.span}>
							{' '}
							To find and prevent fraud, and respond to trust and safety issues
							that may arise;&nbsp; ● For compliance purposes, including{' '}
						</span>
						<span className={styles.span}>
							enforcing our Terms of Service or other legal rights,{' '}
						</span>
						<span className={styles.span}>
							or as may be required by applicable laws and regulations or
							requested by any judicial{' '}
						</span>
						<span className={styles.span}>
							process or governmental agency; and
						</span>
						<span className={styles.span}>&nbsp;</span>
					</li>
					<li>
						<span className={styles.span}>
							{' '}
							For other purposes for which we provide specific notice at the
							time the information is{' '}
						</span>
						<span className={styles.span}>collected.</span>
					</li>
				</ul>
				<p>
					<strong>How We Share the Information We Collect</strong>
					<strong>&nbsp;</strong>
				</p>
				<p>
					<strong>Vendors and Service Providers. </strong>
					<span className={styles.span}>
						We may share any information we receive with vendors and{' '}
					</span>
					<span className={styles.span}>
						&nbsp;service providers retained in connection with the provision of
						our Services.&nbsp;&nbsp;
					</span>
				</p>
				<p>
					<strong>Marketing. </strong>
					<span className={styles.span}>
						We do not rent, sell, or share information about you with non
						affiliated companies&nbsp; for their direct marketing purposes,
						unless we have your permission.&nbsp;&nbsp;
					</span>
				</p>
				<p>
					<strong>Analytics Partners. </strong>
					<span className={styles.span}>
						We use analytics services such as Google Analytics to collect and
						process&nbsp; certain analytics data. These services may also
						collect information about your use of other&nbsp; websites, apps,
						and online resources. You can learn more about Google’s practices by
						visiting{' '}
					</span>
					<a
						href='https://www.google.com/policies/privacy/partners/'
						style={{ fontWeight: 400 }}
					>
						https://www.google.com/policies/privacy/partners/
					</a>
					<span className={styles.span}>
						. To help us understand how you use our&nbsp; Services and to help
						us improve them, we automatically receive information about
						your&nbsp; interactions with our Services, like the pages or other
						content you view, the searches you conduct, and the dates and times
						of your visits.&nbsp;
					</span>
				</p>
				<p>
					<strong>As Required By Law and Similar Disclosures. </strong>
					<span className={styles.span}>
						We may access, preserve, and disclose your&nbsp; information if we
						believe doing so is required or appropriate to: (a) comply with
						law&nbsp; enforcement requests and legal process, such as a court
						order or subpoena; (b) respond to your&nbsp; requests; or (c)
						protect your, our, or others’ rights, property, or safety. For the
						avoidance of&nbsp; doubt, the disclosure of your information may
						occur if you post any objectionable content on or&nbsp; through the
						Services.&nbsp;
					</span>
				</p>
				<p>
					<strong>Merger, Sale, or Other Asset Transfers. </strong>
					<span className={styles.span}>
						We may transfer your information to service providers,{' '}
					</span>
					<span className={styles.span}>&nbsp;</span>
					<span className={styles.span}>
						advisors, potential transactional partners, or other third parties
						in connection with the{' '}
					</span>
					<span className={styles.span}>&nbsp;</span>
					<span className={styles.span}>
						consideration, negotiation, or completion of a corporate transaction
						in which we are acquired{' '}
					</span>
					<span className={styles.span}>&nbsp;</span>
					<span className={styles.span}>
						by or merged with another company or we sell, liquidate, or transfer
						all or a portion of our{' '}
					</span>
					<span className={styles.span}>&nbsp;</span>
					<span className={styles.span}>
						assets. The use of your information following any of these events
						will be governed by the{' '}
					</span>
					<span className={styles.span}>&nbsp;</span>
					<span className={styles.span}>
						provisions of this Privacy Policy in effect at the time the
						applicable information was collected.
					</span>
					<span className={styles.span}>&nbsp;</span>
				</p>
				<p>
					<strong>Consent. </strong>
					<span className={styles.span}>
						We may also disclose your information with your permission.&nbsp;
					</span>
				</p>
				<p>
					<strong>Third Parties&nbsp;</strong>
				</p>
				<p>
					<span className={styles.span}>
						Our Services may contain links to other websites, products, or
						services that we do not own or&nbsp; operate. We are not responsible
						for the privacy practices of these third parties. Please be&nbsp;
						aware that this Privacy Policy does not apply to your activities on
						these third party services or&nbsp; any information you disclose to
						these third parties. We encourage you to read their privacy&nbsp;
						policies before providing any information to them.&nbsp;
					</span>
				</p>
				<p>
					<strong>Security&nbsp;&nbsp;</strong>
				</p>
				<p>
					<span className={styles.span}>
						We make reasonable efforts to protect your information by using
						physical and electronic&nbsp; safeguards designed to improve the
						security of the information we maintain. However, as no&nbsp;
						electronic transmission or storage of information can be entirely
						secure, we can make no&nbsp; guarantees as to the security or
						privacy of your information.&nbsp;
					</span>
				</p>
				<p>
					<strong>Children’s Privacy&nbsp;</strong>
				</p>
				<p>
					<span className={styles.span}>
						We do not knowingly collect, maintain, or use personal information
						from children under 13&nbsp; years of age, and no part of our
						Services are directed to children. If you learn that a child
						has&nbsp; provided us with personal information in violation of this
						Privacy Policy, then you may alert us&nbsp; at{' '}
					</span>
					<span className={styles.span}>privacy@aperturedata.io</span>
					<span className={styles.span}>.&nbsp;&nbsp;</span>
				</p>
				<p>
					<strong>International Visitors&nbsp;</strong>
				</p>
				<p>
					<span className={styles.span}>
						Our Services are hosted in the United States (“U.S.”) and intended
						for visitors located within the&nbsp; United States. If you choose
						to use the Services from the European Union or other regions
						of&nbsp; the world with laws governing data collection and use that
						may differ from U.S. law, then&nbsp;
					</span>
				</p>
				<p>
					<span className={styles.span}>
						please note that you are transferring your personal information
						outside of those regions to the&nbsp; U.S. for storage and
						processing. Also, we may transfer your data from the U.S. to
						other&nbsp; countries or regions in connection with storage and
						processing of data, fulfilling your requests,&nbsp; and operating
						the Services. By providing any information, including personal
						information, on or&nbsp; to the Services, you c
					</span>
					<span className={styles.span}>
						onsent to such transfer, storage, and processing.
					</span>
					<span className={styles.span}>&nbsp;</span>
				</p>
				<p>
					<strong>Changes to this Privacy Policy</strong>
					<strong>&nbsp;</strong>
				</p>
				<p>
					<span className={styles.span}>
						We will post any adjustments to the Privacy Policy on this page, and
						the revised version will be{' '}
					</span>
					<span className={styles.span}>&nbsp;</span>
					<span className={styles.span}>
						effective when it is posted. If we materially change the ways in
						which we use or share personal{' '}
					</span>
					<span className={styles.span}>&nbsp;</span>
					<span className={styles.span}>information previously c</span>
					<span className={styles.span}>
						ollected from you through the Services, we will notify you through
						the&nbsp; Services, by email, or othe
					</span>
					<span className={styles.span}>r communication.</span>
					<span className={styles.span}>&nbsp;</span>
				</p>
				<p>
					<strong>Contact Information&nbsp;</strong>
				</p>
				<p>
					<span className={styles.span}>
						If you have any questions, comments, or concerns about our
						processing activities, please email&nbsp; us at{' '}
					</span>
					<span className={styles.span}>privacy@aperturedata.io</span>
					<span className={styles.span}>.&nbsp;</span>
				</p>
				<p>
					<strong>Last Updated: </strong>
					<span className={styles.span}>09/21/2020</span>
				</p>
			</div>
			<ContactUs />
		</div>
	);
};

export default PrivacyPolicy;
