import React from 'react';
import ContactUs from '../../Landing/sections/ContactUs';
import styles from './TermsOfService.module.scss';

const TermsOfService: React.FC = () => {
	return (
		<div>
			<div className={styles['intro-terms-of-service']}>
				<div className={styles['section-container']}>
					<h1>Terms of Service</h1>
				</div>
			</div>
			<div className={styles['title-container']}>
				<p style={{ textAlign: 'center' }}>
					<strong>ApertureData Terms of Service</strong>
				</p>
				<p>
					<span className={styles.spanItalic}>Last Updated: 09/21/2020 </span>
					<span className={styles.span}>
						<br />
						<br />
					</span>
				</p>
				<p>
					<span className={styles.span}>
						Welcome, and thank you for your interest in ApertureData Inc. (“
					</span>
					<strong>ApertureData</strong>
					<span className={styles.span}>,” “</span>
					<strong>we</strong>
					<span className={styles.span}>,” “</span>
					<strong>our</strong>
					<span className={styles.span}>,” or “</span>
					<strong>us</strong>
					<span className={styles.span}>
						”) and our website at www.aperturedata.io, along with our related
						websites, networks, applications, clients, software, and other
						services provided by us (collectively, the “
					</span>
					<strong>Service</strong>
					<span className={styles.span}>
						”). These Terms of Service are a legally binding contract between
						you and ApertureData regarding your use of the Service.
					</span>
				</p>
				<p style={{ textAlign: 'center' }}>
					<strong>PLEASE READ THE FOLLOWING TERMS CAREFULLY:</strong>
				</p>
				<p>
					<strong>BY CLICKING</strong>
					<span className={styles.span}> “</span>
					<strong>I ACCEPT</strong>
					<span className={styles.span}>,” </span>
					<strong>
						OR “DEMO TRIAL,” OR BY ENTERING INTO AN ENTERPRISE AGREEMENT,
						DOWNLOADING, INSTALLING, OR OTHERWISE ACCESSING OR USING THE SERVICE
					</strong>
					<span className={styles.span}>
						, YOU AGREE THAT YOU HAVE READ AND UNDERSTOOD, AND, AS A CONDITION
						TO YOUR USE OF THE SERVICE, YOU AGREE TO BE BOUND BY, THE FOLLOWING
						TERMS AND CONDITIONS, INCLUDING APERTUREDATA’S PRIVACY POLICY
						(TOGETHER, THESE “
					</span>
					<strong>TERMS</strong>
					<span className={styles.span}>
						”). IF YOU ARE NOT ELIGIBLE, OR DO NOT AGREE TO THE TERMS, THEN YOU
						DO NOT HAVE OUR PERMISSION TO USE THE SERVICE. YOUR USE OF THE
						SERVICE, AND APERTUREDATA’S PROVISION OF THE SERVICE TO YOU,
						CONSTITUTES AN AGREEMENT BY APERTUREDATA AND BY YOU TO BE BOUND BY
						THESE TERMS.{' '}
					</span>
					<span className={styles.span}>
						<br />
					</span>
					<strong>
						<br />
					</strong>
					<strong>ARBITRATION</strong>
					<strong> NOTICE</strong>
					<span className={styles.span}>
						. EXCEPT FOR CERTAIN KINDS OF DISPUTES DESCRIBED IN SECTION&nbsp;14,
						YOU AGREE THAT DISPUTES ARISING UNDER THESE TERMS WILL BE RESOLVED
						BY BINDING, INDIVIDUAL ARBITRATION, AND BY ACCEPTING THESE TERMS,
						YOU AND APERTUREDATA ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY
						OR REPRESENTATIVE PROCEEDING. YOU AGREE TO GIVE UP YOUR RIGHT TO GO
						TO COURT TO ASSERT OR DEFEND YOUR RIGHTS UNDER THIS CONTRACT (EXCEPT
						FOR MATTERS THAT MAY BE TAKEN TO SMALL CLAIMS COURT). YOUR RIGHTS
						WILL BE DETERMINED BY A NEUTRAL ARBITRATOR AND NOT A JUDGE OR JURY.
						(
					</span>
					<em>
						<span className={styles.span}>SEE</span>
					</em>
					<span className={styles.span}> SECTION&nbsp;14.)</span>
					<span className={styles.span}>&nbsp;</span>
				</p>
				<ol className={styles.olContainer}>
					<ol>
						<li>
							<p>
								<strong>ApertureData Service Overview</strong>
								<span className={styles.span}>
									. ApertureData offers ApertureDB, a unique database specially
									designed for multimodal data, as well as a software client that
									users can install on their local computer to interact with the
									server (together, the “
								</span>
								<strong>Software</strong>
								<span className={styles.span}>
									”). The Software stores and manages images, videos, and
									associated metadata like annotations and feature vectors. It
									natively supports complex searching and preprocessing
									operations like zoom, crop over media objects to enable
									analytics use cases. Users must install the Software on their
									own cloud or on-premise hardware, or use the “sandbox” server
									hosted by ApertureData.
								</span>
							</p>
						</li>
						<li>
							<p>
								<strong>Eligibility</strong>
								<span className={styles.span}>
									. You must be at least 18-years old to use the Service. By
									agreeing to these Terms, you represent and warrant to us that:
									(1) you are at least 18-years old; (2) you have not previously
									been suspended or removed from the Service; and (3) your
									registration and your use of the Service is in compliance with
									any and all applicable laws and regulations. If you are an
									entity, organization, or company, the individual accepting
									these Terms on your behalf represents and warrants that they
									have authority to bind you to these Terms, you agree to be
									bound by these Terms, and any references herein to “you” will
									include you and your authorized users.&nbsp;
								</span>
							</p>
						</li>
						<li>
							<p>
								<strong>General Payment Terms</strong>
								<span className={styles.span}>
									. Certain features of the Service may require you to pay fees.
									Before you pay any fees, you will have an opportunity to
									review and accept the fees that you will be charged. All fees
									are in U.S. Dollars and are non-refundable.
								</span>
							</p>
						</li>
						<ol>
							<li>
								<p>
									<strong>Price</strong>
									<span className={styles.span}>
										. ApertureData reserves the right to determine pricing for
										the Service. ApertureData may change the fees for any
										feature of the Service, including additional fees or
										charges, if ApertureData gives you advance notice of changes
										before they apply. Pricing for Enterprise Licenses will be
										as set forth in the relevant Enterprise Agreement (both
										terms as defined in Section 4.2 below). ApertureData, at its
										sole discretion, may make promotional offers with different
										features and different pricing to any of ApertureData’s
										customers. These promotional offers, unless made to you,
										will not apply to your offer or these Terms.
									</span>
								</p>
							</li>
							<li>
								<p>
									<strong>Authorization</strong>
									<span className={styles.span}>
										. You authorize ApertureData to charge all sums for the
										orders that you make and any level of Service you select as
										described in these Terms or published by ApertureData,
										including all applicable taxes, to the payment method
										specified in your account. If you pay any fees with a credit
										card, ApertureData may seek pre-authorization of your credit
										card account prior to your purchase to verify that the
										credit card is valid and has the necessary funds or credit
										available to cover your purchase.&nbsp;
									</span>
								</p>
							</li>
							<li>
								<p>
									<strong>Delinquent Accounts</strong>
									<span className={styles.span}>
										. ApertureData may suspend or terminate access to the
										Service, including fee-based portions of the Service, for
										any account for which any amount is due but unpaid. In
										addition to the amount due for the Service, a delinquent
										account will be charged with fees or charges that are
										incidental to any chargeback or collection of any the unpaid
										amount, including collection fees.&nbsp;
									</span>
								</p>
							</li>
						</ol>
						<li>
							<p>
								<strong>Licenses</strong>
							</p>
						</li>
						<ol>
							<li>
								<p>
									<strong>Limited Trial License</strong>
									<span className={styles.span}>
										. Upon submitting your email address and clicking “Demo
										Trial” or “Request Free Trial” on our website, you will
										receive an email with instructions and access information
										for a free trial of the Software (a “
									</span>
									<strong>Trial Email</strong>
									<span className={styles.span}>
										”). Subject to your complete and ongoing compliance with
										these Terms and upon your receipt of a Trial Email from
										ApertureData, ApertureData grants you, solely for your
										personal, non-commercial use, a limited, non-exclusive,
										non-transferable, non-sublicensable, revocable trial license
										(the “
									</span>
									<strong>Trial License</strong>
									<span className={styles.span}>
										”) to access and use the Software as set forth in the Trial
										Email. Your access to the Software, and the applicable Trial
										License, will automatically expire 60 minutes after you
										request a Trial Email. Upon the expiration of the Trial
										License, all content and data you have uploaded or used with
										the Software will be automatically deleted.&nbsp;
									</span>
								</p>
							</li>
							<li>
								<p>
									<strong>Enterprise License</strong>
									<span className={styles.span}>
										. After the expiration of the Trial License and upon mutual
										execution of a separate agreement, order form, purchase
										order, invoice, or other agreement (such agreement, an “
									</span>
									<strong>Enterprise Agreement</strong>
									<span className={styles.span}>
										”), ApertureData will grant you license(s) to the Service
										(the “
									</span>
									<strong>Enterprise License</strong>
									<span className={styles.span}>
										”). The terms of the Enterprise License will be set forth in
										the Enterprise Agreement and subject to these Terms. Unless
										stated otherwise in the Enterprise Agreement, an Enterprise
										License grants you, solely for your commercial use, a
										limited, non-exclusive, non-transferable, non-sublicensable,
										revocable license to use the Software. These Terms will
										control over any additional, different or conflicting terms
										provided by you in an Enterprise Agreement (such terms, “
									</span>
									<strong>Customer Terms</strong>
									<span className={styles.span}>
										”). Any Customer Terms are hereby expressly rejected by
										ApertureData.
									</span>
								</p>
							</li>
							<li>
								<p>
									<strong>License Restrictions</strong>
									<span className={styles.span}>
										. Except and solely to the extent such a restriction is
										impermissible under applicable law, you may not: (a)
										reproduce, distribute, publicly display, or publicly perform
										the Service; (b) make modifications to the Service; or (c)
										interfere with or circumvent any feature of the Service,
										including any security or access control mechanism. If you
										are prohibited under applicable law from using the Service,
										you may not use it.
									</span>
								</p>
							</li>
							<li>
								<p>
									<strong>
										<strong>Feedback</strong>
										<span className={styles.span}>
											. If you choose to provide input and suggestions regarding
											problems with or proposed modifications or improvements to
											the Service (“
										</span>
										<strong>Feedback</strong>
										<span className={styles.span}>
											”), then you hereby grant ApertureData an unrestricted,
											perpetual, irrevocable, non-exclusive, fully-paid,
											royalty-free right to exploit the Feedback in any manner
											and for any purpose, including to improve the Service and
											create other products and services.
										</span>
									</strong>
								</p>
							</li>
						</ol>
						<li>
							<p>
								<strong>Ownership; Proprietary Rights</strong>
								<span className={styles.span}>
									. The Service is owned and operated by ApertureData. The
									visual interfaces, graphics, design, compilation, information,
									data, computer code (including source code or object code),
									products, software, services, and all other elements of the
									Service (“
								</span>
								<strong>Materials</strong>
								<span className={styles.span}>
									”) provided by ApertureData are protected by intellectual
									property and other laws. All Materials included in the Service
									are the property of ApertureData or its third-party licensors.
									Except as expressly authorized by ApertureData, you may not
									make use of the Materials. ApertureData reserves all rights to
									the Materials not granted expressly in these Terms.
								</span>
							</p>
						</li>
						<li>
							<p>
								<strong>Third-Party Terms</strong>
							</p>
							<ol>
								<li>
									<p>
										<strong>
											<strong>Third-Party Services and Linked Websites</strong>
											<span className={styles.span}>
												. ApertureData may provide tools through the Service
												that enable you to export information, including user
												data or content, to third-party services. By using one
												of these tools, you agree that ApertureData may transfer
												that information to the applicable third-party service.
												Third-party services are not under ApertureData’s
												control, and, to the fullest extent permitted by law,
												ApertureData is not responsible for any third-party
												service’s use of your exported information. The Service
												may also contain links to third-party websites. Linked
												websites are not under ApertureData’s control, and
												ApertureData is not responsible for their content.
											</span>
										</strong>
									</p>
								</li>
								<li>
									<p>
										<strong>
											<strong>Third-Party Services and Linked Websites</strong>
											<span className={styles.span}>
												. ApertureData may provide tools through the Service
												that enable you to export information, including user
												data or content, to third-party services. By using one
												of these tools, you agree that ApertureData may transfer
												that information to the applicable third-party service.
												Third-party services are not under ApertureData’s
												control, and, to the fullest extent permitted by law,
												ApertureData is not responsible for any third-party
												service’s use of your exported information. The Service
												may also contain links to third-party websites. Linked
												websites are not under ApertureData’s control, and
												ApertureData is not responsible for their content.
											</span>
										</strong>
									</p>
								</li>
								<li>
									<p>
										<strong>Third-Party Software</strong>
										<span className={styles.span}>
											. The Service may include or incorporate third-party
											software components that are generally available free of
											charge under licenses granting recipients broad rights to
											copy, modify, and distribute those components (“
										</span>
										<strong>Third-Party Components</strong>
										<span className={styles.span}>
											”). Although the Service is provided to you subject to
											these Terms, nothing in these Terms prevents, restricts,
											or is intended to prevent or restrict you from obtaining
											Third-Party Components under the applicable third-party
											licenses or to limit your use of Third-Party Components
											under those third-party licenses.&nbsp;
										</span>
									</p>
								</li>
							</ol>
						</li>
						<li>
							<p>
								<strong>Communications</strong>
								<span className={styles.span}>
									. We may send you emails concerning our products and services,
									as well as those of third parties. You may opt out of
									promotional emails by following the unsubscribe instructions
									in the promotional email itself.
								</span>
							</p>
						</li>
						<li>
							<p>
								<strong>Prohibited Conduct</strong>
								<span className={styles.span}>
									. BY USING THE SERVICE, YOU AGREE NOT TO (AND, IF APPLICABLE,
									YOU WILL PROHIBIT YOUR AUTHORIZED USERS TO):&nbsp;
								</span>
							</p>
							<ol>
								<li>
									<span className={styles.span}>
										use the Service for any illegal purpose or in violation of
										any local, state, national, or international law;
									</span>
								</li>
								<li>
									<span className={styles.span}>
										violate, or encourage others to violate, any right of a
										third party, including by infringing or misappropriating any
										third-party intellectual property right;
									</span>
								</li>
								<li>
									<span className={styles.span}>
										interfere with security-related features of the Service,
										including by: (a) disabling or circumventing features that
										prevent or limit use or copying of any content; or
										(b)&nbsp;reverse engineering or otherwise attempting to
										discover the source code of any portion of the Service
										except to the extent that the activity is expressly
										permitted by applicable law;
									</span>
								</li>
								<li>
									<span className={styles.span}>
										interfere with the operation of the Service or any user’s
										enjoyment of the Service, including by: (a)&nbsp;uploading
										or otherwise disseminating any virus, adware, spyware, worm,
										or other malicious code; (b)&nbsp;making any unsolicited
										offer or advertisement to another user of the Service;
										(c)&nbsp;collecting personal information about another user
										or third party without consent; or (d)&nbsp;interfering with
										or disrupting any network, equipment, or server connected to
										or used to provide the Service;
									</span>
								</li>
								<li>
									<span className={styles.span}>
										perform any fraudulent activity including impersonating any
										person or entity, claiming a false affiliation, accessing
										any other Service account without permission, or falsifying
										your age or date of birth;
									</span>
								</li>
								<li>
									<span className={styles.span}>
										sell or otherwise transfer the access granted under these
										Terms or any Materials (as defined in Section&nbsp;5) or any
										right or ability to view, access, or use any Materials; or
									</span>
								</li>
								<li>
									<span className={styles.span}>
										attempt to do any of the acts described in this
										Section&nbsp;8 or assist or permit any person in engaging in
										any of the acts described in this Section&nbsp;8.
									</span>
								</li>
							</ol>
						</li>
						<li>
							<p>
								<strong>Modification of Terms</strong>
								<span className={styles.span}>
									. We reserve the right to change these Terms on a
									going-forward basis at any time upon 7 days’ notice. Please
									check these Terms periodically for changes. If a change to
									these Terms materially modifies your rights or obligations, we
									may require that you accept the modified Terms in order to
									continue to use the Service. Material modifications are
									effective upon your acceptance of the modified Terms.
									Immaterial modifications are effective upon publication.
									Except as expressly permitted in this Section&nbsp;9, these
									Terms may be amended only by a written agreement signed by
									authorized representatives of the parties to these Terms.
									Disputes arising under these Terms will be resolved in
									accordance with the version of these Terms that was in effect
									at the time the dispute arose.&nbsp;
								</span>
							</p>
						</li>
						<li>
							<p>
								<strong>
									Term, Termination and Modification of the Service
								</strong>
							</p>
							<ol>
								<li>
									<p>
										<strong>Term</strong>
										<span className={styles.span}>
											. These Terms are effective beginning when you accept the
											Terms or first download, install, access, or use the
											Service, and ending when terminated as described in
											Section&nbsp;10.2.
										</span>
									</p>
								</li>
								<li>
									<p>
										<strong>Termination</strong>
										<span className={styles.span}>
											. If you violate any provision of these Terms, your
											authorization to access the Service and these Terms
											automatically terminate. In addition, ApertureData may, at
											its sole discretion, terminate these Terms or your account
											on the Service, or suspend or terminate your access to the
											Service, at any time for any reason or no reason, with or
											without notice. You may these Terms at any time by
											uninstalling the Software and deleting all
											Materials.&nbsp;
										</span>
									</p>
								</li>
								<li>
									<p>
										<strong>
											<strong>Effect of Termination</strong>
											<span className={styles.span}>
												. Upon termination of these Terms: (a) your license
												rights will terminate and you must immediately cease all
												use of the Service; (b) you will no longer be authorized
												to access the Service; (c) you must pay ApertureData any
												unpaid amount that was due prior to termination; and (d)
												all payment obligations accrued prior to termination and
												Sections 4.4, 5, 10.3, 11, 12, 13, 14, and 15 will
												survive.&nbsp;
											</span>
										</strong>
									</p>
								</li>
								<li>
									<p>
										<strong>Modification of the Service</strong>
										<span className={styles.span}>
											. ApertureData reserves the right to modify or discontinue
											the Service at any time (including by limiting or
											discontinuing certain features of the Service),
											temporarily or permanently, without notice to you.
											ApertureData will have no liability for any change to the
											Service or any suspension or termination of your access to
											or use of the Service.&nbsp;
										</span>
									</p>
								</li>
							</ol>
						</li>
						<li>
							<p>
								<strong>Indemnity. </strong>
								<span className={styles.span}>
									To the fullest extent permitted by law, you are responsible
									for your use of the Service, and you will defend and indemnify
									ApertureData, its affiliates and their respective
									shareholders, directors, managers, members, officers,
									employees, consultants, and agents (together, the&nbsp;“
								</span>
								<strong>ApertureData Entities</strong>
								<span className={styles.span}>
									”) from and against every claim brought by a third party, and
									any related liability, damage, loss, and expense, including
									reasonable attorneys’ fees and costs, arising out of or
									connected with: (1) your unauthorized use of, or misuse of,
									the Service; (2) your violation of any portion of these Terms,
									any representation, warranty, or agreement referenced in these
									Terms, or any applicable law or regulation; (3) your violation
									of any third-party right, including any intellectual property
									right or publicity, confidentiality, other property, or
									privacy right; or (4) any dispute or issue between you and any
									third party. We reserve the right, at our own expense, to
									assume the exclusive defense and control of any matter
									otherwise subject to indemnification by you (without limiting
									your indemnification obligations with respect to that matter),
									and in that case, you agree to cooperate with our defense of
									those claims.
								</span>
							</p>
						</li>
						<li>
							<p>
								<strong>
									<strong>Disclaimers; No Warranties</strong>
								</strong>
							</p>
							<p>
								<span className={styles.span}>
									THE SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH
									THE SERVICE ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE”
									BASIS. APERTUREDATA DISCLAIMS ALL WARRANTIES OF ANY KIND,
									WHETHER EXPRESS OR IMPLIED, RELATING TO THE SERVICE AND ALL
									MATERIALS AND CONTENT AVAILABLE THROUGH THE SERVICE,
									INCLUDING: (1) ANY IMPLIED WARRANTY OF MERCHANTABILITY,
									FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, OR
									NON-INFRINGEMENT; AND (2) ANY WARRANTY ARISING OUT OF COURSE
									OF DEALING, USAGE, OR TRADE. APERTUREDATA DOES NOT WARRANT
									THAT THE SERVICE OR ANY PORTION OF THE SERVICE, OR ANY
									MATERIALS OR CONTENT OFFERED THROUGH THE SERVICE, WILL BE
									UNINTERRUPTED, SECURE, OR FREE OF ERRORS, VIRUSES, OR OTHER
									HARMFUL COMPONENTS, AND APERTUREDATA DOES NOT WARRANT THAT ANY
									OF THOSE ISSUES WILL BE CORRECTED.
								</span>
							</p>
							<p>
								<span className={styles.span}>
									NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY
									YOU FROM THE SERVICE OR APERTUREDATA ENTITIES OR ANY MATERIALS
									OR CONTENT AVAILABLE THROUGH THE SERVICE WILL CREATE ANY
									WARRANTY REGARDING ANY OF THE APERTUREDATA ENTITIES OR THE
									SERVICE THAT IS NOT EXPRESSLY STATED IN THESE TERMS. WE ARE
									NOT RESPONSIBLE FOR ANY DAMAGE THAT MAY RESULT FROM THE
									SERVICE AND YOUR DEALING WITH ANY OTHER SERVICE USER. YOU
									UNDERSTAND AND AGREE THAT YOU USE ANY PORTION OF THE SERVICE
									AT YOUR OWN DISCRETION AND RISK, AND THAT WE ARE NOT
									RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR
									COMPUTER SYSTEM OR MOBILE DEVICE USED IN CONNECTION WITH THE
									SERVICE) OR ANY LOSS OF DATA, INCLUDING USER CONTENT.
								</span>
								<span className={styles.span}>
									<br />
								</span>
								<span className={styles.span}>
									<br />
								</span>
								<span className={styles.span}>
									IF YOU ARE USING THE SOFTWARE UNDER A TRIAL LICENSE, YOU
									UNDERSTAND AND AGREE THAT THE TRIAL IS NOT A PRODUCTION
									ENVIRONMENT, AND THAT ANY CONTENT OR DATA THAT YOU HAVE
									UPLOADED OR USED WITH THE SOFTWARE WILL BE AUTOMATICALLY
									DELETED AT THE END OF THE TRIAL. THE SOFTWARE IS NOT A HOSTING
									SERVER OR OTHER SIMILAR SERVICE AND DOES NOT SAVE ANY CONTENT
									OR DATA. YOU UNDERSTAND AND AGREE THAT YOU ARE SOLELY
									RESPONSIBLE FOR BACKING UP YOUR CONTENT AND DATA AND THAT WE
									ARE NOT RESPONSIBLE FOR ANY LOSS OF CONTENT OR DATA.
								</span>
							</p>
							<p>
								<span className={styles.span}>
									THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS IN THIS
									SECTION&nbsp;APPLY TO THE FULLEST EXTENT PERMITTED BY LAW.
									APERTUREDATA DOES NOT DISCLAIM ANY WARRANTY OR OTHER RIGHT
									THAT APERTUREDATA IS PROHIBITED FROM DISCLAIMING UNDER
									APPLICABLE LAW.
								</span>
							</p>
						</li>
						<li>
							<p>
								<strong>Limitation of Liability</strong>
							</p>
							<p>
								<span className={styles.span}>
									TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE
									APERTUREDATA ENTITIES BE LIABLE TO YOU FOR ANY INDIRECT,
									INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES
									(INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, OR ANY OTHER
									INTANGIBLE LOSS) ARISING OUT OF OR RELATING TO YOUR ACCESS TO
									OR USE OF, OR YOUR INABILITY TO ACCESS OR USE, THE SERVICE OR
									ANY MATERIALS OR CONTENT ON THE SERVICE, WHETHER BASED ON
									WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE, OR
									ANY OTHER LEGAL THEORY, AND WHETHER OR NOT ANY APERTUREDATA
									ENTITY HAS BEEN INFORMED OF THE POSSIBILITY OF DAMAGE.
								</span>
							</p>
							<p>
								<span className={styles.span}>
									TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE
									APERTUREDATA ENTITIES BE LIABLE TO YOU FOR ANY LOSS OF YOUR
									CONTENT OR DATA.
								</span>
							</p>
							<p>
								<span className={styles.span}>
									TO THE FULLEST EXTENT PERMITTED BY LAW, THE AGGREGATE
									LIABILITY OF THE APERTUREDATA ENTITIES TO YOU FOR ALL CLAIMS
									ARISING OUT OF OR RELATING TO THE USE OF OR ANY INABILITY TO
									USE ANY PORTION OF THE SERVICE OR OTHERWISE UNDER THESE TERMS,
									WHETHER IN CONTRACT, TORT, OR OTHERWISE, IS LIMITED TO THE
									AMOUNT YOU HAVE PAID TO APERTUREDATA FOR ACCESS TO AND USE OF
									THE SERVICE IN THE 12 MONTHS PRIOR TO THE EVENT OR
									CIRCUMSTANCE GIVING RISE TO CLAIM.
								</span>
							</p>
							<p>
								<span className={styles.span}>
									EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A LIMITATION
									OF LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF
									DAMAGES IS INTENDED TO AND DOES ALLOCATE THE RISKS BETWEEN THE
									PARTIES UNDER THESE TERMS. THIS ALLOCATION IS AN ESSENTIAL
									ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES. EACH
									OF THESE PROVISIONS IS SEVERABLE AND INDEPENDENT OF ALL OTHER
									PROVISIONS OF THESE TERMS. THE LIMITATIONS IN THIS
									SECTION&nbsp;13 WILL APPLY EVEN IF ANY LIMITED REMEDY FAILS OF
									ITS ESSENTIAL PURPOSE.
								</span>
							</p>
						</li>
						<li>
							<p>
								<strong>
									<strong>Dispute Resolution and Arbitration</strong>
									<span className={styles.span}>
										. Except for the right of either party to apply to a court
										of competent jurisdiction for a temporary restraining order,
										a preliminary injunction, or other equitable relief to
										preserve the status quo or prevent irreparable harm, any
										dispute as to the interpretation, enforcement, breach, or
										termination of this Agreement will be settled by individual
										and confidential binding arbitration in Santa Clara
										California, U.S.A. under the Rules of the American
										Arbitration Association by one arbitrator appointed in
										accordance with such rules.&nbsp; All other disputes
										(excluding the right of either party to apply to a court of
										competent jurisdiction for a temporary restraining order, a
										preliminary injunction, or other equitable relief to
										preserve the status quo or prevent irreparable harm) will be
										resolved by a court specified in Section 15.2.&nbsp;
										Judgment upon the award rendered by the arbitrators may be
										entered in any court of competent jurisdiction.&nbsp; If
										ApertureData is the prevailing party, it will be entitled to
										receive from the you its attorneys’ fees and costs incurred
										in connection with any arbitration or litigation instituted
										by you in connection with this Agreement.
									</span>
								</strong>
							</p>
						</li>
						<li>
							<p>
								<strong>Miscellaneous</strong>
							</p>
							<ol>
								<li>
									<p>
										<strong>General Terms</strong>
										<span className={styles.span}>
											. These Terms, together with the Privacy Policy and any
											other agreements expressly incorporated by reference into
											these Terms, are the entire and exclusive understanding
											and agreement between you and ApertureData regarding your
											use of the Service. You may not assign or transfer these
											Terms or your rights under these Terms, in whole or in
											part, by operation of law or otherwise, without our prior
											written consent. We may assign these Terms at any time
											without notice or consent. The failure to require
											performance of any provision will not affect our right to
											require performance at any other time after that, nor will
											a waiver by us of any breach or default of these Terms, or
											any provision of these Terms, be a waiver of any
											subsequent breach or default or a waiver of the provision
											itself. Use of Section&nbsp;headers in these Terms is for
											convenience only and will not have any impact on the
											interpretation of any provision. Throughout these Terms
											the use of the word “including” means “including but not
											limited to.” If any part of these Terms is held to be
											invalid or unenforceable, the unenforceable part will be
											given effect to the greatest extent possible, and the
											remaining parts will remain in full force and effect.
										</span>
									</p>
								</li>
								<li>
									<p>
										<strong>Governing Law</strong>
										<span className={styles.span}>
											. These Terms are governed by the laws of the State of
											California without regard to conflict of law principles.
											You and ApertureData submit to the personal and exclusive
											jurisdiction of the state courts and federal courts
											located within Santa Clara County, California for
											resolution of any lawsuit or court proceeding permitted
											under these Terms. We operate the Service from our offices
											in California, and we make no representation that
											Materials included in the Service are appropriate or
											available for use in other locations.
										</span>
									</p>
								</li>
								<li>
									<p>
										<strong>Privacy Policy</strong>
										<span className={styles.span}>
											. Please read the ApertureData{' '}
											<a href='privacy-policy'>Privacy Policy</a> carefully for
											information relating to our collection, use, storage, and
											disclosure of your personal information. The ApertureData
											Privacy Policy is incorporated by this reference into, and
											made a part of, these Terms.
										</span>
									</p>
								</li>
								<li>
									<p>
										<strong>Additional Terms</strong>
										<span className={styles.span}>
											. Your use of the Service is subject to all additional
											terms, policies, rules, or guidelines applicable to the
											Service or certain features of the Service that we may
											post on or link to from the Service (the “
										</span>
										<strong>Additional Terms</strong>
										<span className={styles.span}>
											”). All Additional Terms (including any Enterprise
											Agreement) are incorporated by this reference into, and
											made a part of, these Terms.
										</span>
									</p>
								</li>
								<li>
									<p>
										<strong>
											<strong>Consent to Electronic Communications</strong>
											<span className={styles.span}>
												. By using the Service, you consent to receiving certain
												electronic communications from us as further described
												in our Privacy Policy. Please read our Privacy Policy to
												learn more about our electronic communications
												practices. You agree that any notices, agreements,
												disclosures, or other communications that we send to you
												electronically will satisfy any legal communication
												requirements, including that those communications be in
												writing.
											</span>
										</strong>
									</p>
								</li>
								<li>
									<strong>Contact Information</strong>
									<span className={styles.span}>
										. The Service is offered by ApertureData Inc., located at
										1484 Pollard Rd &nbsp;#3005 Los Gatos, CA 95032. You may
										contact us by sending correspondence to that address or by
										emailing us at team@aperturedata.io.
									</span>
								</li>
								<li>
									<p>
										<strong>Notice to California Residents</strong>
										<span className={styles.span}>
											. If you are a California resident, under California Civil
											Code Section&nbsp;1789.3, you may contact the Complaint
											Assistance Unit of the Division of Consumer Services of
											the California Department of Consumer Affairs in writing
											at 1625 N. Market Blvd., Suite S-202, Sacramento,
											California 95834, or by telephone at (800) 952-5210 in
											order to resolve a complaint regarding the Service or to
											receive further information regarding use of the Service.
										</span>
									</p>
								</li>
								<li>
									<p>
										<strong>No Support</strong>
										<span className={styles.span}>
											. We are under no obligation to provide support for the
											Service. In instances where we may offer support, the
											support will be subject to published policies.
										</span>
									</p>
								</li>
								<li>
									<p>
										<strong>International Use</strong>
										<span className={styles.span}>
											. The Service is intended for visitors located within the
											United States. We make no representation that the Service
											is appropriate or available for use outside of the United
											States. Access to the Service from countries or
											territories or by individuals where such access is illegal
											is prohibited.
										</span>{' '}
										<span className={styles.span}>
											<br />
											<br />
										</span>
									</p>
								</li>
							</ol>
						</li>
					</ol>
				</ol>
			</div>
			<ContactUs />
		</div>
	);
};

export default TermsOfService;
