import styles from './CaseList.module.scss';
import CaseRow from './Case/CaseRow';
import { CaseType } from '../UseCases';
import React from 'react';

interface CaseListProps {
	cases: CaseType[];
}

const CaseList: React.FC<CaseListProps> = ({ cases }) => {
	return (
		<div className={styles['case-list']}>
			<div className={styles['section-container']}>
				{cases.map(
					(specificCase: CaseType, index: number): JSX.Element => (
						<CaseRow
							case={specificCase}
							key={specificCase.title}
							imageAtLeft={index % 2 !== 0}
						/>
					)
				)}
			</div>
		</div>
	);
};

export default CaseList;
