import NSFImage from '../images/Company/NSF.png';
import { UserType } from '../../pages/Landing/sections/Users';
import VBImage from '../images/Company/cmu.svg';
import LABImage from '../images/Company/lab.png';
import zippinImage from '../images/Company/zippin.png';

const investors: UserType[] = [
	{
		id: 1,
		phrase: 'It’s when you start training models over 100s of 1000s of labeled videos that you quickly see the value of a database like ApertureDB. They took us most of the way there , 2X faster and with half the resources. The Aperture team not only helps fix current problems but anticipates and solves future ones too!',
		author: 'Hareesh Kolluru',
		stand: 'Director of AI/ML at Zippin',
		image: zippinImage,
	},
	{
		id: 2,
		phrase: 'ApertureDB has a unique set of capabilities that we have not found in other databases. The ApertureData team has been very helpful and responsive and we look forward to continue working closely with them',
		stand: 'Head of Software Engineering, Stealth Biotech AI Company',
		image: LABImage,
	},
	{
		id: 3,
		phrase: 'NSF is proud to support the technology of the future by thinking beyond incremental developments and funding the most creative, impactful ideas across all markets and areas of science and engineering',
		author: 'Andrea Belz',
		stand: 'Division Director of the Division of Industrial Innovation and Partnerships at NSF',
		image: NSFImage,
	},
	{
		id: 4,
		phrase: 'Image-based data has seen unprecedented growth in recent years. The storing & searching of these visual datasets present new challenges. We are excited by this team’s focus and experience in this space.',
		author: 'Namrata M Banerjee',
		stand: 'Director of VentureBridge Accelerator at CMU Swartz Center',
		image: VBImage,
	},
];

export default investors;
