import styles from './ResourceCard.module.scss';
import React from 'react';
import { ResourceType } from '../Resources';
import {
	Link,
	Card,
	CardMedia,
	CardContent,
	Grid,
	Typography
} from '@material-ui/core';

interface ResourceCardProps {
	resource: ResourceType;
}

const ResourceCard: React.FC<ResourceCardProps> = (props) => {
	return (
		<Grid item>
			<Card className={styles.resource} elevation={2}>
				<CardContent className={styles['container-type']}>
					<Typography
						gutterBottom
						variant='h6'
						component='h2'
						className={styles.type}
					>
						{props.resource.type}
					</Typography>
				</CardContent>
				<CardMedia
					alt={props.resource.title}
					className={styles.media}
					component='img'
					image={props.resource.image}
					title={props.resource.title}
				/>
				<CardContent className={styles['container-text']}>
					<Typography
						gutterBottom
						className={styles.title}
					>
						{props.resource.title}
					</Typography>
					<Typography
						className={styles.description}
					>
						{props.resource.description}
					</Typography>
				</CardContent>
				<Link
					href={props.resource.link}
					className={styles.button}
					target='_blank'
					rel='noopener noreferrer'
				>
					{props.resource?.readMoreText ?? 'READ MORE'}
				</Link>
			</Card>
		</Grid>
	);
};

export default ResourceCard;
