import styles from "./Why.module.scss";
import React from "react";
import Graph from "../../../assets/images/Why/Graph.svg";

const Why: React.FC = () => {
	return (
		<div className={styles.why}>
			<div
				className={`${styles["section-container-extension"]}`}
			>
				<div className={styles.graphContainer}>
					<div className={styles.firstTitle}>
						Traditional databases were not designed for multimodal data
					</div>
					<div className={styles.subTitle}>
						Data science or ML-based analytics require more than just
						read and write capabilities
					</div>
					<div className={styles.imgContainer}>
						<img className={styles.image} src={Graph} alt="Graph" />
					</div>
				</div>
				<div className={styles.reasons}>
					<div id="divUnifiedAccess" className={styles.reason}>
						<div className={styles.title}>
							<span>Size and Volume</span>
						</div>
						<div className={styles.body}>
							<span>
								Most current environments are ill-equipped to
								handle the size, volume, and complexity required
								to analyze multimodal data like images and videos.
								It can be individually large, with datasets growing
								exponentially. This requires a database to
								natively recognize these complex types and
								seamlessly scale.
							</span>
						</div>
					</div>
					<div id="divMLReady" className={styles.reason}>
						<div className={styles.title}>
							<span>Rich Metadata Types</span>
						</div>
						<div className={styles.body}>
							<span>
								Data science teams require the ability to store
								and search application metadata, annotations,
								and embeddings. Without a purpose-built database
								they are left to use disparate software tools,
								traditional databases, and significant manual
								effort to integrate and manage their
								environment.
							</span>
						</div>
					</div>
					<div id="divRobust" className={styles.reason}>
						<div className={styles.title}>
							<span>Usage Patterns</span>
						</div>
						<div className={styles.body}>
							<span>
								The right API and tools need to address usage
								patterns such as iteratively evolving metadata,
								accessing batches of data in parallel,
								maintaining versions of datasets, tracking
								performance of models on various datasets,
								high-dimensional similarity search, and labeled
								dataset management.
							</span>
						</div>
					</div>
				</div>
				</div>
		</div>
	);
};

export default Why;
