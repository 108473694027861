import styles from './Cases.module.scss';
import CaseCard from './Case/CaseCard';
import { CaseType } from '../UseCases';
import React from 'react';

interface CasesProps {
	cases: CaseType[];
}

const Cases: React.FC<CasesProps> = ({ cases }) => {
	return (
		<div className={styles.cases}>
			<div className={styles['section-container']}>
				{cases.map(
					(specificCase: CaseType): JSX.Element => (
						<CaseCard case={specificCase} key={specificCase.title} />
					)
				)}
			</div>
		</div>
	);
};

export default Cases;
