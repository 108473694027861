import styles from "./JobForm.module.scss";
import ActionButton from "../../../components/Buttons/ActionButton";
import Button from "@material-ui/core/Button";
import MailRepository from "../../../api/mail";
import MailService from "../../../services/mail";
import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";

const style = {
	attach: {
		backgroundColor: "#4648c6",
		color: "#fff",
		borderRadius: 8,
		width: 200,
		height: 48,
		fontSize: 18,
		letterSpacing: 0.2,
		textTransform: "none",
		fontWeight: "bold",
		marginRight: 10,
		marginTop: 10,
		marginBottom: 10,
	},
	submit: {
		backgroundColor: "#4648c6",
		color: "#fff",
		borderRadius: 8,
		width: 200,
		height: 48,
		fontSize: 18,
		letterSpacing: 0.2,
		textTransform: "none",
		fontWeight: "bold",
		marginRight: 10,
	},
};

interface JobFormProps {
	jobTitle: string;
}

const JobForm: React.FC<JobFormProps> = ({ jobTitle }) => {
	const [firstName, setFirstName] = React.useState("");
	const [lastName, setLastName] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [phoneNumber, setPhoneNumber] = React.useState("");
	const [linkedIn, setLinkedIn] = React.useState("");
	const [comments, setComments] = React.useState("");
	const [fileInputName, setFileInputName] = React.useState("");
	const [file, setFile] = React.useState<File | null>(null);
	const fileInput = React.useRef<HTMLInputElement | null>(null);
	const [snackbarMessage, setSnackbarMessage] = React.useState("");
	const [openSnackbar, setOpenSnackbar] = React.useState(false);

	const onChangeFileInput = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		if (event.target.files && event.target.files[0]) {
			setFileInputName(event.target.files[0].name);
			setFile(event.target.files[0]);
		}
	};

	const showSnackbarMessage = (): void => {
		setOpenSnackbar(true);
		setTimeout(() => {
			setOpenSnackbar(false);
		}, 3000);
	};

	const checkAndSubmit = (): void => {
		if (!email || !MailService.isValidEmail(email)) {
			setSnackbarMessage(
				"The email is not valid. Please review the data and try again."
			);
			showSnackbarMessage();
		} else if (!firstName || !lastName || !fileInputName) {
			setSnackbarMessage(
				"There is some information missing. Please review it and try again."
			);
			showSnackbarMessage();
		} else {
			MailRepository.jobPositionMail(
				firstName,
				lastName,
				email,
				phoneNumber,
				comments,
				jobTitle,
				linkedIn,
				file
			).then((result: boolean) => {
				if (result) {
					setSnackbarMessage("Email sent!");
				} else {
					setSnackbarMessage(
						"There was an error while trying to send email. Please review the data and try again."
					);
				}

				showSnackbarMessage();
			});
		}
	};

	return (
		<div className={styles["contact-section"]}>
			<span className={styles["contact-section-title"]}>Apply</span>
			<div className={styles["contact-row"]}>
				<TextField
					label="First Name"
					variant="outlined"
					required={true}
					className={styles["contact-field"]}
					value={firstName}
					onChange={(
						event: React.ChangeEvent<HTMLInputElement>
					): void => setFirstName(event.target.value)}
				/>
				<TextField
					label="Last Name"
					variant="outlined"
					required={true}
					className={styles["contact-field"]}
					value={lastName}
					onChange={(
						event: React.ChangeEvent<HTMLInputElement>
					): void => setLastName(event.target.value)}
				/>
			</div>
			<div className={styles["contact-row"]}>
				<TextField
					label="Email"
					variant="outlined"
					required={true}
					className={styles["contact-field"]}
					type="mail"
					value={email}
					onChange={(
						event: React.ChangeEvent<HTMLInputElement>
					): void => setEmail(event.target.value)}
				/>
				<TextField
					label="Phone Number"
					variant="outlined"
					required={false}
					className={styles["contact-field"]}
					value={phoneNumber}
					onChange={(
						event: React.ChangeEvent<HTMLInputElement>
					): void => setPhoneNumber(event.target.value)}
				/>
			</div>
			<div className={styles["contact-row"]}>
				<TextField
					label="LinkedIn"
					variant="outlined"
					required={false}
					className={styles["contact-field"]}
					value={linkedIn}
					onChange={(
						event: React.ChangeEvent<HTMLInputElement>
					): void => setLinkedIn(event.target.value)}
				/>
			</div>
			<div className={styles["contact-row"]}>
				<input
					accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
					className={styles["selected-files"]}
					id="contained-button-file"
					multiple
					type="file"
					ref={fileInput}
					onChange={onChangeFileInput}
				/>
				<span className={styles["file-name"]}>{fileInputName}</span>
				<label htmlFor="contained-button-file">
					<Button
						variant="contained"
						component="span"
						style={style.attach as object}
					>
						Attach CV
					</Button>
				</label>
			</div>
			<div className={styles["contact-row"]}>
				<TextField
					label="Comments"
					variant="outlined"
					className={styles["contact-field"]}
					multiline={true}
					rows={3}
					value={comments}
					onChange={(
						event: React.ChangeEvent<HTMLInputElement>
					): void => setComments(event.target.value)}
				/>
			</div>
			<div className={styles["contact-row"]}>
				<ActionButton
					name="Submit"
					onClick={checkAndSubmit}
					style={style.submit}
					isDisabled={
						!(firstName && lastName && email && fileInputName)
					}
				/>
			</div>
			<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				open={openSnackbar}
				autoHideDuration={6000}
				message={snackbarMessage}
			/>
		</div>
	);
};

export default JobForm;
