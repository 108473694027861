import { FilterOptionType } from "../../components/FilterOptions/FilterOptionInterface";

const ResorcesOptions: FilterOptionType[] = [
	{
		type: "Product",
	},
	{
		type: "Videos",
	},
	{
		type: "Events",
	},
	{
		type: "Industry Experts",
	},
	{
		type: "Blogs",
	},
	{
		type: "Applied",
	},
];

export default ResorcesOptions;
