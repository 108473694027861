import styles from './Intro.module.scss';
import React from 'react';

const Intro: React.FC = () => {
	return (
		<div className={styles['intro-contact-us']}>
			<div className={styles['section-container']}>
				<h1>Contact Us</h1>
			</div>
		</div>
	);
};

export default Intro;
