import { JobsSectionType } from '../../pages/JoinUs/JoinUs';

const jobsSections: JobsSectionType[] = [
	{
		id: 1,
		title: 'Engineering',
		jobs: [
			{
				id: 1,
				title: 'Sr. Software Engineer',
				contractType: 'Full Time',
				location: 'US-based',
				description:
					'At ApertureData, we are on a mission to solve data infrastructure challenges for machine learning on big-visual-data. We are an angel and NSF grant backed, fast growing startup looking for a Sr. Software Engineer with experience building large scale infrastructure and developing low level systems software. If you enjoy the idiosyncrasies of C++, big data systems excite you, and being among the first five hires fires up your imagination on what all hats you get to wear, we are looking forward to hearing from you!',
				minimumQualificationsIntro:
					'While you get the freedom to define direction and build castles on-ground for now, it comes with some requirements so we can build fast and stay focused.',
				minimumQualifications: [
					'5+ years of experience in Computer Science, or a related technical field',
					'2+ years of experience in C++',
					'Understand concurrency well',
					'Understand the effects of cache/memory/disk as they interplay with each other and processing',
					'Systems level data structure and algorithm effects (kernel and driver level included)',
					'Be comfortable with Linux, C++, and Python',
					'Valid work status in the US',
				],
				additionalQualificationsIntro:
					'Good tools make engineering more fun and the more you know about scaling, the faster you can help us scale our product',
				additionalQualifications: [
					'Productivity, development, testing, and cluster management tools/frameworks/languages such as Gtest, git, Jupyter, shell scripting, OpenCV (to know how to handle some computer vision tasks), Kafka, Spark, Tensorflow/PyTorch/Caffe2, Docker, Kubernetes, Zookeeper, and just in general keep up with new technology to know when we should pay attention to something',
					'Practical implementation knowledge of CAP theorem, distributed systems programming',
					'Experience architecting a system to run as a service independent of the cloud vendor',
					'Knowledge of how to interact with distributed file systems',
				],
				ourPromiseIntro:
					'We realize that hiring and retaining talent requires nurturing. Our promise to you:',
				ourPromise: [
					'Regular supply of technical challenges: We have a large system to build to accelerate an extremely dynamic and massive market. This will be the easiest promise to keep!',
					'Opportunities to grow, technically and personally',
					'Healthcare benefits',
					'An environment where you can speak your mind and be how you like to be',
				],
			},
			{
				id: 2,
				title: 'Database Architect',
				contractType: 'Full Time',
				location: 'US-based',
				description:
					'At ApertureData, we are on a mission to solve data infrastructure challenges for machine learning through our unique multimodal AI database, ApertureDB. We are an angel and NSF grant backed, fast growing startup looking for a Database Architect with experience designing and building database internals. If you think locking and logging have a lot more to them than MVCC or redo, connected graph-like queries excite you, and being among the first five hires fires up your imagination on what all hats you get to wear, we are looking forward to hearing from you!',
				minimumQualificationsIntro:
					'Databases serve a vital purpose particularly as we get to define them for unstructured data. That requires a certain set of skills to even start off.',
				minimumQualifications: [
					'5+ years of experience in Computer Science, or a related technical field',
					'2+ years of experience in C++',
					'Understand concurrency and ACID implementations well',
					'Systems level data structure and algorithm effects (kernel and driver level included)',
					'Data structure and query optimization techniques',
					'Different consistency models',
					'Valid work status in the US',
				],
				additionalQualificationsIntro:
					'It would be great if you already came to us with a few more tricks up your sleeve.',
				additionalQualifications: [
					'Be comfortable with JSON, Python, Git, and Linux',
					'Object mapper interfaces',
					'Indexing vector data types',
					'Understand the effects of cache/memory/disk as they interplay with each other and processing',
					'Experience architecting a system to run as a service independent of the cloud vendor',
				],
				ourPromiseIntro:
					'We realize that hiring and retaining talent requires nurturing. Our promise to you:',
				ourPromise: [
					'Regular supply of technical challenges: We have a large system to build to accelerate an extremely dynamic and massive market. This will be the easiest promise to keep!',
					'Opportunities to grow, technically and personally',
					'Healthcare benefits',
					'An environment where you can speak your mind and be how you like to be',
				],
			},
			{
				id: 3,
				title: 'Computer Vision Systems Engineer',
				contractType: 'Full Time',
				location: 'US-based',
				description:
					'At ApertureData, we are on a mission to solve data infrastructure challenges for machine learning through our unique multimodal AI database, ApertureDB. We are an angel and NSF grant backed, fast growing startup looking for a Computer Vision Systems Engineer because dealing with images and videos is challenging, particularly at scale. If you can easily tackle the likes of OpenCV and ffmpeg, dealing with large object caching excites you, and being among the first five hires fires up your imagination on what all hats you get to wear, we are looking forward to hearing from you!',
				minimumQualificationsIntro:
					'We are focused on making access to multimodal data simple and fast. That requires absorbing the comlexity of these data types within ApertureDB, therefore requiring certain qualifications',
				minimumQualifications: [
					'5+ years of experience in Computer Science, or a related technical field',
					'2+ years of experience in C++',
					'Understand different image and video formats and encodings',
					'Experience working with OpenCV and FFMPEG libraries',
					'Systems level data structure and algorithm effects (kernel and driver level included)',
					'Data structure optimization techniques',
					'Valid work status in the US',
				],
				additionalQualificationsIntro:
					'It would be great if you already came to us with a few more tricks up your sleeve.',
				additionalQualifications: [
					'Be comfortable with JSON, Python, Git, and Linux',
					'Other visual processing libraries',
					'Understand the effects of cache/memory/disk as they interplay with each other and processing',
					'Efficient algorithms for visual transformations',
				],
				ourPromiseIntro:
					'We realize that hiring and retaining talent requires nurturing. Our promise to you:',
				ourPromise: [
					'Regular supply of technical challenges: We have a large system to build to accelerate an extremely dynamic and massive market. This will be the easiest promise to keep!',
					'Opportunities to grow, technically and personally',
					'Healthcare benefits',
					'An environment where you can speak your mind and be how you like to be',
				],
			},
		],
	},
	{
		id: 2,
		title: 'Marketing',
		jobs: [
			{
				id: 1,
				title: 'Product Marketing',
				contractType: 'Full Time',
				location: 'US-based',
				description: 'At ApertureData, we are on a mission to make it easy to derive valuable business insights from complex multimodal data using machine learning and related data science techniques. We are a VC backed, fast growing startup looking for a Product Marketing Manager to market our database software not only to data scientists, computer vision engineers, and ML engineers, but also to the economic buyers. If solving data science / computer vision / big data excites you, and being the very first marketing hire fires up your imagination, we are looking forward to hearing from you!',
				minimumQualificationsIntro: 'We are looking for a candidate to set and execute the corporate marketing strategy and create compelling content that results in a growing, measurable sales funnel and share of voice in the market. We’re looking for a passionate, driven individual to take on this crucial role at ApertureData and act as the primary expert in this area. You will build the Product Marketing function by having both strategic and hands-on responsibilities towards ApertureDB, with the aim of defining and executing on customer acquisition and revenue generation strategies. You will help us lay down the foundation and execute on how to market a novel database to data scientists and ML engineers that work with image and video data, serving as a liaison between our customers and engineering teams. Therefore, we are looking for:',
				minimumQualifications: [
					'Content creation: To reach our target data science audience, you will need to create content at all layers of the sales funnel: Top-of-the-funnel (TOFU), middle (MOFU) and bottom (BOFU), whether that’s industry trends in visual analysis, customer success stories, machine learning architectures with block diagrams, or sample applications with code. Some will be on our corporate website, but others may be hosted on industry websites. We’d love to hear your ideas for content strategy and getting our message to a broader audience.',
                    'Email marketing: Email marketing is a powerful marketing channel, a form of direct marketing as well as digital marketing, that uses email to promote your business’s products or services. You will help our sales team by creating targeted compaigns to help prospect, and implement marketing automation efforts to scale this outreach. You will help build this key part of our marketing strategy for lead generation, brand awareness, building relationships or keeping customers engaged between purchases through different types of marketing emails',
                    'Technical understanding: you have a taste for technically-oriented products and the ability to dive deep and grow a good understanding of operator problems. Developer-level skills are not required, but candidates must show acumen and ability to learn about our highly technical domain. You have a great rapport with engineers and can take their ideas to edited blogs and user-ready demos.',
                    'Data-driven: you can work and reason about data using various analytics tools to make sense of numbers and translate those into actionable insights. You will devise a strategy to measure marketing success on a month-by-month and year-over-year basis.',
                    'Exceptional communication skills: you get people excited around a common goal, and can effectively lead a cross-functional group of people to reach alignment. You are comfortable with producing content in a variety of formats both written (blogs, web pages) and audio/video (podcasts, webinars, videos).',
                    'Experience in crafting messages directed at individual practitioners, leaders, and enterprises: you love nailing the right story, adapted to your target audience.',
                    'Positioning: You will need to establish ApertureData against competitive alternatives, and show the benefits of adopting our product versus older methods of addressing the issues our system solves.',
                    'Valid work status in the US.',
				],
				ourPromiseIntro: 'We realize that hiring and retaining talent requires nurturing. Our promise to you:',
				ourPromise: [
                    'Regular supply of marketing challenges: We have a product that can redefine the way people have been managing complex data types and an extremely dynamic and massive market. However, we need to distill all its goodness in very few data sciency words. This will be the easiest promise to keep!',
                    'Opportunities to grow, as a marketer and personally.',
                    'Healthcare and retirement benefits in addition to competitive startup salary and equity.',
                    'An environment where you can speak your mind and be how you like to be.',
				],
				additionalQualificationsIntro: 'While these are not necessary to start with, we would love it if you grow with us or already have:',
				additionalQualifications: [
					'Past experience with companies that have a computer vision, machine learning, or a database product and have to connect with data scientists and data engineers',
					'You have been successful as marketing hire #1/2 at a prior company',
					'Proven experience successfully selling and/or marketing SaaS or cloud based products',
					'Able to develop messaging to support direct sales, inbound marketing and product led growth techniques',
                    'Search engine marketing',
                    'Organic and paid social media marketing',
					'3+ years experience working in Product Marketing roles at a growing startup and an established software company focused on Enterprise/B2B SaaS',
					'Strong knowledge of enterprise sales as well as product led growth strategies and tactics',
					'Proven track-record of success in partnering with Sales for common goals',
					'Experience working with Hubspot',
					'Experience working with Product and Engineering teams to define product communication plans, develop marketing materials, and coordinate high-impact feature releases',
				],
			},
		],
	},
	{
		id: 3,
		title: 'Sales',
		jobs: [
			{
				id: 1,
				title: 'Sales Development Representative',
				contractType: 'Full Time',
				location: 'US-based',
				description: 'At ApertureData, we are on a mission to make it easy to derive valuable business insights from complex multimodal data using machine learning and related data science techniques. We are a VC backed, fast growing startup looking for SDRs. Our SDRs have an incredible opportunity to evangelize ApertureDB and deliver qualified opportunities to the sales team.  If helping grow ApertureData’s market share in an exciting and evolving space excites you, and being on the early sales team fires up your imagination, we are looking forward to hearing from you!',
				minimumQualificationsIntro: 'Our SDRs will have the unique opportunity to build a strong foundation of software sales skills while having a direct impact on pipeline and revenue generation. The SDR team is the first point of contact for many ApertureData customers, so the right candidate will be passionate about our mission to make the world more fact-driven. SDRs will work cross-functionally with ApertureData sales development and will align with our Go To Market strategy. Join us and help us achieve our growth and expansion goals! What you\'ll work on:',
				minimumQualifications: [
                    'Outbound Demand Generation',
					'Lead Qualification and detailed discovery',
                    'Targeted email Outreach',
                    'Qualify inbound leads and generate meetings for Sales Leaders and Management',
                    'Be responsible and expected to mine and generate your own leads with support of our Marketing function',
                    'Research Fortune companies to identify current and future Image and Video based Analytics trends/initiatives',
                    'Effectively communicate with individuals at all levels in the organization, discussing the value of ApertureDB with both technical and non-technical audiences',
                    'Work with both field sales and marketing to convert leads into qualified meetings',
                    'Design and execute creative sales/marketing campaigns',
                    'LinkedIn – social selling and lead generation activity',
                    'Utilize our CRM (hubspot) – manage, organize and update contacts database',
                    'Consistently work with deadlines and targets',
                    'Valid work status in the US',
				],
				ourPromiseIntro: 'We realize that hiring and retaining talent requires nurturing. Our promise to you:',
				ourPromise: [
                    'Growth opportunity: We have a product that will redefine the way people have been managing complex data types in an extremely dynamic and massive market. Given the exponential growth in multimodal data use cases, this will be the easiest promise to keep!',
                    'Opportunities to grow, in sales and personally',
                    'Healthcare and retirement benefits in addition to competitive startup compensation',
                    'An environment where you can speak your mind and be how you like to be.',
				],
				additionalQualificationsIntro: 'Candidates with these qualifications would be best suited for the role:',
				additionalQualifications: [
					'1 to 3 Years Sales Development Experience',
					'Articulate with strong business acumen',
					'Professional, creative and a dynamic presence',
					'Demonstrated high degree of success at university and/or enterprise inside sales',
					'The ability to work independently in an environment that changes rapidly (we\'re a startup)',
					'A positive attitude and a desire to build a world-class company, as well as willingness to work closely with other functions within ApertureDB',
					'Beneficial if experience with AI, Business Intelligence, Data Warehousing, or Analytics',
					'A strong focus on customer success',
					'Strong time management skills with the ability to handle multiple priorities',
					'Excellent written and verbal communication in both',
				],
			},
			{
				id: 2,
				title: 'Solution Architect',
				contractType: 'Full Time',
				location: 'SF Bay Area',
				description: 'At ApertureData, we are on a mission to make it easy to derive valuable business insights from complex multimodal data using machine learning and related data science techniques. We are a VC backed, fast growing startup looking for a Solution Architect to join our rapidly growing team that’s well positioned to build the future of image and video based AI & ML use cases.  If helping grow ApertureData’s market share in an exciting and evolving space excites you, and being on the early sales team fires up your imagination, we are looking forward to hearing from you!',
				minimumQualificationsIntro: 'You will be part of a small Go to Market team that is working with Sales, Product, Engineering, and Marketing to help organizations looking to build AI and Machine Learning solutions using Images and Videos. We are looking for people who share our values, challenge ordinary thinking, and push the pace of innovation while building a future for themselves and ApertureData. You will get to work through the deal flow pipeline from pre-sales to post-sales. What you’ll work on:',
				minimumQualifications: [
                    'Create momentum and drive adoption of ApertureDB through your interactions with data scientists and engineers building machine learning workflows and products',
					'Work hand-in-hand with our Sales, Marketing, and Product organizations to develop proof of concept applications and present technical demonstrations',
                    'Collect a library of technical assets, create user manuals, to help organizations scale quickly',
                    'Be an internal advocate for the needs of customers while working with cross functional teams including Product Management, Marketing, Support, Engineering, Program Management, and Partners',
                    'Own and drive relationships with external technical audiences of all kinds — from engineer to CTO — to understand business objectives and translate requirements into solutions',
                    'Influence the direction of our product by gathering insights from our end users',
                    'Support a sales lifecycle and drive positive outcomes in customer-facing conversations',
                    'Valid work status in the US.',
				],
				ourPromiseIntro: 'We realize that hiring and retaining talent requires nurturing. Our promise to you:',
				ourPromise: [
                    'Growth opportunity: We have a product that will redefine the way people have been managing complex data types in an extremely dynamic and massive market. Given the exponential growth in multimodal data use cases, this will be the easiest promise to keep!',
                    'Opportunities to grow, in sales engineering and personally',
                    'Healthcare and retirement benefits in addition to competitive startup compensation',
                    'An environment where you can speak your mind and be how you like to be.',
				],
				additionalQualificationsIntro: 'Candidates with these qualifications would be best suited for the role:',
				additionalQualifications: [
					'Have academic and/or professional experience working with a database and preferably an understanding of how AI and/or Machine Learning applications access / use data. Bonus if you have  built models particularly using computer vision data',
					'Be proficient in writing Python code and use of REST API. Experience with libraries and packages like PyTorch, Tensorflow, Sagemaker, Vertex AI, any labeling or data-centric AI framework will certainly be of great value',
					'Experience with some common machine learning problem spaces, such as, Recommendation Systems, Segmentation, Unstructured data analysis (e.g. image recognition) will help understand customer use cases better',
					'Experience with building on and using various cloud provider platforms (AWS, GCP, Azure)',
					'Experience with docker, git, Linux, shell scripting, network setup, firewall configuration',
					'Be a great communicator, comfortable explaining complex concepts to both technical and non-technical audiences',
					'Interested in solving open ended business problems with a combination of technology and creative thinking',
					'Experience working with Hubspot',
				],
			},
		],
	},
	{
		id: 4,
		title: 'Other',
		jobs: [
		],
	},
];

export default jobsSections;
