import styles from './BlogsList.module.scss';
import React from 'react';
import { Container, Divider, Grid } from '@material-ui/core';
import { BlogType } from '../Blog';
import BlogCard from './BlogCard';
import { UseViewPortWidth } from '../Hook/UseViewPortWidth';
import BlogBigCard from './BlogBigCard';

interface BlogsListProps {
	blogs: BlogType[];
	allBlogs: BlogType[],
	optionState: string;
}

const BlogsList: React.FC<BlogsListProps> = ({ blogs, optionState, allBlogs }) => {
	const viewPortWidth = UseViewPortWidth();

	return (
		<Container className={styles.list}>
			{viewPortWidth > 950 &&
			<div className={styles.dividerContainer}>
				{optionState.includes("Tag") ?
					<p><span> Tag: </span> {optionState.replace("Tag", "")}</p>
				:
					<p><span> Category: </span>{optionState}  </p>
				}
				<Divider light />
			</div>
			}
			<BlogBigCard blog={allBlogs[0]} />
			<Grid container spacing={1}>
				{blogs.length !== 0?
				blogs.map(
					(specificNew: BlogType, index: number): JSX.Element => (
						<Grid
							container
							item
							xs={6}
							md={4}
							lg={3}
							spacing={1}
							key={index}
							className={styles.grid}
						>
							<BlogCard blog={specificNew} key={specificNew.title} />
						</Grid>
					)
				): <h2 className={styles.emptyResult}> No items found.</h2>
				}
			</Grid>
		</Container>
	);
};

export default BlogsList;
