import styles from './Intro.module.scss';
import React from 'react';

const Intro: React.FC = () => {
	return (
		<div className={styles["intro-contact-us"]}>
			<div
				className={`${styles["section-container"]} ${styles["section-container-extension"]}`}
			>
				<h1>Request a Free Trial</h1>
				<h2>To simplify evaluation of ApertureDB and its capabilities easily, we have loaded some popular datasets into our database. Use the example notebooks and check out the power of ApertureDB in minutes, at NO COST, for a week! </h2>
				<div className={styles.linkQuestions}>
					<span>
						<a href="/contact-us">Questions? Contact Us</a>
					</span>
				</div>
			</div>
		</div>
	);
};

export default Intro;
