import styles from './Investors.module.scss';
import investors from '../../../assets/content/Investors';
import React from 'react';

export interface InvestorType {
	image: string;
}

const Investors: React.FC = () => {
	return (
		<div className={styles.investors}>
			<div
				className={`${styles['section-container']} ${styles['section-container-extension']}`}
			>
				<div className={styles['title-column']}>Investors</div>
				<div
					className={`${styles['content-column']} ${styles['content-column-extension']}`}
				>
					{investors.map((investor, index) => (
						<div className={styles['investors-img-container']} key={index}>
							<img
								alt={'investor' + index}
								src={investor.image}
								className={styles['investors-img']}
							/>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Investors;
