import React from "react";
import ContactUs from "../../Landing/sections/ContactUs";
import styles from "./SupportPolicy.module.scss";

const SupportPolicy: React.FC = () => {
	return (
		<>
			<div className={styles.supportPolicy}>
				<div className={styles["section-container"]}>
					<h1>Support Policy</h1>
				</div>
			</div>
			<div className={styles["title-container"]}>
				<p style={{ textAlign: "center" }}>
					<strong>ApertureData Support Policy</strong>
				</p>
				<p>
					<span className={styles.span}>
						ApertureData Inc. (“ApertureData”) provides Support of
						eligible software under the terms of this Support Policy
						as long as Customer maintains a current subscription to
						Support. “Support” means the services described in this
						Support Policy and does not include one-time services or
						other services not specified in this Support Policy,
						such as training, consulting or custom development. This
						Support Policy is part of and subject to an agreement
						with ApertureData (the “Agreement”) that includes the
						Enterprise Terms and the Order Schedule under which
						Customer purchased its Subscription to Support.
					</span>
				</p>
				<ol>
					<ol>
						<li style={{ fontWeight: 400 }}>
							<p>
								<strong>Supported Software</strong>
							</p>
							<span className={styles.span}>
								Support covers only the software delivered to
								Customer by ApertureData under the Agreement
								(the “Supported Software”). The Supported
								Software means the single instance of ApertureDB
								as delivered to Customer by ApertureData. An
								ApertureDB instance will be defined as follows:
								Physical or virtual machine(s) supporting one
								database of ApertureDB objects deployed either
								in ApertureData cloud or delivered to Customer
								as a set of Docker images.
							</span>
						</li>
						<li style={{ fontWeight: 400 }}>
							<p>
								<strong>Technical Contacts</strong>
							</p>
							<span className={styles.span}>
								Support may be initiated and managed only by
								Customer’s Technical Contacts. “Technical
								Contacts” are named individuals who are
								responsible for administration of the Supported
								Software within Customer’s organization.
							</span>
						</li>
						<li style={{ fontWeight: 400 }}>
							<p>
								<strong>Informational Support</strong>
							</p>
							<span className={styles.span}>
								ApertureData will provide reasonable product
								and technical support to address questions
								concerning use of the Supported Software.
								Technical Contacts may initiate Support by
								emailing to support@aperturedata.io. Support is
								provided in the English language only.
							</span>
						</li>
						<li style={{ fontWeight: 400 }}>
							<p>
								<strong>Technical Currency</strong>
							</p>
							<span className={styles.span}>
								As part of Support and at no additional
								license fee, ApertureData will provide Customer
								with Updates. As used herein,
							</span>
							<strong> “Updates” </strong>
							<span className={styles.span}>
								means all Software new releases, updates,
								upgrades, modifications, improvements, versions,
								replacements, fixes, and enhancements, and other
								changes to the Supported Software, that
								ApertureData generally makes available at no
								additional charge to its customers, or which are
								otherwise provided to Customer by ApertureData
								under the Agreement.
							</span>
						</li>
						<li style={{ fontWeight: 400 }}>
							<p>
								<strong>Issue Resolution</strong>
							</p>
							<span className={styles.span}>
								ApertureData will make commercially reasonable
								efforts to resolve any Issues submitted by
								Customer’s Technical Contacts. Such efforts may
								include helping with diagnosis, suggesting
								workarounds, providing patches, temporary
								builds, or contributing a change to the
								Supported Software for inclusion in a new
								release. An “Issue” is a material and verifiable
								failure of the Supported Software to conform to
								its Documentation. Support will not be provided
								for (1) use of the Supported Software in a
								manner inconsistent with the applicable
								Documentation, (2) modifications to the
								Supported Software not provided by or approved
								in writing by ApertureData, or (3) use of the
								Supported Software with products or software not
								provided or approved in writing by ApertureData.
							</span>
						</li>
						<li style={{ fontWeight: 400 }}>
							<p>
								<strong>Service Levels</strong>
							</p>
							<span className={styles.span}>
								When a Technical Contact submits an Issue,
								ApertureData will reasonably assess its priority
								according to the appropriate priority levels
								defined in Table 1. ApertureData will confirm
								the priority level with Customer and will
								resolve any disagreement regarding the priority
								as soon as is reasonably practical.
								<strong>
									{" "}
									Urgent and High priority levels are not
									available for non-production software.
								</strong>
							</span>

							<strong>
								Table 1: Support levels for Issues at Different
								Priority Levels
							</strong>
							<div className={styles.tableContainer}>
								<table>
									<tr>
										<th></th>
										<th>Standard Support</th>
										<th>Premium Support</th>
									</tr>
									<tr>
										<td>Logging</td>
										<td>Email / Slack</td>
										<td>Email / Slack / Phone</td>
									</tr>
									<tr>
										<td>Issues</td>
										<td>Unlimited</td>
										<td>Unlimited</td>
									</tr>
									<tr>
										<td>
											<strong>Priority</strong>
										</td>
										<td>
											<strong>Response</strong>
										</td>
										<td>
											<strong>Response</strong>
										</td>
									</tr>
									<tr>
										<td>Urgent (S1)</td>
										<td>1 business hour</td>
										<td>1 hour, 24x7x365</td>
									</tr>
									<tr>
										<td>High (S2)</td>
										<td>4 business hours</td>
										<td>4 hours, 24x7x365</td>
									</tr>
									<tr>
										<td>Normal (S3)</td>
										<td>Within 1 business day</td>
										<td>Within 1 business day</td>
									</tr>
									<tr>
										<td>Low (S4)</td>
										<td>Within 3 business days</td>
										<td>Within 3 business days</td>
									</tr>
								</table>
							</div>
							<span className={styles.span}>
								“Urgent” means a catastrophic problem in
								Customer’s production systems. Examples include
								a complete loss of service, production systems
								that are crashed, a production system that hangs
								indefinitely, or when Customer cannot continue
								essential operations.
							</span>
							<br></br>
							<br></br>
							<span className={styles.span}>
								“High” means a high-impact problem in Customer’s
								production systems. Customer’s essential
								operations are seriously disrupted, but a
								workaround exists which allows for continued
								essential operations.
							</span>
							<br></br>
							<br></br>
							<span className={styles.span}>
								“Normal” means a lower impact problem on a
								production or non-production system that
								involves a partial or limited loss of
								non-critical functionality, or some other
								problem involving no loss in functionality.
								Customer can continue essential operations.
								Normal problems also include all problems on
								non-production systems, such as test and
								development systems.
							</span>
							<br></br>
							<br></br>
							<span className={styles.span}>
								“Low” means a general usage question. It also
								includes recommendations for requests for new
								products or features, and requests for
								enhancements or modifications. There is no
								impact on the quality, performance, or
								functionality of the product in a production or
								non-production system.
							</span>
							<br></br>
							<br></br>
							<span className={styles.span}>
								“Business hours” are from Monday - Friday, 5am
								to 6pm PT
							</span>
							<br></br>
							<br></br>
							<span className={styles.span}>
								“Business day” is a day from Monday - Friday PT
								excluding federal holidays.
							</span>
							<br></br>
							<br></br>
							<h3>
								Support Details for Non Production Software
								Subscription (Basic Support)
							</h3>
							<span className={styles.span}>
								During the Subscription Period, for issues at
								Priority Levels S3 and S4 as outlined in the
								Table 1 above, ApertureData shall provide
								technical support to address any questions,
								concerns, fixes necessary to use Software as
								follows: (i) email support (at{" "}
								<a href="mailto:support@aperturedata.io">
									support@aperturedata.io
								</a>{" "}
								), emails will be answered according to their
								specific priority level; and (ii) slack support
								on Aperture slack channel
								(aperturedata.slack.com), where messages will be
								answered according to their priority level.
							</span>
							<br></br>
							<br></br>
							<h3>Support coverage statement</h3>
							<span className={styles.span}>
								As per support policy, if you are a licensed
								customer…
							</span>
							<br></br>
							<br></br>
							<span className={styles.span}>We support:</span>
							<ul>
								<li>
									Diagnosis and resolution of product defects
								</li>
								<li>
									Installation, configuration, and migration
									issues
								</li>
								<li>License issues</li>
								<li>How-to questions</li>
								<li>
									Delivery of updated documentation and
									software packages
								</li>
								<li>Installation/migration walkthroughs*</li>
							</ul>
							<br></br>
							<span className={styles.span}>
								* We support sharing best practices around
								installation/migration walkthroughs and pipeline
								and job design optimizations, and will try to
								support you. Where we feel that your question is
								too custom and would require more dedicated
								support, we may suggest alternative ways, which
								may incur additional costs.
							</span>
						</li>
						<li style={{ fontWeight: 400 }}>
							<p>
								<strong>Customer Responsibilities</strong>
							</p>
							<span className={styles.span}>
								Customer shall provide ApertureData with logs,
								sample data, process information, online access
								to the Supported Software, supporting analysis,
								and access to Customer’s Technical Contact as
								reasonably required to allow ApertureData to
								resolve reported Issues. Customer is responsible
								for the adequate duplication and documentation
								of all of its files and data for back-up
								purposes.
							</span>
						</li>
						<li style={{ fontWeight: 400 }}>
							<p>
								<strong>How to Escalate a Support Issue</strong>
							</p>
							<span className={styles.span}>
								ApertureData Support is committed to providing
								accurate and timely solutions to technical
								support needs. If Customer has a critical or
								serious technical issue on its production system
								or is not satisfied with the response or
								resolution provided by ApertureData Technical
								Support, Customer may use one of the following
								escalation paths to have its concerns addressed:
							</span>

							<ol type="a">
								<li style={{ fontWeight: 400 }}>
									<span className={styles.span}>
										For support issues where the business
										impact has changed or was not correctly
										stated initially, request to have the
										priority of the ticket increased
										according to the above service level
										definitions.
									</span>
								</li>
								<br></br>
								<li style={{ fontWeight: 400 }}>
									<span className={styles.span}>
										For an existing issue that has become
										critical in nature where Customer’s
										production system is down, inaccessible,
										or Customer is dissatisfied with the
										ApertureData response or resolution,
										follow the procedure below for
										management review.
									</span>
									<br></br>
									<br></br>
									<ol type="i">
										<li>
											Verify that the support email is
											up-to-date and all requested
											information and files have been
											provided and request an escalation.
										</li>
										<br></br>
										<li>
											Contact{" "}
											<a href="mailto:support@aperturedata.io">
												support@aperturedata.io
											</a>{" "}
											or call one of the escalation phone
											numbers found in the “Welcome to
											ApertureData” email. Customer’s
											Technical Contact will be asked for
											the Technical Contact’s name,
											company name, ticket number, contact
											number, and reason for the
											escalation.
										</li>
										<br></br>
										<li>
											Once an escalation has been
											received, the Support Manager will
											contact Customer’s Technical Contact
											to acknowledge the escalation and
											determine the mode of communication
											and frequency of updates. The
											Support Manager will work to ensure
											that the appropriate resources are
											available to identify a solution or
											workaround.
										</li>
									</ol>
								</li>
							</ol>
						</li>
						<li style={{ fontWeight: 400 }}>
							<p>
								<strong>End of Life Policy</strong>
							</p>
							<span className={styles.span}>
								Support covers (1) the current Major Release;and
								(2) the Major Release that was available
								immediately prior to the current Major Release
								only for security fixes; and (3) all Minor
								Releases under a supported Major Release. If
								none of the conditions in the preceding sentence
								are met, then the Supported Software has reached
								its End of Life (“EOL”).
							</span>
							<br></br>
							<br></br>
							<span className={styles.span}>
								“Major Release” means a new version of the
								Supported Software made generally available by
								ApertureData with substantial improvements,
								enhancements and bug fixes, represented by a
								change in the number to the left (such as a
								version change from 1.1.0 to 2.0.0).
							</span>
							<br></br>
							<br></br>
							<span className={styles.span}>
								“Minor Release” means a version change
								represented by a change in the number to the
								right of the last decimal point.
							</span>
							<br></br>
							<br></br>
							<span className={styles.span}>
								The Supported Software will reach its End of
								Service Life (“EOSL”) 6 months after the EOL.
								Until the EOSL (and provided Customer has a
								current Subscription to Support), if Customer
								has identified a defect in a Major Release that
								has reached EOL, ApertureData will evaluate
								fixing the defect in a release that has not
								reached EOL. After the EOSL, ApertureData will
								not support, in any way, a Major Release and/or
								any Minor Releases of that Major Release.
							</span>
							<br></br>
							<br></br>
							<span className={styles.span}>
								Support for other versions of the Supported
								Software is not provided under this Support
								Policy. Support only covers use of the Supported
								Software on the platform or operating system
								versions that are specified at{" "}
								<a href="www.ApertureData.co/product">
									www.ApertureData.co/product
								</a>{" "}
								. Support does not cover use of the Supported
								Software on platforms or operating systems that
								are no longer supported by the original vendor.
							</span>
							<br></br>
							<br></br>
						</li>
						<li style={{ fontWeight: 400 }}>
							<p>
								<strong>Continuous Support</strong>
							</p>
							<span className={styles.span}>
								In order to receive uninterrupted access to
								ApertureData Support from one subscription term
								to the next, Customer must renew their
								subscription to Software in a timely manner to
								avoid a lapse in Support.
							</span>
						</li>
						<li style={{ fontWeight: 400 }}>
							<p>
								<strong>Amendments</strong>
							</p>
							<span className={styles.span}>
								Except as expressly provided herein, no
								modification of this Support Policy will be
								effective unless contained in writing and signed
								by an authorized representative of each party.
								From time to time, ApertureData may amend this
								Support Policy in its sole discretion.
								ApertureData will post the amended terms on the
								ApertureData website at
								www.apertureData.io/support-policy. ApertureData
								will also update the “Last Updated Date” at the
								top of this Support Policy. By continuing to
								access or use Support after ApertureData has
								provided Customer with such notice of a change,
								Customer is indicating that it agrees to be
								bound by the modified Support Policy. If the
								changes have a material adverse impact on and
								are not acceptable to Customer, then Customer
								must notify ApertureData within 30 days of the
								applicable Last Updated Date. If ApertureData
								cannot accommodate Customer’s objection, then
								the prior Support Policy shall govern until the
								expiration of the then-current subscription
								period. Any renewed subscription will be
								governed by the then-current Support Policy. No
								term or condition contained in Customer’s
								purchase order or similar document will apply
								unless specifically agreed to by ApertureData in
								writing, even if ApertureData has accepted the
								order set forth in such purchase order, and all
								such terms or conditions are otherwise hereby
								expressly rejected by ApertureData.
							</span>
							<br></br>
							<br></br>
						</li>
					</ol>
				</ol>
			</div>
			<ContactUs />
		</>
	);
};

export default SupportPolicy;
