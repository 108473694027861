import styles from './Intro.module.scss';
import React from 'react';

const Intro: React.FC = () => {
	return (
		<div className={styles['intro-faq']}>
			<div className={styles['section-container']}>
				<h1>Frequently Asked Questions</h1>
			</div>
		</div>
	);
};

export default Intro;
