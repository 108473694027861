import styles from './FaqRow.module.scss';
import { BulletType, FAQType } from '../Faq';
import React from 'react';

interface FaqProps {
	faq: FAQType;
}

const FaqRow: React.FC<FaqProps> = (props) => {
	return (
		<div className={styles['faq-row']} id={props.faq.title}>
			<div className={styles['section-container']}>
				<div className={styles['faq-title']}>{props.faq.title}</div>
				<div className={styles['faq-description']}>
					{props.faq.bullets.map((bullet: BulletType, index: number) => {
						return (
							<>
								<div
									className={styles['faq-description-question']}
									key={`${index}-question`}
								>
									<span className={styles['pre-text']}>Q:</span>
									<span className={styles['question']}>{bullet.question}</span>
								</div>
								<div
									className={styles['faq-description-answer']}
									key={`${index}-answer`}
								>
									<span className={styles['pre-text']}>A:</span>
									<span className={styles['answer']} dangerouslySetInnerHTML={{ __html: bullet.answer }}></span>
								</div>
							</>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default FaqRow;
