import styles from './FilterOptionsCard.module.scss';
import React from 'react';
import { FilterOptionType } from '../FilterOptionInterface';

interface FilterProps {
	case: FilterOptionType;
	handleOption: (data: string) => void;
	optionState: string;
}

const FilterOptionCard: React.FC<FilterProps> = (props) => {

	return (
			<div onClick={() => props.handleOption(props.case.type)} className={`${styles['case-card']} ${props.optionState === props.case.type && styles['active']} `}>
				<div className={styles['case-text']}>
					<span className={styles['case-title']}>{props.case.type}</span>
				</div>
			</div>
	);
};

export default FilterOptionCard;
