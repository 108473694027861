/* eslint-disable no-mixed-spaces-and-tabs */
import styles from './JobsSection.module.scss';
import JobCard from './JobCard';
import { JobsSectionType } from '../JoinUs';
import React from 'react';

interface JobsSectionProps {
	jobsSection: JobsSectionType;
}

const JobsSection: React.FC<JobsSectionProps> = ({ jobsSection }) => {
	return (
		<div className={styles['jobs-section']}>
			<div
				className={`${styles['section-container']} ${styles['section-container-extension']}`}
			>
				<div className={styles['title-column']}>{jobsSection.title}</div>
				<div className={styles['content-column']}>
					{jobsSection.jobs.length
						? jobsSection.jobs.map((job) => (
								<JobCard
									job={job}
									sectionId={jobsSection.id}
									key={'job-' + job.id}
								/>
						  ))
						: 'There are no open positions at the moment'}
				</div>
			</div>
		</div>
	);
};

export default JobsSection;
