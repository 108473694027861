import styles from "./NewSolution.module.scss";
import React from "react";
import Graph from "../../../assets/images/NewSolution/Graph.svg";
import back from "../../../assets/images/Landing/newSolutionBackground.png";

const NewSolution: React.FC = () => {
	return (
		<div className={`${styles["new-solution"]}`}>
			<div className={styles.backgroudSection}>
				<img src={back} alt="alt" />
			</div>
			<div
				className={`${styles["section-container"]} ${styles["section-container-extension"]}`}
			>
				<span className={styles.firstTitle}>
					Imagine the benefits of having a purpose-built database for
					multimodal AI
				</span>
				<span className={styles.subTitle}>
					Organizations are looking for faster time to value at
					enterprise scale, reduced network traffic, faster machine
					learning iterations, better collaboration among data science
					and ML teams, all without losing trust and confidence in the
					underlying data.
				</span>
				<div className={styles.imgContainer}>
					<img src={Graph} alt="Graph" />
				</div>
				<div className={styles.footerText}>
					<p>
						Our innovative approach to data management
						delivers rapid time to value removing months of manual
						effort, accelerates data science
					</p>
					<p>
						team productivity, reduces complexity and total cost of
						ownership in supporting complex analytic use cases.
					</p>
				</div>
			</div>
		</div>
	);
};

export default NewSolution;
