import CaseList from "./sections/CaseList";
import cases from "../../assets/content/UseCases";
import Cases from "./sections/Cases";
import Intro from "./sections/Intro";
import React from "react";
import styles from "./UseCases.module.scss";
import ContactUs from "../Landing/sections/ContactUs";
export interface CaseType {
	icon: string;
	name: string;
	title: string;
	description: string;
	image: string;
	bullets: string[];
}

const UseCases: React.FC = () => {
	return (
		<div className={styles["use-cases"]}>
			<Intro />
			<Cases cases={cases} />
			<CaseList cases={cases} />
			<ContactUs />
		</div>
	);
};

export default UseCases;
