import styles from './ContactSection.module.scss';
import ContactForm from './ContactForm';
import PeopleImg from '../../../assets/images/ContactUs/people.png';
import React from 'react';

const ContactSection: React.FC = () => {
	return (
		<div className={styles['contact-page-section']}>
			<div
				className={`${styles['section-container']} ${styles['section-container-extension']}`}
			>
				<ContactForm />
				<div className={styles['contact-us-image-container']}>
					<img
						src={PeopleImg}
						className={styles['contact-us-image']}
						alt='people'
					/>
				</div>
			</div>
		</div>
	);
};

export default ContactSection;
