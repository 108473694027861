import styles from './Resources.module.scss';
import React from 'react';
import ResourceList from './sections/ResourceList';
import resources from '../../assets/content/Resources';
import Intro from './sections/Intro';
import ContactUs from '../Landing/sections/ContactUs';
import FilterOptions from '../../components/FilterOptions/FilterOptions';
import ResorcesOptions from '../../assets/content/ResourcesOptions';

export interface ResourceType {
	title: string;
	description: string;
	image: string;
	type: string;
	link: string;
	readMoreText?: string | undefined;
}

const Resources: React.FC = () => {
	const [filterOption, setFilterOption] = React.useState<string>('All');
	const [ filterResult, setFilterResult] = React.useState<ResourceType[]>([]);

	React.useEffect(() => {
		const handleFilterPost = () => {
			if(filterOption !== 'All') {
				const resultFilter = resources.filter(item => item.type.toLocaleLowerCase() === filterOption.toLocaleLowerCase());

				setFilterResult(resultFilter);
			} else {
				setFilterResult(resources);
			}
		};

		handleFilterPost();

	}, [filterOption]);

	const handleFilterOptionSelected = (state : string) => {
		setFilterOption(state);
	};

	return (
		<div className={styles.resources}>
			<Intro />
			<FilterOptions cases={ResorcesOptions} handleFilterOptionSelected={handleFilterOptionSelected} />
			<ResourceList resources={filterResult} allResources={resources}/>
			<ContactUs />
		</div>
	);
};

export default Resources;
