import styles from "./FaqMenu.module.scss";
import FaqMenuItem from "./FaqMenuItem";
import { FAQType } from "../Faq";
import React from "react";

interface FaqMenuProps {
	faqs: FAQType[];
}

const FaqMenu: React.FC<FaqMenuProps> = ({ faqs }) => {
	return (
		<div className={styles.menu}>
			<div className={styles["section-container"]}>
				{faqs.map(
					(faq: FAQType, index: number): JSX.Element => (
						<FaqMenuItem faq={faq} key={faq.title} showDivider={index + 1 !== faqs.length} />
					)
				)}
			</div>
		</div>
	);
};

export default FaqMenu;
