import styles from './Landing.module.scss';
import ContactUsLandingPage from './sections/ContactUsLandingPage';
import Features from './sections/Features';
import Intro from './sections/Intro';
import NewSolution from './sections/NewSolution';
import React from 'react';
import Users from './sections/Users';
import CaseStudiesCarousel from './sections/CaseStudiesCarousel';
import Why from './sections/Why';
import OurSolution from './sections/OurSolution';
import DataExperts from './sections/DataExperts';
import caseStudies from "../../assets/content/CaseStudies";

const Landing: React.FC = () => {

	return (
		<div className={styles.landing}>
			<Intro />
			<CaseStudiesCarousel caseStudies={caseStudies} />
			<Users />
			<Why />
			<NewSolution />
			<OurSolution />
			<DataExperts />
			<Features />
			<ContactUsLandingPage />
		</div>
	);
};

export default Landing;
