import React from "react";
import styles from "./CaseStudies.module.scss";
import ContactUs from "../Landing/sections/ContactUs";
import Intro from "./sections/Intro";
import CaseStudiesList from './sections/CaseStudiesList';
import caseStudies from "../../assets/content/CaseStudies";

const CaseStudies: React.FC = () => {
	return (
		<div className={styles["case-studies"]}>
			<Intro />
			<CaseStudiesList caseStudies={caseStudies} />
			<ContactUs />
		</div>
	);
};

export default CaseStudies;
