import styles from './ContactSection.module.scss';
import ContactForm from './ContactForm';
import React from 'react';

const ContactSection: React.FC = () => {
	return (
		<div className={styles['contact-page-section']}>
			<div
				className={`${styles['section-container']} ${styles['section-container-extension']}`}
			>
				<ContactForm />
			</div>
		</div>
	);
};

export default ContactSection;
