import './BlogDetails.scss';
import React from 'react';
import styles from './BlogsDetails.module.scss';
import ContactUs from '../../Landing/sections/ContactUs';
import Intro from './sections/Intro';
import blogsData from '../../../assets/content/BlogsData';
import { useParams, useNavigate } from 'react-router-dom';
import { BlogType } from '../Blog';
import { UseViewPortWidth } from '../Hook/UseViewPortWidth';
import { Divider, Button } from '@material-ui/core';
import { ReactComponent as Avatar } from '../../../assets/images/Blogs/avatar.svg';
import { ReactComponent as XSocialMedia } from '../../../assets/images/Blogs/x.svg';
// import { ReactComponent as Reddit } from '../../../assets/images/Blogs/reddit.svg';
import { ReactComponent as LinkedIn } from '../../../assets/images/Blogs/linke.svg';
import { ReactComponent as Share } from '../../../assets/images/Blogs/share.svg';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

type BlogDataWithIndex = {
  index: number;
  blog: BlogType;
}

const BlogsDetails = () => {
	const [blogSelected, setBlogSelected] = React.useState<BlogDataWithIndex>();
	const [blogsDataWithIndex, setBlogsDataWithIndex] = React.useState<BlogDataWithIndex[]>([]);
	const navigate = useNavigate();
	const { id } = useParams();
	const viewPortWidth = UseViewPortWidth();

	React.useEffect(() => {
		const handleFilterPost = () => {
			if (id) {
				const resultFilter = blogsDataWithIndex.find(item => item.blog.id === id);

				setBlogSelected(resultFilter);
			}
		};

		handleFilterPost();
	}, [blogsDataWithIndex, id]);

	function handleCopyUrlToShare(text: string) {
		const input = document.createElement('input');

		input.value = text;
		document.body.appendChild(input);

		input.select();
		input.setSelectionRange(0, 99999);

		document.execCommand('copy');

		document.body.removeChild(input);
	}

	const handleNavigateNextPost = () => {
		const nextPost = blogsDataWithIndex.find(item => item.index === (blogSelected?.index ?? -1) + 1);

		if (nextPost) {
			navigate(`/blog/${nextPost.blog.id}`);
		}
	};

	const handleNavigatePreviousPost = () => {
		const previousPost = blogsDataWithIndex.find(item => item.index === (blogSelected?.index ?? -1) - 1);

		if (previousPost) {
			navigate(`/blog/${previousPost.blog.id}`);
		}
	};

	React.useEffect(() => {
		const blogs:BlogDataWithIndex[] = 	blogsData.map((blog: BlogType, index: number) => {
			return {blog, index};
		});

		setBlogsDataWithIndex(blogs);
		scrollToTheTop();
	}, []);

	const scrollToTheTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'auto',
		});
	};

	React.useEffect(() => {
		scrollToTheTop();
	}, [blogSelected]);

	return (
		<div className={styles.blogs}>
			<Intro data={blogSelected?.blog ?? blogsData[0]} />
			{viewPortWidth <= 950 &&
				<div className={styles.imageMobileCover}><img src={blogSelected?.blog.image} alt={blogSelected?.blog.title} className={styles.img}/></div>
			}
			<div className={styles.detailsBody}>
				<div>
					<div className={styles.authContainer}>
						<div className={styles.auth}>
							{blogSelected?.blog.author.map((person: { name: string, image: string }) => (
								<div key={person.name} className={styles.auth}>
									<div className={`${person.image !== '' ? styles.authImage : styles.authAvatar}`}>
										{person.image === '' ? <Avatar /> :
											<img src={person.image} alt={person.name} />
										}
									</div>
									<div className={styles.authName}>
										<p>{person.name}</p>
									</div>
								</div>))}
						</div>
						{viewPortWidth > 950 &&
							<div className={styles.optionsContainer}>
								<a href={`https://twitter.com/intent/tweet?text=Hey!%20Check%20out%20this%20post!%20${window.location.href}%20%40ApertureData&original_referer=https://aperturedata.io/`} target='_blank' rel='noreferrer'>
									<XSocialMedia />
								</a>
								{/* <a href={data[0]?.reddit} target='_blank' rel='noreferrer'>
									<Reddit />
								</a> */}
								<a href={`https://www.linkedin.com/share?text=Hey!%20Check%20out%20this%20post!%20${window.location.href}%20%40ApertureData&original_referer=https://aperturedata.io/`} target='_blank' rel='noreferrer'>
									<LinkedIn />
								</a>
								<a href={'#'}>
									<Share onClick={() => handleCopyUrlToShare(window.location.href)} />
								</a>
							</div>
						}
					</div>
					{viewPortWidth > 950 &&
						<Divider light className={styles.divider} />
					}
				</div>
				<div className={'blogDetailContainer'}>
					<div dangerouslySetInnerHTML={{ __html: blogSelected?.blog.bodyText ?? ""}} />
				</div>
				{blogSelected && blogSelected.blog.tags.length > 0 &&
					<div className={styles.tagsContainer}>
						<a className={styles.tagText}>
							Tags:
						</a>
						{ blogSelected && blogSelected.blog.tags.map((tag: string, index: number): JSX.Element => {
							return (
									<Button
										className={styles.button}
										key={index}
										variant="outlined"
										size="small"
										onClick={() => navigate(`/blog/?tag=${encodeURIComponent(tag)}`)}
									>
										{tag}
									</Button>
								);
							})
						}
					</div>
				}
				<Divider light className={styles.divider} />
				<div className={styles.previousAndNextPostsContainer}>
					{ blogSelected && blogSelected.index > 0 &&
						<Button
							className={styles.button}
							startIcon={<ArrowBackIosIcon />}
							variant="text"
							onClick={handleNavigatePreviousPost}>
								{'Previous'}
						</Button>
					}
					<div className={styles.nextPostContainer}>
						{ blogSelected && blogSelected.index < blogsData.length - 1 && (
							<Button
								className={styles.button}
								endIcon={<ArrowForwardIosIcon />}
								variant="text"
								onClick={handleNavigateNextPost}
							>
								{'Next'}
							</Button>
						)}
					</div>
				</div>
			</div>
			<ContactUs />
		</div>
	);
};

export default BlogsDetails;
