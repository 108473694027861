import { CaseStudyType } from '../../pages/CaseStudy/CaseStudy';
import caseStudyHeader from '../images/CaseStudies/BadgerTechnologies/case-study-logo.png';
import caseStudyOne from '../images/CaseStudies/BadgerTechnologies/caseStudyOne.svg';
import caseStudyTwo from '../images/CaseStudies/BadgerTechnologies/caseStudyTwo.svg';
import caseStudyThree from '../images/CaseStudies/BadgerTechnologies/caseStudyThree.svg';
import companyLogo from '../images/CaseStudies/BadgerTechnologies/company-logo.png';
import caseArticleOne from '../images/CaseStudies/BadgerTechnologies/case-article-one.png';
import caseArticleTwo from '../images/CaseStudies/BadgerTechnologies/case-article-two.png';
import caseArticleThree from '../images/CaseStudies/BadgerTechnologies/case-article-three.png';

const caseStudies: CaseStudyType[] = [
	{
		id: '1',
		card: {
			authorComment: `Leslie Hensley`,
			authorPosition: `Solution Architect, Badger Technologies`,
			comment: `With ApertureDB, more folks can be asleep at 5AM instead of babysitting our vector database.`,
			descriptionCarousel:`Badger Technologies, a retail automation company, was able to increase the performance of vector similarity search against their embedding library by 2.5x using ApertureDB.`,
			description:
				`Badger Technologies is a retail automation company that utilizes multipurpose robots for smart shelf scanning. The company uses ApertureDB to enhance vector search performance on their library of embeddings, achieving a 2.5x
				improvement in query speed in production deployments.`,
			image: caseStudyHeader,
			title: 'Badger Technologies',
			titleCarousel: `Badger Technologies Uses ApertureDB to Solve "Wrong Product" Placement Problems at Scale`,
			date: 'November 15, 2023',
			pdf: 'badgerTechnologies.pdf',
			link: 'https://www.badger-technologies.com/',
		},
		introduction: {
			title: `BADGER TECHNOLOGIES USES APERTUREDB TO SOLVE “WRONG PRODUCT" PLACEMENT
			PROBLEMS AT SCALE`,
			text: `Badger Technologies is a retail automation company that utilizes multipurpose robots for smart shelf scanning. The company uses ApertureDB to enhance vector search performance on their library of embeddings, achieving a 2.5x improvement in query speed in production deployments.`,
			companyLogo: companyLogo,
			images: [caseStudyOne, caseStudyTwo, caseStudyThree],
		},
		text: `Badger Technologies is a pioneer in robotics automation that empowers retailers to improve operational efficiencies and shopping experiences through the use of multipurpose, automated robots. Equipped with arrays of cameras, depth sensors, and LIDAR, these modular robots ensure accurate product placement in hundreds of grocery store locations (as well as identifying spills, hazards, and even store security and safety compliance). Badger Technologies relies heavily on computer vision and machine learning to deliver this vital business intelligence, and now, the company is elevating its visual data infrastructure with ApertureDB.
		Using ApertureDB, Badger Technologies was able to increase the performance of vector similarity search against their embedding library by 2.5x (we are now seeing up to 3x in lab deployments). With their prior solution, Badger Technologies was maxing out at 4,000 queries per second and encountering major stability issues, while ApertureDB now provides performance in excess of 10,000 queries per second at a high degree of stability (we’re now seeing over 12,000 queries per second with our next release).
		We sat down with Leslie Hensley, Solution Architect, and CT Wolfe, Imaging Research Scientist, at Badger Technologies to get a direct line on how ApertureDB was instrumental in scaling their in-store solution and how they envisioned its impact to grow as they grew their customer base.`,
		articles: [
			{
				article: {
					comment:
						`With ApertureDB, more folks can be asleep at 5AM instead of babysitting our vector database.`,
					authorComment: `Leslie Hensley`,
					authorPosition: `Solution Architect, Badger Technologies`,
					notices: [
						{
							title: `HOW DOES BADGER TECHNOLOGIES SERVE ITS CUSTOMERS?`,
							text: `Each morning, Badger Technologies takes the tedium out of product placement by delivering reports identifying out-of-stock, misplaced, or mispriced items to individual stores (along with liability concerns like spills and other potential hazards). Using dashboards and inventory management integrations, Badger Technologies makes it straightforward to take action on these critical loss of revenue concerns before it impacts customer shopping experiences.
							If your favorite hot sauce isn’t on the shelf or an apparent discount on ice cream doesn’t materialize at the register, the store takes the blame — an outcome no grocer wants. We all rely on a retailer’s ability to maintain and accurately position and label products, and when you have thousands of products to keep track of, that’s a tall order!`,
							source: {image: caseArticleOne},
						},
						{
							title: `WHAT CHALLENGES DID BADGER TECHNOLOGIES  HAVE WITH VISUAL DATA?`,
							text: `At peak throughput, Badger Technologies’ fleet of robots capture thousands of images of store shelves per second. But images are only part of the story.
							On-device computer vision models generate huge quantities of embeddings that are uploaded to the cloud alongside that image data. It is vital to rapidly search over this large collection of embeddings in order to provide timely and actionable insights for Badger Technologies’ customers. Time is of the essence: Grocers need to open at the same time every day, and planogram (layout) compliance must be completed before shoppers arrive.
							Vector search and classification are a key component of this process. To that end, Badger Technologies required a vector database solution that could scale quickly and reliably to keep pace with growing data ingestion demands. Instability and poor performance from their previous solution were resulting in delayed reporting — an unacceptable outcome for customers.`,
							source: {image: caseArticleTwo},
						},
						{
							title: `HOW DID APERTUREDB HELP BADGER TECHNOLOGIES?`,
							text: `By implementing ApertureDB, a database for images, videos, metadata (annotations), and embeddings, Badger Technologies has taken its search capabilities to the next level while eliminating search bottlenecks.
							With ApertureDB, Badger Technologies is achieving impressive vector-search performance and is meeting its commitment to deliver business intelligence to customers in a timely manner. With its optimized vector search implementation together with an in-memory graph database, ApertureDB is far more performant than existing vector database solutions on the market. Not only does ApertureDB enable greater scale and efficiency for operations driven by vector searches, but it also unifies the entire visual data pipeline in a single database. Images, metadata, and embeddings all live inside ApertureDB, creating a unified access point for multiple teams within Badger Technologies.
							As a result, Badger Technologies now has a powerful tool for dataset management in ApertureDB, which they plan to leverage in their ML model training workflow. Because all of the relevant data for training and validating models can be managed by ApertureDB (and is fully searchable, including built-in KNN search), there’s no more messy iterating and downloading of various blobs and zip files. This results in less data duplication, less confusion, and more time spent building a better product.`,
						},
					],
				},
			},
			{
				article: {
					comment: `ApertureDB vector search is a key part of determining "Wrong Product" issues quickly and at scale.`,
					authorComment: `Leslie Hensley`,
					authorPosition: `Solution Architect, Badger Technologies`,
					notices: [
						{
							title: `WHY DID BADGER TECHNOLOGIES CHOOSE APERTUREDB?`,
							text: `According to Leslie and CT, ApertureDB was the only solution that could deliver stability and performance at a scale of millions of vectors. Moreover, the ApertureData team closely collaborated with the Badger Technologies team to fully meet their goals (praise we very gladly accept).
							Badger Technologies evaluated a half dozen vector databases in its search for a powerful vector search solution. Besides failing on the core metrics of vector search performance and overall stability, none of them offered a comprehensive visual data management platform. With ApertureDB, Badger Technologies found a way to convert raw data captured from customer stores into datasets for training ML models, allowing them to increase accuracy for those customers. ApertureDB can now allow them to iterate faster when training those models.`,
							source: {image: caseArticleThree, position: 'BOTTOM'},
						},
						{
							title: `HOW CAN APERTUREDB HELP ME WITH VECTOR SEARCH OR DATASET MANAGEMENT FOR ML TRAINING?`,
							text: `ApertureDB is a solution that is well-suited to a wide variety of visual data management, ML training, and vector search challenges. Because our database is optimized for in-memory operations, it achieves performance even purpose-built vector databases can’t. And unlike those dedicated vector databases, ApertureDB is built with all of your visual data in mind: metadata (e.g., annotations), embeddings, imagery, and video are all stored and related in our in-memory graph database.`,
						},
					],
				},
			},
		],
	},
];

export default caseStudies;
