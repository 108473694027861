export const retailAi = `<html>
<head>
	<meta content="text/html; charset=UTF-8" http-equiv="content-type">
</head>

<body class="c16 doc-content">
	<p class="c3"><span class="c4">Retailers</span><span>&nbsp;and
		</span><span class="c4">ecommerce</span><span>&nbsp;leaders
			are obsessed with improving customer experience to ultimately drive bottom-line results. </span><span>In the
			world of smart retail, innovations like inventory management, out of stock warnings, and frictionless stores
			are transforming shopper</span><span>&nbsp;</span><span>experience and lowering labor costs.
		</span><span>Ecommerce vendors have invested billions of dollars to improve their consumers&rsquo; online
			experience through use of </span><span class="c4"><a class="c7"
				href="/blog/whats-in-your-visual-dataset" target="_blank">visual
				assets</a></span><span>&nbsp;</span><span>to optimize resources and ultimately increase sales.
		</span><span>But how do they get from strategy to reality? With Multimodal AI.</span></p>
	<p class="c0"><span class="c2"></span></p>
	<p class="c3"><span>Multimodal AI, stated simply, is intelligence derived from a combination of data types like
			images, videos, text, and audio, and is key to meeting these customer experience and business goals. It is
			becoming increasingly important for businesses to capitalize on understanding these constantly changing
			customer needs and stay ahead of the competition, as AI allows them to extract more value from their data.
		</span><span class="c2">&nbsp;</span></p>
	<p class="c0"><span class="c8 c6"></span></p>
	<h1 class="c13" id="h.4h4vbnuu0y9v"><span>Multimodal AI Use Cases In Retail And Ecommerce</span></h1>
	<p class="c3"><span>While we are just scratching the surface on how multimodal data and AI can boost
        retail sales, lower labor costs, and provide great customer experience, let's look at a few
    use cases that are already proving worth their investment and how they are accomplished.</span></p>
	<h2 class="c1" id="h.m26v9cvymoec"><span>Actionable Data</span><span class="c11">&nbsp;for Retail Operations</span>
	</h2>
	<p class="c3"><span>Tracking holes on shelves, misplaced items, price mismatch, planogram compliance, hazard
			mitigation, and fraud avoidance are just a few examples of how companies ensure a safe and smooth shopping
			experience for their consumers. </span><span class="c18">Camera-based solutions</span><span>&nbsp;allow
			these companies to capture pictures in real time and use </span><span class="c4"><a class="c7"
				href="/case-studies/1" target="_blank">vector
				classification</a></span><span class="c2">&nbsp;and matching with product catalogs to be much more
			accurate and cover more ground daily and efficiently, making them more attractive than manual scans. These
			solutions rely on AI models trained on (labeled) store data at regular intervals to improve their
			accuracy.</span></p>

	<p class="imgContainer">
		<span>
			<img
				alt=""
				src="https://aperturedata-public.s3.us-west-2.amazonaws.com/website_images/retail_blog_dec23/image5.jpg"
                style="width: 60%; height: auto;" 
				title=""
			>
		</span>
	</p>
	<p class="c0 c9"><span class="c2"></span></p>
	<p class="c3"><span class="c8 c6">Figure 1: <a href="https://www.badger-technologies.com/resource/badger-resources.html" target="_blank">Smart
        shelf scanning</a> by automated robot in supermarket setting</span></p>
	<h2 class="c1" id="h.3bhxjer0xwpe"><span class="c11">AI-Driven Insights for Shopper Behavior and Frictionless
			Checkout</span></h2>
	<p class="c3"><span>A common goal for retailers is frictionless shopping and checkout that leverages machine
			learning, computer vision, cameras, and sensors to detect shopper movements within store, time they spend
			interacting with various products, store layout, as well as the products customers put in their basket and
			purchase at the register, all with minimal need to line up at the checkout or wait in line to interact with
			a traditional cashier. </span><span>This is made possible at scale with AI models trained to detect people,
			products, and their interactions within the stores, as collected from all the camera and sensor data in
			these stores. Using labels, product and model metadata, and their relationships with images and videos
			enables these shopper insights and </span><span>analytics</span><span class="c2">. Leading retailers use
			these insights for effective category management and to drive their overall retail strategy.</span></p>
	<p class="c0"><span class="c8 c6"></span></p>
	<p class="imgContainer">
		<span>
			<img
				alt=""
				src="https://aperturedata-public.s3.us-west-2.amazonaws.com/website_images/retail_blog_dec23/image1.png"
                style="width: 60%; height: auto;" 
				title=""
			>
		</span>
	</p>
	<p class="c3"><span class="c8 c6">Figure 2: <a href="https://link.springer.com/article/10.1007/s10462-022-10142-3" target="_blank">Heatmaps</a>
        in a supermarket setting showing where people spend more time and other details</span></p>
	<h2 class="c1" id="h.g3jum0wbvlng"><span>Personalized Recommendations</span></h2>
	<p class="c3"><span>As consumers, we are likelier to buy something if it visually appeals to us. </span><span>These
			personalized recommendations using product signatures or embeddings require deep learning models to help
			form clusters of similar products based on visual features like colors and patterns correlated to the user
			buying the products. </span><span class="c4"><a class="c7"
				href="/blog/are-vector-databases-enough" target="_blank">Vector
				search and classification</a></span><span>&nbsp;filtered with user metadata is a key element when
			recommending the right products. </span><span>These can then be shown online or even in store on
			personalized displays, together with other relevant product information fetched from this enriched
			catalog.</span></p>

	<p class="imgContainer">
		<span>
			<img
				alt=""
				src="https://aperturedata-public.s3.us-west-2.amazonaws.com/website_images/retail_blog_dec23/image3.png"
				title=""
			>
		</span>
	</p>
	<p class="c3"><span class="c8 c6">Figure 3: <a href="https://www.visenze.com/discovery-suite/modules/smart-recommendations/" target="_blank">Outfit
            recommendations</a> that delight shoppers and boost cart
			sizes</span></p>
	<p class="c0"><span class="c2"></span></p>
	<h1 class="c13" id="h.fafd1btrp1oz"><span class="c12">Current Challenges Facing Data Scientists And AI Teams</span>
	</h1>
	<p class="c3"><span>The benefits of smart retailers and ecommerce leaders harnessing multimodal data are clear yet
			it is resource-consuming and depends on quality data to support. Their top goals are to provide valuable
			customer insights, optimize resources, and increase sales but they are challenging to reach. While AI
			algorithms and models are seeing rapid improvements, </span><span>common challenges</span><span>&nbsp;for
			data scientists and AI teams to prove the value and deploy in production are outlined below:</span></p>
	<p class="c0"><span class="c8 c6"></span></p>
	<p class="c3 c10"><span class="c6"><b>Data Not Accessible</b>: </span><span>Critical business information is often
			dispersed in hard-to-reach silos, creating a challenge for teams to access the relevant knowledge
			collaboratively. Unfortunately, this can lead to a lack of shared understanding or, even worse, inconsistent
			replication of data across different teams. </span></p>
	<p class="c3 c10"><span class="c6"><b>Data Inconsistency and Loss</b>:</span><span>&nbsp;</span><span>When subpar tools are
			in use, data loss and consistency problems become significant concerns. This can cast doubt on the
			reliability of insights, whether it&#39;s due to outdated data or insufficient high-quality data, thereby
			questioning the true business value.</span></p>
	<p class="c3 c10"><span class="c6"><b>Rising Costs</b>:</span><span>&nbsp;Cloud costs are on the rise, raising questions
			about the cost vs. benefit of utilizing multimodal data. Data science expenses often surge without a
			commensurate return on investment (ROI) due to ineffective resource utilization caused by suboptimal
			tooling.</span></p>
	<p class="c3 c10"><span class="c6"><b>Not Production Ready</b>: </span><span class="c2">A production-ready system providing
			adequate scaling, performance, and security guarantees is even harder to build for complex data and such
			evolving use cases. This can easily cause <i>6 months to a year of delay</i> in valuable ML research.</span></p>
	<p class="c3 c10"><span class="c6"><b>Cannot scale with growing needs</b>: </span><span class="c4">Scaling
				to large volumes</span><span class="c2">&nbsp;is hard and achieving high performance can be very
			challenging.</span></p>
	<p class="c0"><span class="c2"></span></p>
	<p class="c3"><span>Even with advancements in data science and machine learning, the success of AI heavily relies on
			dependable and accurate data. </span><span class="c2">All of the use cases detailed above require:</span>
	</p>
	<ol class="c15 lst-kix_h1low8f9j9rq-0 start" start="1">
		<li class="c3 c5 li-bullet-0"><span class="c2">Easily storing and cataloging the data that&rsquo;s being continuously
				generated</span></li>
        <li class="c3 c5 li-bullet-0">Iteratively training ML models using these in-store or online images or videos, regularly, to continue to
				improve accuracy on latest data</span></li>
		<li class="c3 c5 li-bullet-0"><span class="c2">Seamlessly integrating with labeling and curation
				frameworks in-house or through 3rd party vendors as this data can often require annotations</span></li>
		<li class="c3 c5 li-bullet-0"><span>Finally, generating useful insights or creating relevant datasets using
				product and vector search capabilities which in turn require all the data to be indexed and continuously
				enriched, in a consistent manner</span></li>
	</ol>
	<p class="c0"><span class="c2"></span></p>
	<h1 class="c13" id="h.ic7v29kh64zm"><span class="c12">Next Steps On Your Multimodal AI Journey</span></h1>
	<p class="c3"><span class="c4"><a class="c7"
				href="/use-cases" target="_blank">Use
				cases</a></span><span class="c2">&nbsp;like these and the challenges explained above
			are exactly why retailers and ecommerce leaders need a database purpose-built for multimodal AI. This can
			help them build a central repository of their product images, store videos, and corresponding attribute
			metadata as well as keep track of their annotations, embeddings, datasets, and relevant model
			behaviors. Such a database is also necessary to enable collaboration among data science and engineering teams so that they can build on
			each other&rsquo;s work, and keep evolving the richness of information they manage. When successful,
			retailers and ecommerce leaders gain invaluable customer insights leading to better customer experiences
			with more efficient and profitable operations.</span></p>
	<p class="c0"><span class="c2"></span></p>
	<p class="c3"><span>The ability to search, efficiently access, process, and visualize data is paramount for the
			success of AI deployments. Many retailers begin with cloud-based storage solutions but then realize, sometimes quite late, that when it
			comes to multimodal data for AI, specifically images, videos, or even documents, just knowing filenames often
			isn&#39;t enough. Searching via different modalities like metadata, labels, embeddings, requires multiple databases
            catering to each type, and then
			preprocessing the required data to the right format requires complex libraries like ffmpeg or opencv.
                The various components then need to be stitched together which is often done in an ad hoc manner and these </span><span class="c4"><a
				class="c7"
				href="https://docs.aperturedata.io/Introduction/WhyAperture%23status-quo-manually-integrated-solution" target="_blank">traditional
				data management solutions</a></span><span class="c2">&nbsp;don&rsquo;t deliver what retailers and
			ecommerce leaders need. </span></p>
	<h2 class="c1" id="h.wr4yca40bbu8"><span>Consider </span><span>ApertureDB </span><span class="c11">- A Purpose-Built
			Database for Launching Multimodal AI</span></h2>
	<p class="c0"><span class="c2"></span></p>
	<p class="c3"><span>A unified approach to multimodal data,</span><span>&nbsp;</span><span class="c4"><a class="c7"
				href="https://docs.aperturedata.io/Introduction/WhatIsAperture" target="_blank">ApertureDB</a></span><span>&nbsp;replaces
			the manual integration of multiple systems to achieve multimodal search and
			access.</span><span>&nbsp;ApertureDB unifies the management of images, videos, embeddings, and associated
			metadata including annotations and integrates functionalities of a </span><span class="c4"><a class="c7"
				href="https://docs.aperturedata.io/HowToGuides/Basic/Image%20Similarity%20Search" target="_blank">vector
				database</a></span><span>, </span><span class="c4"><a class="c7"
				href="https://docs.aperturedata.io/HowToGuides/start/Schema" target="_blank">intelligence
				graph</a></span><span>, and multimodal data, to seamlessly query across data domains. It provides
			seamless integration within existing and </span><span class="c4"><a class="c7"
				href="https://drive.google.com/file/d/1saZmtHZXd_gTlPKQg0jzrsZMTga78H3R/view" target="_blank">new
				analytics pipelines</a></span><span>&nbsp;in a </span><span class="c4"><a class="c7"
				href="/faq#ApertureDB%20Deployment" target="_blank">cloud-agnostic
				manner</a></span><span>&nbsp;to bring speed, agility, and productivity to data science and ML
			teams.</span><span>&nbsp;</span><span class="c4">ApertureDB</span><span
			class="c2">&nbsp;allows all of the relevant data to be colocated for efficient retrieval, and complex
			queries to be handled transactionally.</span></p>
	<p class="c0"><span class="c2"></span></p>
	<p class="imgContainer">
		<span>
			<img
				alt=""
				src="https://aperturedata-public.s3.us-west-2.amazonaws.com/website_images/retail_blog_dec23/image2.jpg"
                style="width: 80%; height: auto;" 
				title=""
			>
		</span>
	</p>
	<p class="c3"><span class="c8 c6">Figure 4: A purpose-built database can really simplify users' data pipelines
        and shift focus back to the primary machine learning tasks and data understanding</span></p>
	<p class="c3"><span>If your organization uses or intends to use multimodal data (small or large team) or you are
			simply curious about our technology, our approach to infrastructure development, or where we are headed,
			please contact us at </span><span class="c4"><a class="c7"
				href="mailto:team@aperturedata.io" target="_blank">team@aperturedata.io</a></span><span>&nbsp;or try out </span><span
			class="c4"><a class="c7"
				href="/demo-request" target="_blank">ApertureDB
				on pre-loaded datasets</a></span><span>. If you&rsquo;re excited to join an early-stage startup and make
			a big difference, </span><span class="c4"><a class="c7"
				href="/join-us" target="_blank">we&rsquo;re
				hiring</a></span><span>. Last but not least, we will be documenting our journey and explaining all the
			components listed above on our blog, subscribe </span><span class="c4"><a class="c7"
				href="https://forms.gle/BdxgdUndw5FcsBQP6" target="_blank">here</a></span><span
			class="c2">.
            </span></p>
		<span class="c4"></span>
        <p class="c4">
                <span class="acknowledge">I want to acknowledge Laura Horvath for helping write this blog and the insights from Drew Ogle, and the ApertureData team.</span>
        </p>
	<p class="c0"><span class="c2"></span></p>
</body>
</html>
`;
