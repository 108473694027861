import styles from './CaseCard.module.scss';
import { CaseType } from '../../UseCases';
import React from 'react';

interface CaseProps {
	case: CaseType;
}

const CaseCard: React.FC<CaseProps> = (props) => {
	return (
		<a href={'#' + props.case.title} style={{ textDecoration: 'none' }}>
			<div className={styles['case-card']}>
				<div className={styles['case-text']}>
					<span className={styles['case-title']}>{props.case.title}</span>
				</div>
			</div>
		</a>
	);
};

export default CaseCard;
