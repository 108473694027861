import styles from "./ContactFormCarousel.module.scss";
import React, { useState } from "react";
import { Paper, MobileStepper, Button } from "@material-ui/core";
import demoContactFormImages from "../../../assets/content/DemoContactForm";

const ContactFormCarousel: React.FC = () => {
	const [activeStep, setActiveStep] = useState(0);

	return (
		<div className={styles["contact-form-carousel"]}>
			<div className={styles.container}>
				<Paper
					elevation={0}
					variant="outlined"
					className={styles.paper}
					style={{
						padding: "16px",
						margin: "8px",
						transform: "scale(1.05)",
					}}
				>
					<div className={styles["paper-container"]}>
						<div className={styles["image-container"]}>
							<img
								alt={demoContactFormImages[activeStep].label}
								src={demoContactFormImages[activeStep].imgPath}
								style={{
									height: 200,
									maxWidth: 350,
									minWidth: 350,
									overflow: "hidden",
									display: "block",
									width: "100%",
								}}
							/>
						</div>
					</div>
				</Paper>
			</div>
			{demoContactFormImages.length >= 2 && (
				<MobileStepper
					classes={{
						dotActive: styles["dot-active"],
						dot: styles.dot,
						root: styles["mobile-stepper"],
					}}
					steps={demoContactFormImages.length}
					position="static"
					variant="dots"
					activeStep={activeStep}
					nextButton={
						<Button
							size="small"
							onClick={() =>
								setActiveStep(
									(prevActiveStep) => prevActiveStep + 1
								)
							}
							disabled={
								activeStep === demoContactFormImages.length - 1
							}
							classes={{
								root:
									activeStep === demoContactFormImages.length - 1
										? styles["arrow-right-disabled"]
										: styles["arrow-right"],
							}}
						/>
					}
					backButton={
						<Button
							size="small"
							onClick={() =>
								setActiveStep(
									(prevActiveStep) => prevActiveStep - 1
								)
							}
							disabled={activeStep === 0}
							classes={{
								root:
									activeStep === 0
										? styles["arrow-left-disabled"]
										: styles["arrow-left"],
							}}
						/>
					}
				/>
			)}

		</div>
	);
};

export default ContactFormCarousel;
