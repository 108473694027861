import { Home } from './pages';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

const App = (): JSX.Element => {
	return (
		<Router basename="/">
			<Home />
		</Router>
	);
};

export default App;
