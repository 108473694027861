import { BlogType } from '../../pages/Blog/Blog';
import { addDockerLaunchBlog } from './BlogsContent/AddDockerLaunchBlog';
import { vectorDatabasesPartOneBlog } from './BlogsContent/VectorDatabasesPartOneBlog';
import { vectorDatabasesPartTwoBlog } from './BlogsContent/VectorDatabasesPartTwoBlog';
import { apertureDB2 } from './BlogsContent/ApertureDB2Blog';
import { blogCoco } from './BlogsContent/BlogCoco';
import { intro } from './BlogsContent/Intro';
import { lifeSciences } from './BlogsContent/LifeSciences';
import { evolvingMultimodalData } from './BlogsContent/YourMultimodalDataEvolving';
import { uiFrontend } from './BlogsContent/UiFrontend';
import { seedAnnouncement } from './BlogsContent/SeedAnnouncement';
import { similaritySearch } from './BlogsContent/similaritySearch';
import { retailAi } from './BlogsContent/RetailAI';
import { multimodalAI } from './BlogsContent/MultimodalAI';
import { purporseBuiltDatabase } from './BlogsContent/PurposeBuiltDatabase';
import { tcoBlog } from './BlogsContent/TCO';
import { docChatBotBlog } from './BlogsContent/DocChatBot';
import YourMultimodalDataEvolvingImg from '../images/Blogs/MultimodalDataEvolving.png';
import LifeSciencesImg from '../images/Blogs/LifeSciences.jpg';
import tcoImg from '../images/Blogs/watering_data.png';
import addDockerLaunchBlogImg from '../images/Blogs/docker.png';
import docChatBotBlogImg from '../images/Blogs/docChatBot.png';
import vectorDatabaseMultimodalAIImg from '../images/Blogs/brain_ai.jpg';
import vectorDatabaseMultimodalAIImgPart2 from '../images/Blogs/chatgpt.jpg';
import apertureIntro from '../images/Blogs/ApertureIntroBlog.jpg';
import blogCocoImg from '../images/Blogs/Aperture2.jpg';
import retailAiImg from '../images/Blogs/retail_blog.png';
import apertureDb2 from '../images/Blogs/BuildingSpecialDB.jpg';
import frontEnd from '../images/Blogs/DataPrep.jpg';
import seedAnnouncementImg from '../images/Blogs/InVisualDataset.jpg';
import similaritySearchImg from '../images/Blogs/VectorDBIntro.jpg';
import multimodalAIImg from '../images/Blogs/inspection1.jpg';
import purporseBuiltDatabaseImg from '../images/Blogs/purposeBuiltDatabaseImg.jpg';
import imgVishakha from '../images/Company/vishakha.jpg';
import imgLuis from '../images/Company/luis.png';
import imgJJ from '../images/Company/jj.png';
import { Tags } from '../content/Tags';

const blogsData: BlogType[] = [
	{
		id: 'doc-chat-bot',
		title: 'Can A RAG Chatbot Really Improve Content?',
		description: 'We asked our chatbot questions like "Can ApertureDB store pdfs?" and the answer it gave....',
		image: docChatBotBlogImg,
		type: 'APPLIED',
		bodyText: docChatBotBlog,
		date: 'August 8, 2024 - 5 min read ',
		author: [
			{ name: 'Vishakha Gupta', image: imgVishakha },
		],
		tags: [Tags.VECTOR_DATABASE, Tags.RAG, Tags.USABILITY],
	},
	{
		id: 'docker-launch',
		title: 'ApertureDB Now Available on DockerHub',
		description: 'Getting started with ApertureDB has never been easier or safer...',
		image: addDockerLaunchBlogImg,
		type: 'UPDATES',
		bodyText: addDockerLaunchBlog,
		date: 'May 7, 2024 - 4 min read ',
		author: [
			{ name: 'Julie-Jennifer Nguyen', image: imgJJ },
		],
		tags: [Tags.PRIVACY, Tags.DATASET_MANAGEMENT, Tags.GRAPH_DATABASE, Tags.VECTOR_DATABASE],
	},
	{
		id: 'multimodal-data-intro-part1',
		title: 'Vector Databases and Beyond for Multimodal AI: A Beginner\'s Guide Part 1',
		description: 'Multimodal AI, vector databases, large language models (LLMs)...',
		image: vectorDatabaseMultimodalAIImg,
		type: 'TECHNOLOGY',
		bodyText: vectorDatabasesPartOneBlog,
		date: 'July 1, 2024 - 7 min read ',
		author: [
			{ name: 'Vishakha Gupta', image: imgVishakha },
		],
		tags: [Tags.MULTIMODAL_AI, Tags.GENAI, Tags.MULTIMODAL_DATA, Tags.VECTOR_DATABASE, Tags.RAG, Tags.KNOWLEDGE_GRAPH],
	},
	{
		id: 'multimodal-data-intro-part2',
		title: 'Vector Databases and Beyond for Multimodal AI: A Beginner\'s Guide Part 2',
		description: 'Multimodal AI, vector databases, large language models (LLMs)...',
		image: vectorDatabaseMultimodalAIImgPart2,
		type: 'TECHNOLOGY',
		bodyText: vectorDatabasesPartTwoBlog,
		date: 'July 8, 2024 - 6 min read ',
		author: [
			{ name: 'Vishakha Gupta', image: imgVishakha },
		],
		tags: [Tags.MULTIMODAL_AI, Tags.GENAI, Tags.MULTIMODAL_DATA, Tags.VECTOR_DATABASE, Tags.SEMANTIC_SEARCH],
	},
	{
		id: 'purpose-built-database',
		title: 'Why Do We Need A Purpose-Built Database For Multimodal Data?',
		description: 'Recently, data engineering and management has grown difficult for companies building modern applications...',
		image: purporseBuiltDatabaseImg,
		type: 'TECHNOLOGY',
		bodyText: purporseBuiltDatabase,
		date: 'March 11, 2024 - 11 min read',
		author: [
			{ name: 'Vishakha Gupta', image: imgVishakha },
		],
		tags: [Tags.MULTIMODAL_AI, Tags.USABILITY, Tags.MULTIMODAL_DATA, Tags.DATASET_MANAGEMENT, Tags.HIGH_PERFORMANCE, Tags.SEMANTIC_SEARCH],
	},
	{
		id: 'challenges-with-multimodal-data-evolving',
		title: 'Your Multimodal Data Is Constantly Evolving - How Bad Can It Get?',
		description: 'The data landscape has dramatically changed in the last two decades...',
		image: YourMultimodalDataEvolvingImg,
		type: 'TECHNOLOGY',
		bodyText: evolvingMultimodalData,
		date: 'April 15, 2024 - 8 min read ',
		author: [
			{ name: 'Vishakha Gupta', image: imgVishakha },
		],
		tags: [Tags.USABILITY, Tags.MULTIMODAL_DATA, Tags.DATASET_MANAGEMENT, Tags.HIGH_PERFORMANCE, Tags.SEMANTIC_SEARCH],
	},
	{
		id: 'multimodal-data-tco',
		title: 'How a Purpose-Built Database for Multimodal AI Can Save You Time and Money',
		description: 'With extensive data systems needed for modern applications, costs...',
		image: tcoImg,
		type: 'TECHNOLOGY',
		bodyText: tcoBlog,
		date: 'Jul 29, 2024 - 8 min read ',
		author: [
			{ name: 'Vishakha Gupta', image: imgVishakha },
		],
		tags: [Tags.MULTIMODAL_DATA, Tags.DATASET_MANAGEMENT, Tags.GRAPH_DATABASE, Tags.VECTOR_DATABASE, Tags.MULTIMODAL_AI, Tags.GENAI],
	},
	{
		id: 'accelerate-inspection-multimodal-ai',
		title: 'Accelerate Industrial and Visual Inspection with Multimodal AI',
		description: 'From worker safety to detecting product defects to overall quality control, industrial and visual inspection plays a crucial role...',
		image: multimodalAIImg,
		type: 'APPLIED',
		bodyText: multimodalAI,
		date: 'February 12, 2024 - 6 min read ',
		author: [
			{ name: 'Vishakha Gupta', image: imgVishakha },
		],
		tags: [Tags.MACHINE_LEARNING, Tags.MACHINE_LEARNING, Tags.DATASET_MANAGEMENT, Tags.VISUAL_DATA, Tags.MULTIMODAL_AI],
	},
	{
		id: 'are-vector-databases-enough',
		title: 'Are Vector Databases Enough for Visual Data Use Cases?',
		description: 'ApertureDB vector search and classification functionality is offered as part of our unified API defined to...',
		image: similaritySearchImg,
		type: 'TECHNOLOGY',
		bodyText: similaritySearch,
		date: 'December 12, 2023 - 8 min read ',
		author: [
			{ name: 'Vishakha Gupta', image: imgVishakha },
		],
		tags: [Tags.VECTOR_DATABASE, Tags.GRAPH_SEARCH, Tags.MULTIMODAL_AI, Tags.RAG],
	},
	{
		id: 'transforming-retail-ecommerce-multimodal-ai',
		title: 'Transforming Retail and Ecommerce with Multimodal AI',
		description: 'Multimodal AI can boost retail sales by enabling better user experience at lower cost but needs the right infrastructure...',
		image: retailAiImg,
		type: 'APPLIED',
		bodyText: retailAi,
		date: 'December 20, 2023 - 6 min read ',
		author: [
			{ name: 'Vishakha Gupta', image: imgVishakha },
		],
		tags: [Tags.RECOMMENDATION, Tags.SEMANTIC_SEARCH, Tags.GRAPH_SEARCH, Tags.DATASET_MANAGEMENT, Tags.VISUAL_DATA, Tags.MULTIMODAL_AI],
	},
	{
		id: 'life-sciences',
		title: 'Challenges and Triumphs: Multimodal AI in Life Sciences',
		description: 'AI presents a new and unparalleled transformational opportunity for the life sciences sector...',
		image: LifeSciencesImg,
		type: 'APPLIED',
		bodyText: lifeSciences,
		date: 'April 15, 2024 - 6 min read ',
		author: [
			{ name: 'Vishakha Gupta', image: imgVishakha },
		],
		tags: [Tags.MACHINE_LEARNING, Tags.MULTIMODAL_DATA, Tags.GRAPH_SEARCH, Tags.DATASET_MANAGEMENT, Tags.VISUAL_DATA, Tags.MULTIMODAL_AI],
	},
	{
		id: 'whats-in-your-visual-dataset',
		title: 'What’s in Your Visual Dataset?',
		description: 'CV/ML users need to find, analyze, pre-process as needed; and to visualize their images and videos along with any metadata easily...',
		image: frontEnd,
		type: 'PRODUCT',
		bodyText: uiFrontend,
		date: 'December 12, 2023 - 7 min read ',
		author: [
			{ name: 'Vishakha Gupta', image: imgVishakha },
		],
		tags: [Tags.SEMANTIC_SEARCH, Tags.MACHINE_LEARNING, Tags.GRAPH_SEARCH, Tags.DATASET_MANAGEMENT, Tags.VISUAL_DATA_EXPLORER, Tags.MULTIMODAL_AI, Tags.DEBUGGING, Tags.USABILITY],
	},
	{
		id: 'aperture-db-2',
		title: 'ApertureDB 2.0: Redefining Visual Data Management for AI',
		description:
			'A key to solving Visual AI challenges is to bring together the key learnings of...',
		image: apertureDb2,
		type: 'UPDATES',
		bodyText: apertureDB2,
		date: 'November 22, 2023 - 8 min read ',
		author: [
			{ name: 'Vishakha Gupta', image: imgVishakha },
		],
		tags: [Tags.SEMANTIC_SEARCH, Tags.MACHINE_LEARNING, Tags.GRAPH_SEARCH, Tags.DATASET_MANAGEMENT, Tags.VISUAL_DATA, Tags.PRIVACY],
	},
	{
		id: 'minute-made-data-preparation',
		title: 'Minute-Made Data Preparation with ApertureDB',
		description: 'Working with visual data (images, videos) and its metadata is no picnic...',
		image: blogCocoImg,
		type: 'PRODUCT',
		bodyText: blogCoco,
		date: 'November 22, 2022 - 8 min read ',
		author: [
			{ name: 'Luis Remis', image: imgLuis },
		],
		tags: [Tags.MACHINE_LEARNING, Tags.DATASET_MANAGEMENT, Tags.USABILITY, Tags.DATA_PREPARATION],
	},
	{
		id: 'specialized-database-analytics-images-videos',
		title: 'Building a Specialized Database for Analytics on Images and Videos',
		description: 'ApertureDB is a database for visual data such as images, videos, embeddings and associated metadata like annotations, purpose-built for...',
		image: seedAnnouncementImg,
		type: 'NEWS',
		bodyText: seedAnnouncement,
		date: 'February 8, 2022 - 6 min read ',
		author: [
			{ name: 'Vishakha Gupta', image: imgVishakha },
		],
		tags: [Tags.VISUAL_DATA, Tags.SEMANTIC_SEARCH, Tags.MACHINE_LEARNING, Tags.GRAPH_SEARCH, Tags.DATASET_MANAGEMENT],
	},
	{
		id: 'visual-data-machine-learning-data-science',
		title: 'Managing Visual Data for Machine Learning and Data Science. Painlessly.',
		description: 'Visual data or image/video data is growing fast. ApertureDB is a unique database...',
		image: apertureIntro,
		type: 'TECHNOLOGY',
		bodyText: intro,
		date: 'August 20, 2020 - 7 min read ',
		author: [
			{ name: 'Vishakha Gupta', image: imgVishakha },
		],
		tags: [Tags.VISUAL_DATA, Tags.SEMANTIC_SEARCH, Tags.MACHINE_LEARNING, Tags.GRAPH_SEARCH, Tags.DATASET_MANAGEMENT, Tags.USABILITY],
	},
];

export default blogsData;
