import styles from './ResourceList.module.scss';
import React from 'react';
import { ResourceType } from '../Resources';
import ResourceCard from './ResourceCard';
import { Container, Grid } from '@material-ui/core';
import ResourceBigCard from './ResourceBigCard';

interface ResourceListProps {
	resources: ResourceType[];
	allResources: ResourceType[];
}

const ResourceList: React.FC<ResourceListProps> = ({ resources, allResources }) => {
	return (
		<Container className={styles.list}>
			<ResourceBigCard blog={allResources[0]} />
			<Grid container spacing={1}>
				{resources.length !== 0?
				resources.map(
					(specificNew: ResourceType, index: number): JSX.Element => (
						<Grid
							container
							item
							xs={6}
							md={4}
							lg={3}
							spacing={1}
							key={index}
							className={styles.grid}
						>
							<ResourceCard resource={specificNew} key={specificNew.title} />
						</Grid>
					)
				)
				: <h2 className={styles.emptyResult}> No items found.</h2>

			}
			</Grid>
		</Container>
	);
};

export default ResourceList;
