import styles from './Company.module.scss';
import AboutUs from './sections/AboutUs';
import Angels from './sections/Angels';
import Intro from './sections/Intro';
import Investors from './sections/Investors';
import React from 'react';
import Team from './sections/Team';
import ContactUs from '../Landing/sections/ContactUs';

export interface CaseType {
	title: string;
	description: string;
	image: string;
	bullets: string[];
}

const Company: React.FC = () => {
	return (
		<div className={styles.company}>
			<Intro />
			<AboutUs />
			<div className={styles['company-extension']}>
				<div className={styles.line} />
			</div>
			<Team />
			<div className={styles['company-extension']}>
				<div className={styles.line} />
			</div>
			<Investors />
			<div className={styles['company-extension']}>
				<div className={styles.line} />
			</div>
			<Angels />
			<ContactUs />
		</div>
	);
};

export default Company;
