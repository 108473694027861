import styles from './DockerSection.module.scss';
import React from 'react';
import DockerForm from './DockerForm';

const ContactSection: React.FC = () => {
	return (
		<div className={styles['docker-section']}>
			<div
				className={`${styles['section-container']} ${styles['section-container-extension']}`}
			>
				<DockerForm />
			</div>
		</div>
	);
};

export default ContactSection;
