export const multimodalAI = `<html>
<head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
</head>
<body class="c14 doc-content">
    <p class="c9"><span>From </span><span>worker safety to detecting product defects to overall quality control,
        </span><span class="c7"><a class="c2"
                href="/use-cases#Visual%20Inspection" target="_blank">industrial
                and visual inspection</a></span><span>&nbsp;plays a crucial role. </span><span class="c7"><a class="c2"
                href="https://ispe.org/pharmaceutical-engineering/november-december-2020/vision-inspection-using-machine" target="_blank">Pharmaceutical</a></span><span
            class="c0">&nbsp;and cosmetic manufacturing, food production, heavy machinery operation, energy production,
            electronics manufacturing, and more, differ significantly in the products and services they offer, yet they
            all recognize inspection is vital for promptly detecting issues and ensuring that processes operate
            efficiently according to design.</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c9"><span>Efficient processing and </span><span class="c7"><a class="c2"
                href="/blog/visual-data-machine-learning-data-science" target="_blank">management
                of data across various modalities</a></span><span>, including text, images, video, and audio, are
            critical for effective applications of visual and industrial inspection. This multimodal data in combination
            with the </span><span class="c7"><a class="c2"
                href="https://www.mdpi.com/journal/applsci/special_issues/Visual_Inspection_Using_Machine_Learning_Artificial_Intelligence" target="_blank">rapidly
                improving AI techniques</a></span><span class="c7"><a class="c2"
                href="https://www.mdpi.com/journal/applsci/special_issues/Visual_Inspection_Using_Machine_Learning_Artificial_Intelligence" target="_blank">&nbsp;</a></span><span
            class="c0">can be particularly powerful, as it allows for a more comprehensive analysis by combining
            information from different sources. Improvement opportunities and benefits are vast and vary greatly based
            on the type of inspection being done. </span></p>
    <h1 class="c4" id="h.9iqdpk9d227l"><span>Multimodal AI </span><span>Use Cases </span><span>For Industrial And Visual
        </span><span>Inspection</span></h1>
    <h2 class="c13" id="h.ed5b7vu04xzo"><span class="c5">Worker Safety</span></h2>
    <p class="c9"><span>Workers may not always comply with required Personal Protective Equipment
            (</span><span>PPE</span><span>). There might be hazardous spills or obstructions creating an unsafe working
            environment. Applying visual inspection to worker safety can protect employees from work related illnesses
            and injuries, boost morale and efficiency, and improve regulatory compliance.</span><span
            class="c0">&nbsp;</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c9"><span class="c0">If AI models can detect PPE violations and environmental hazards from a camera feed
            as they happen and generate alerts, safety issues can be immediately identified and rectified in real time.
            This can be made possible at scale with AI models trained to detect people, products, and their interactions
            with all the camera and sensor data available. </span></p>
    <p class="imgContainer">
        <span>
            <img
                alt=""
                src="https://aperturedata-public.s3.us-west-2.amazonaws.com/website_images/inspection_blog_feb24/inspection2.jpg"
                title=""
                style="width: 60%; height: auto;" 
            >
        </span>
    </p>
    <h2 class="c13" id="h.v4lqsemibi9h"><span class="c5">Defect Detection and Quality Control</span></h2>
    <p class="c9"><span>No one wants a defective product - not the consumer, not the retailer, and most importantly, not
            the manufacturer. </span><span class="c7"><a class="c2"
                href="https://paperswithcode.com/task/defect-detection" target="_blank">Visual
                detection</a></span><span class="c0">&nbsp;can be used to identify manufacturing defects more
            effectively and sooner, reducing waste, safeguarding quality, and improving costs.</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c9"><span>Cameras and other sensors along manufacturing lines </span><span>capture a variety</span><span
            class="c0">&nbsp;of data in addition to images or videos, monitoring products and machinery at different
            stages of production. AI models trained on this multimodal data can capture defects more effectively than
            individual sensors acting independently. </span></p>
    <p class="c1"><span class="c0"></span></p>
	<p class="imgContainer">
		<span>
			<img
				alt=""
				src="https://aperturedata-public.s3.us-west-2.amazonaws.com/website_images/inspection_blog_feb24/inspection1.jpg"
				title=""
                style="width: 60%; height: auto;" 
			>
		</span>
	</p>
    <h2 class="c13" id="h.y3su58ffly6a"><span class="c5">Predictive Maintenance</span></h2>
    <p class="c9"><span>Many businesses rely on large, expensive systems that can be difficult and/or expensive to
            monitor and maintain, </span><span>such as an oil rig in a remote area</span><span>. </span><span>If these
            systems break down, it may result in a </span><span>catastrophic</span><span>&nbsp;spill or fire,
            endangering not just the workers but also the surrounding communities with
        </span><span>devastating</span><span>&nbsp;</span><span>environmental</span><span>&nbsp;impacts. &nbsp;</span>
    </p>
    <p class="c1 c8"><span class="c0"></span></p>
    <p class="c9"><span>A tremendous amount of data comes from these machines including performance data, product data,
            throughput data, cameras focused on difficult to access areas of the machine, and audio recordings of the
            machine in operation. &nbsp;All of this multimodal data can be used to build and train AI models to identify
            operational abnormalities and potential equipment defects. This results in proactively identifying and
            addressing anomalies quickly, before they become emergencies, cause millions in damages, </span><span>or
            worse, result in loss of life,</span><span>&nbsp;due to large equipment failures.</span></p>
    <p class="c1 c8"><span class="c0"></span></p>
	<p class="imgContainer">
		<span>
			<img
				alt=""
				src="https://aperturedata-public.s3.us-west-2.amazonaws.com/website_images/inspection_blog_feb24/inspection3.jpg"
				title=""
                style="width: 60%; height: auto;" 
			>
		</span>
	</p>
    <h1 class="c4" id="h.mpwlh6wtx1j3"><span class="c11">Industrial And Visual Inspection Challenges Facing Data
            Scientists And AI Teams </span></h1>
    <p class="c9"><span>Regardless of the specific use case, multimodal AI has become increasingly &nbsp;important for
        </span><span class="c7"><a class="c2"
                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC10058274/" target="_blank">industrial
                and visual inspection</a></span><span>&nbsp;as AI allows you to achieve your goals faster</span><span>,
            yet it is not without cost and depends on quality data. While the specific goals vary, all focus on
            improving efficiency and performance in operations, lowering overall costs, optimizing resources, and
            ultimately driving business growth and revenue. As AI algorithms and models are seeing rapid improvements,
            some </span><span class="c7"><a class="c2"
                href="https://www.youtube.com/watch?v=uSP7qjeF5A4" target="_blank">common
                challenges</a></span><span class="c0">&nbsp;remain, to prove value and deploy in production:</span></p>
    <p class="c1"><span class="c0"></span></p>
        <p class="c3 c10"><span class="c3"><b>Disparate Data Sources</b>: </span><span>Collecting industrial
            </span><span class="c7"><a class="c2"
                    href="https://docs.aperturedata.io/HowToGuides/start/Ingestion" target="_blank">data</a></span><span
                class="c0">&nbsp;for detection or training can often require ingestion of data from many different
                endpoints, sending data at different frequencies and in different formats. These data sources are
                continuously getting richer as cameras and sensors improve. Data management solutions and data loading
                pipelines need to support this evolving information from disparate sources with ease.</span></p>
        <p class="c3 c10"><span class="c3"><b>Dataset Versioning</b>:</span><span>&nbsp;Models need iterations as data
                evolves. Often, it is necessary to create datasets using complex searches that involve </span><span
                class="c7"><a class="c2"
                    href="/blog/are-vector-databases-enough" target="_blank">vector
                    similarity</a></span><span class="c0">&nbsp;to find similar defects in images and so on. Equally
                important is to manage and define datasets according to the state of the data, and track versions of
                these datasets.</span></p>
        <p class="c3 c10"><span class="c3"><b>Knowledge Loss</b>: </span><span>D</span><span class="c0">eparture of
                experienced team members can create knowledge gaps, and processes can become non-repeatable or ad-hoc
                due to inadequate tooling. Onboarding new resources to work with complex tooling becomes extremely
                frustrating and time-consuming, impacting the success of ongoing AI projects.</span></p>
        <p class="c3 c10"><span class="c3"><b>Rising Costs</b>: </span><span>Cloud costs are on the rise, affecting
                the cost vs. benefit calculus of multimodal data. </span><span class="c0">Effective resource utilization
                and tooling are vital to safeguard return on investment (ROI) as expenses rise.</span></p>
        <p class="c3 c10"><span class="c3"><b>Scaling and Growth</b></span><span>: Scaling to large volumes poses
                challenges, and achieving high performance can be exceptionally difficult in the realm of multimodal
                data.</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c9"><span class="c0">Despite advancements in data science and machine learning, the success of AI hinges
            heavily on reliable and accurate data. All the aforementioned use cases necessitate:</span></p>
    <p class="c1"><span class="c0"></span></p>
    <ol class="c10 lst-kix_om64cuitkbfu-0 start">
        <li class="c6 li-bullet-0"><span class="c0">Efficiently and easily storing and organizing continuously generated
                data from disparate sources spread across edge and cloud.</span></li>
        <li class="c6 li-bullet-0"><span class="c0">Training machine learning models in an iterative fashion using the
                chosen modalities of data to enhance accuracy with the latest data.</span></li>
        <li class="c6 li-bullet-0"><span class="c0">Integrating with labeling and curation frameworks in-house or
                utilizing third-party vendors, as the data often requires annotations.</span></li>
        <li class="c6 li-bullet-0"><span>Ultimately, generating valuable insights or creating relevant
            </span><span>datasets</span><span>&nbsp;leveraging product and </span><span>vector search
                capabilities</span><span>, which, in turn, demand consistent indexing and continuous enrichment of all
                the data.</span></li>
    </ol>
    <h1 class="c4" id="h.gvkg3lnrzi7o"><span>Next Steps For Your Multimodal AI Journey</span></h1>
    <p class="c1 c8"><span class="c0"></span></p>
    <p class="c9"><span>Efficiently searching, accessing, processing, and visualizing data for reasons explained above,
            is </span><span>crucial for AI success</span><span>. Many companies initially opt for cloud-based storage
            but later realize that, especially for multimodal data like images, videos, and documents, relying solely on
            file names is woefully inadequate. Searching across various modalities necessitates multiple databases, each
            for metadata, labels, and embeddings. Preprocessing data into the right format involves complex libraries
            like ffmpeg or opencv. Stitching together these diverse data components is labor-intensive, suboptimal, and
            falls short of the needs of effective industrial and visual inspection.</span><span class="c0">&nbsp;</span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c9"><span>Effective visual and industrial inspection</span><span>&nbsp;requires a purpose-built multimodal
            data solution that establishes a central repository of multimodal data and attribute metadata, as
            well as track corresponding annotations, embeddings, datasets, and model behaviors. Such a </span><span
            class="c7"><a class="c2"
                href="/blog/specialized-database-analytics-images-videos" target="_blank">database</a></span><span
            class="c0">&nbsp;facilitates management of data from disparate sources and collaboration among teams that
            foster continuous improvement of managed information. This results in new operational insights, enhancing
            quality, and operational efficiency.</span></p>
    <p class="c1 c8"><span class="c0"></span></p>
    <h2 class="c13" id="h.ugrvm1rn9sm1"><span class="c5">Consider ApertureDB - A Purpose-built Database For
            Multimodal AI</span></h2>
    <p class="c9"><span>A unified approach to multimodal data, </span><span class="c7"><a class="c2"
                href="https://www.aperturedata.io/home" target="_blank">ApertureDB</a></span><span>&nbsp;replaces
            the manual integration of multiple systems to achieve multimodal search and access. It seamlessly manages
            images, videos, embeddings, and associated metadata, including annotations, merging the capabilities of a
        </span><span class="c7"><a class="c2"
                href="https://docs.aperturedata.io/HowToGuides/Basic/Image%20Similarity%20Search" target="_blank">vector
                database</a></span><span>, </span><span class="c7"><a class="c2"
                href="https://docs.aperturedata.io/HowToGuides/start/Schema" target="_blank">intelligence
                graph</a></span><span>, and </span><span class="c7"><a class="c2"
                href="https://docs.aperturedata.io/Introduction/WhatIsAperture#aperturedb-core-architecture" target="_blank">multimodal
                data</a></span><span>.</span></p>
    <p class="c1"><span class="c0"></span></p>
	<p class="imgContainer">
		<span>
			<img
				alt=""
				src="https://aperturedata-public.s3.us-west-2.amazonaws.com/website_images/inspection_blog_feb24/inspection4.png"
				title="Search for images of steel pipes all with the defect type "unfused""
                style="width: 80%; height: auto;" 
			>
		</span>
        <p class="c1 c8" align="center"><span class="c0"><i>Navigate all images showing the "unfused" defect type, graphically, on ApertureDB UI</i></span></p>
	</p>
    <p class="c9"><span>ApertureDB ensures </span><span>cloud-agnostic</span><span>&nbsp;integration with
            existing and new analytics pipelines, enhancing speed, agility, and productivity for data science and ML
            teams. ApertureDB enables efficient retrieval by co-locating relevant data and handles complex queries
            transactionally.</span></p>
    <p class="c1"><span class="c0"></span></p>
	<p class="imgContainer">
		<span>
			<img
				alt=""
				src="https://aperturedata-public.s3.us-west-2.amazonaws.com/website_images/inspection_blog_feb24/inspection5.png"
				title="Search for images of steel pipes all with the defect type "unfused""
                style="width: 80%; height: auto;" 
			>
		</span>
        <p class="c1 c8" align="center"><span class="c0"><i>Use the ApertureDB client package on Jupyterlab to search for data by metadata or similarity.</i></span></p>
	</p>
    <p class="c1 c8"><span class="c0"></span></p>
    <p class="c9"><span>Whether your organization has a small or large team working with multimodal data, or if
            you&#39;re simply curious about our technology and infrastructure development, reach out to us at
        </span><span class="c7"><a class="c2" href="mailto:team@aperturedata.io">team@aperturedata.io</a></span><span>.
            Experience </span><span class="c7"><a class="c2"
                href="/demo-request" target="_blank">ApertureDB
                on pre-loaded datasets</a></span><span>, and if you&#39;re eager to contribute to an early-stage
            startup, </span><span class="c7"><a class="c2"
                href="/join-us" target="_blank">we&#39;re hiring</a></span>. Stay informed about our journey and learn more about the components mentioned
            above by </span><span class="c7"><a class="c2"
                href="https://forms.gle/BdxgdUndw5FcsBQP6" target="_blank">subscribing</a></span><span
            class="c0">&nbsp;to our blog.</span></p>
    <p class="c1 c8"><span class="c0"></span></p>
    <p class="c1 c8"><span class="c0"></span></p>
        <p class="c4">
                <span class="acknowledge">I want to acknowledge Laura Horvath for helping write this blog and the insights from Josh Stoddard, and the ApertureData team.</span>
        </p>
    <p class="c1"><span class="c0"></span></p>
</body>
</html>
`;
