import styles from './Angels.module.scss';
import React from 'react';

const Angels: React.FC = () => {
	return (
		<div className={styles.angels}>
			<div
				className={`${styles['section-container']} ${styles['section-container-extension']}`}
			>
				<div className={styles['title-column']}>Angel Investors</div>
				<div className={styles['content-column']}>
					We have an accomplished group of angel investors supporting
					ApertureData, including Tom Preston Werner (Co-founder of Github),
					Borja Burgos (Director of Product at Datadog, ex-Docker), Balamurali
					Meduri (Managing Director at JP Morgan Chase, ex-eBay Head of AI
					Platform & Applications), Rohini Pandhi (Product @ Square), Rahul
					Thathoo (Sr. Engineering Manager @ Square), Minda Brusse (Co-founder +
					Managing Director at First Row Partners), Michael MacCombie (Head of
					Platform at Surface Ventures), Palvi Mehta (CFO and Operating Partner,
					Pioneer Square Labs), Charles Ferguson (founded and sold Vermeer
					Technologies to Microsoft), Andrew Schulert (ex-VP at Sonos), Heidi
					Braunstein (Principal at Future Future), Vivek Tripathi (Director of
					Software Engineering at LinkedIn), Ashwin Kolhe (Sr. Engineering
					Manager at NVIDIA), and Jui Deshpande (Sr. Software Engineer at
					Microsoft).
				</div>
			</div>
		</div>
	);
};

export default Angels;
