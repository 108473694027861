import styles from './DemoRequest.module.scss';
import ContactSection from './sections/ContactSection';
import ContactUs from '../Landing/sections/ContactUs';
import Intro from './sections/Intro';
import React from 'react';

export interface DemoCaseType {
	name: string;
	title: string;
}

const DemoRequest: React.FC = () => {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className={styles['demo-request-page']}>
			<Intro />
			<ContactSection />
			<ContactUs text={"If you’d like to request a sandbox to try your own use case, have specific questions, or simply want to chat, drop us a line below."} />
		</div>
	);
};

export default DemoRequest;
