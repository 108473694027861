import styles from './Intro.module.scss';
import React from 'react';

const Intro: React.FC = () => {

	return (
		<div className={styles['intro-case-studies']}>
			<div className={styles['section-container']}>
				<h1>Case Studies</h1>
				<h2>Watch our impact in the words of our customers then browse our case studies.</h2>
			</div>
		</div>
	);
};

export default Intro;
