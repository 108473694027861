import styles from './Intro.module.scss';
import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import ActionButton from '../../../components/Buttons/ActionButton';

// const style = {
// 	tryForFree: {
// 		backgroundColor: '#FF8D22',
// 		border: '0.8px solid #FFFFFF',
// 		color: '#fff',
// 		borderRadius: 8,
// 		width: 166,
// 		height: 31,
// 		fontSize: 12,
// 		letterSpacing: 0.2,
// 		textTransform: 'none',
// 		fontWeight: 700,
// 	},
// };

const Intro: React.FC = () => {
	// const navigate = useNavigate();

	return (
		<div className={styles['intro-use-cases']}>
			<div className={styles['section-container']}>
				<h1>Industry Use Cases</h1>
				<h2>Use ApertureDB to save your data teams months in data engineering, deploy ML in production faster, and benefit from increased productivity and collaboration between teams, across a variety of verticals and initiatives.</h2>
				{/* <ActionButton
					name='Request a Demo'
					onClick={(): void => navigate('/contact-us')}
					style={style.tryForFree}
				/> */}
				{<a href='/contact-us'>Questions? Contact Us</a>}
			</div>
		</div>
	);
};

export default Intro;
