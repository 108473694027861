import apertureDB from '../images/ContactUs/Carousel/apertureDB.svg';
import inVisualDataset from '../images/ContactUs/Carousel/InVisualDataset.jpg';
import celebaTrial from '../images/ContactUs/Carousel/celeba_trial.png';
import clipVectorSearch from '../images/ContactUs/Carousel/clip_vector_search.png';
import polygons from '../images/ContactUs/Carousel/polygons.png';
import semanticSearch from '../images/ContactUs/Carousel/semantic_search.png';
import videos from '../images/ContactUs/Carousel/videos.png';

const demoContactFormImages = [
	{
		label: 'CelebaTrial',
		imgPath: celebaTrial,
	},
	{
		label: 'ApertureDB',
		imgPath: apertureDB,
	},
	{
		label: 'InVisualDataset',
		imgPath: inVisualDataset,
	},
	{
		label: 'ClipVectorSearch',
		imgPath: clipVectorSearch,
	},
	{
		label: 'Polygons',
		imgPath: polygons,
	},
	{
		label: 'SemanticSearch',
		imgPath: semanticSearch,
	},
	{
		label: 'Videos',
		imgPath: videos,
	},
];

export default demoContactFormImages;
