export const downloadPDF = (pdfName: string) => {
  const url = `${process.env.PUBLIC_URL}/pdf/${pdfName}`;
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', pdfName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

