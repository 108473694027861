import { NewType } from '../News';
import Link from '@material-ui/core/Link';
import React from 'react';
import styles from './NewCard.module.scss';
import Typography from '@material-ui/core/Typography';

interface NewProps {
	new: NewType;
}

const NewCard: React.FC<NewProps> = (props) => {
	return (
		<div className={styles.new}>
			<div className={styles['image-container']}>
				<img
					alt={props.new.title}
					className={styles.image}
					src={props.new.image}
				/>
			</div>
			<div className={styles['text-container']}>
				<Typography className={styles.date} color='textSecondary' gutterBottom>
					{props.new.date}
				</Typography>
				<Typography className={styles.title} component='h2'>
					{props.new.title}
				</Typography>
				<Typography className={styles.description} component='p'>
					{props.new.description}
				</Typography>
				<Link
					href={props.new.link}
					className={styles.link}
					target='_blank'
					rel='noopener noreferrer'
				>
					READ MORE
				</Link>
			</div>
		</div>
	);
};

export default NewCard;
