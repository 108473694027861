/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import styles from "./CaseStudy.module.scss";
import ContactUs from "../Landing/sections/ContactUs";
import Intro from "./sections/Intro";
import { useParams } from "react-router-dom";
import Study from "./sections/Study";
import caseStudies from "../../assets/content/CaseStudies";

interface ImageForNotice {
	image: string;
	position?: "UP" | "BOTTOM";
}
interface Notice {
	title: string;
	text: string;
	source?: ImageForNotice;
}

interface Article {
	comment: string;
	authorComment: string;
	authorPosition: string;
	notices: Notice[];
}

export interface Card {
	authorComment: string;
	authorPosition: string;
	comment: string;
	description: string;
	descriptionCarousel: string;
	image: string;
	title: string;
	titleCarousel: string;
	date: string;
	pdf?: string;
	link?: string;
}

interface ArticlesData {
	article: Article;
}

export interface CaseStudyType {
	id: string;
	card: Card;
	introduction: {
		title: string;
		text: string;
		companyLogo?: string;
		images: string[];
	};
	text: string;
	articles: ArticlesData[];
}

const textStyle: React.CSSProperties = {
	color: "#FFF",
	textAlign: "center",
	fontFamily: "Roboto",
	fontSize: "16px",
	fontStyle: "normal",
	fontWeight: 400,
	lineHeight: "normal",
};

const CaseStudy: React.FC = () => {
	const { studyId } = useParams();

	const studySelected = caseStudies.find((study) => study.id === studyId!);

	return (
		<div className={styles.caseStudies}>
			<Intro study={studySelected!} />
			<Study study={studySelected!} />
			<ContactUs
				text="At ApertureData, we work with companies such as big box retailers, (obviously!) intelligent retail platform providers, visual inspection solutions, to drive business intelligence platforms, eCommerce experiences, and more."
				secondText="We'd love to get to know you and your challenges."
				buttonText="BOOK A DEMO"
				buttonPosition="BETWEEN"
				buttonStyleWithBorder={false}
				firstTextStyle={textStyle}
				secondTextStyle={textStyle}
			/>
		</div>
	);
};

export default CaseStudy;
