import styles from "./Intro.module.scss";
import ActionButton from "../../../components/Buttons/ActionButton";
import LandingIllustrait from "../../../assets/images/Landing/new-landing.svg";
import Arrow from "../../../assets/images/Landing/arrow.svg";
import React from "react";
import { useNavigate } from "react-router-dom";

const style = {
	tryUs: {
		color: "#fff",
		backgroundColor: "#FF8D22",
		borderRadius: 8,
		width: "auto",
		height: 27,
		fontSize: "clamp(11.13px, 1.17vw, 18.51px)",
		textTransform: "none",
		fontWeight: 700,
	},
};

// <h1>{'ApertureDB: The purpose-built database for managing images and videos for data science and machine learning'}</h1>
// A purpose-built database for image and video analytics
// Manage and search labeled image and video datasets with ease
// Perform metadata and vector search at scale
// Streamline dataset preparation and tracking to iterate on ML models

const Intro: React.FC = () => {
	const navigate = useNavigate();

	return (
		<div className={styles.imgGroupe}>
			<div className={styles.introLanding}>
				<div
					className={`${styles["section-container"]} ${styles.sectionContainerExtension}`}
				>
					<div className={styles.leftSide}>
						<div className={styles.titleContainer}>
							<h1>ApertureDB:</h1>
							<h1>
								A database purpose-built for multimodal AI
							</h1>
						</div>
						<div className={styles.listContainer}>
							<div className={styles.optionContainer}>
								<img src={Arrow} alt="Arrow" />
								<span className={styles.option}>
									Production-ready, scalable vector database for multimodal data
								</span>
							</div>
							<div className={styles.optionContainer}>
								<img src={Arrow} alt="Arrow" />
								<span className={styles.option}>
									Memory-optimized graph database support for knowledge graphs
								</span>
							</div>
							<div className={styles.optionContainer}>
								<img src={Arrow} alt="Arrow" />
								<span className={styles.option}>
									Streamlined dataset preparation and integration with ML frameworks
								</span>
							</div>
						</div>
						<div className={styles.btnContainer}>
							<div className={styles.trialContainer}>
								<ActionButton
									name="Try ApertureDB for Free"
									onClick={(): void =>
										navigate("/demo-request")
									}
									style={style.tryUs}
								/>
							</div>
							<div className={styles.linkQuestions}>
								<span>
									<a href="/contact-us">
										Questions? Contact Us
									</a>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<img
				src={LandingIllustrait}
				className={styles.groupImage}
				alt="intro"
			/>
		</div>
	);
};

export default Intro;
