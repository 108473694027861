export const vectorDatabasesPartTwoBlog = `
<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
  </head>
  <body class="c13 doc-content">
    <p class="c4">
      <span>In the </span>
      <span class="c5">
        <a class="c2" href="/blog/multimodal-data-intro-part1">first blog of this series</a>,
            we covered the basics of multimodal AI, generative AI, and how vector databases enable semantic search over text and multimodal data
      <span>. In this second blog of the series, we&rsquo;ll look at real-life examples of where vector databases are used when building some text and multimodal AI applications like </span>
      <span>semantic search</span>
      <span>&nbsp;often for question/answering solutions, </span>
      <span>recognizing faces</span>
      <span>&nbsp;for security, and using </span>
      <span>robots to check store shelves</span>
      <span>. These examples will help us understand fundamental requirements when building various text and multimodal AI applications, and give us an idea of why we need advanced data solutions, beyond vector databases, &nbsp;that can enable more complex and contextual data searches. </span>
    </p>
    <h1 class="c17" id="h.xkjx1dietmro">
      <span class="c12">Search And Retrieval Requirements of Sample Multimodal AI Applications</span>
    </h1>
    <p class="c4">
      <span>Vector indexing, search, and classification are hard problems that are tackled by a growing collection of </span>
      <span class="c5">
        <a class="c2" href="https://www.superlinked.com/vector-db-comparison">vector databases in the market or incumbents</a>
      </span>
      <span>&nbsp;that have introduced vector support. However</span>
      <span>, these databases can&rsquo;t do everything that is expected from the data layer in a classic machine learning pipeline regardless of whether </span>
      <span>it&#39;s</span>
      <span class="c1">&nbsp;for traditional use cases or for GenAI applications. </span>
    </p>
    <p class="c4 c6">
      <span class="c1"></span>
    </p>
    <p class="c4">
      <span class="c1">Let&#39;s walk through a few examples we have encountered when working with our users, to really understand what&rsquo;s required from the data layer, to support multimodal AI in the real world.</span>
    </p>
    <h2 class="c14" id="h.sm0wplxq3vs1">
      <span class="c0">Chat Support or Question-Answering Using Semantic Search</span>
    </h2>
    <p class="c4">
      <span>A common example we come across now is chat or question-answering support (let&rsquo;s call it chatbot for now) thanks to LLM-based bots like ChatGPT that often </span>
      <span class="c5">
        <a class="c2" href="https://sonamcoffeenlp.substack.com/p/semantic-search-to-glean-valuable-deb">use semantic search</a>
      </span>
      <span class="c1">. Let&rsquo;s start with a manufacturer that makes millions of different products that provide error codes when something goes wrong and that also include a product manual with purchase. A customer may experience an issue and sees an error code but doesn&rsquo;t understand what it means. They visit the manufacturers chatbot and enter in the error code as a first step of similarity search. Next, they want to add more restrictions to the search by focusing on products sold in 2019 so adding more metadata filtering. In addition, the customer also wants a copy of the product manual to learn more. At this time, they not only want an &lsquo;answer&rsquo; to the meaning of the error code but also want a link to the complete original manual for their review with some author or date restrictions. This evolved the query from similarity search to address constraints due to complex metadata filtering followed by pdf access requirements making it a problem where vector databases and even the incumbent databases with &ldquo;vector add-ons&rdquo; cannot solely provide a complete solution.</span>
    </p>
    <p class="imgContainer">
			<span style="overflow: hidden; display: inline-block; margin: 0px; border: none; width: 100%; height: auto; box-sizing: border-box;">
        <img alt="" src="https://aperturedata-public.s3.us-west-2.amazonaws.com/website_images/multimodalai_intro/chatgpt.jpg" style="width: 60%; height: 60%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);" title="">
      </span>
    </p>
    <h2 class="c14" id="h.xtdbbtda8258">
      <span class="c0">Facial Recognition</span>
    </h2>
    <p class="c4">
      <span>Another very simple but common use case example is </span>
      <span class="c5">
        <a class="c2" href="https://youtu.be/Ta7G_ASgxnA?feature=shared">finding a specific face</a>
      </span>
      <span class="c1">&nbsp;in a collection of faces for say, surveillance/safety concerns or just remembering someone&rsquo;s name. First, we start with a similarity search of face images but then you want to restrict your search to limit by nationality where people from the United States are the only ones we want to find. This introduces a metadata attribute. Next, you remember that the person you are searching for acted in a specific movie, so you add that search constraint. You also would like to see video clips of the movies in which this person appeared. As you can see, your search gradually evolved from simple similarity search to more and more complex metadata filtering followed by video access, capabilities that vector databases were simply not built to support on their own. </span>
    </p>
    <p class="imgContainer">
			<span style="overflow: hidden; display: inline-block; margin: 0px; border: none; width: 100%; height: auto; box-sizing: border-box;">
        <img alt="" src="https://aperturedata-public.s3.us-west-2.amazonaws.com/website_images/multimodalai_intro/tracking.jpg" style="width: 60%; height: 60%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);" title="">
      </span>
    </p>
    <p class="c4 c6">
      <span class="c1"></span>
    </p>
    <h2 class="c14" id="h.dq1xth25lltu">
      <span class="c0">Visual Recommendations</span>
    </h2>
    <p class="c4">
      <span>Let&rsquo;s say an e-commerce company with over millions of products </span>
      <span class="c1">wants to support visual recommendations based on colors and shapes of products but wants to limit items to their fall 2023 catalog or to a specific supplier catalog. The goal is then similarity search constrained by complex metadata filtering followed by image access.</span>
    </p>
    <p class="c4 c6">
      <span class="c1"></span>
    </p>
    <p class="c4">
      <span class="c1">Before running these queries, there are many steps that must be completed to support the visual recommendations for the ecommerce company. This includes creating and managing datasets easily so they can be revised with every catalog update. Iteratively training models that will be used to extract embeddings for visual search, will also be needed with all the millions of images that are being constantly updated. Finally the embeddings will need to be effectively indexed to support similarity search quickly and easily at scale. While a vector database can solve the last part, a) the dataset management of product and image data, b) training with millions of relevant images, and finally, c) keeping track of all the metadata, embeddings, and images to respond to users, they all need seamless integration with the entire machine learning pipeline, not just vector or metadata search support.</span>
    </p>
    <p class="imgContainer">
			<span style="overflow: hidden; display: inline-block; margin: 0px; border: none; width: 100%; height: auto; box-sizing: border-box;">
        <img alt="" src="https://aperturedata-public.s3.us-west-2.amazonaws.com/website_images/multimodalai_intro/ecommerce.png" style="width: 60%; height: 60%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);" title="">
      </span>
      <span class="c2"><i>Source: https://www.visenze.com/discovery-suite/modules/smart-recommendations</i></span>
    </p>
    <h2 class="c14" id="h.iootbl98liu3">
      <span class="c0">Smart Retail</span>
    </h2>
    <p class="c4">
      <span>Ever seen a </span>
      <span class="c5">
        <a class="c2" href="/case-studies/1">robot checking for empty shelves</a>
      </span>
      <span>&nbsp;at a grocery or </span>
      <span>retail store?</span>
      <span class="c1">&nbsp;The robots go up and down the aisles looking for empty shelves to then determine what product needs to be added. This query includes matching thousands of vectors to their corresponding product name that requires classification followed by product lookup, at scale. </span>
    </p>
    <p class="c4 c6">
      <span class="c1"></span>
    </p>
    <p class="c4">
      <span class="c1">Again, before the queries can be executed, there are many tasks that need to be completed and managed effectively. All the labeled data must be created and managed to be easily updated with every new image of an empty shelf. Models must be constantly trained as new products are added or old products removed requiring large amounts of images. Finally all the embeddings need to be indexed to perform quickly and easily to support the business needs.</span>
    </p>
    <p class="c4 c6 c9">
      <span class="c1"></span>
    </p>
    <p class="imgContainer">
			<span style="overflow: hidden; display: inline-block; margin: 0px; border: none; width: 100%; height: auto; box-sizing: border-box;">
        <img alt="" src="https://aperturedata-public.s3.us-west-2.amazonaws.com/website_images/multimodalai_intro/badgerbot.jpg" style="width: 60%; height: 60%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);" title="">
      </span>
      <span class="c2"><i>Source: https://www.badger-technologies.com/resource/badger-resources.html</i></span>
    </p>
    <h2 class="c17" id="h.pv6515wgqru4">
      <span class="c12">Building a Data Stack for Current and Future AI Applications</span>
    </h2>
    <p class="c4">
      <span class="c1">All of these examples share common requirements to index high-dimensional embeddings from any multimodal data type as well as to allow metadata-based filtering and retrieval of the corresponding data after the search. This necessitates certain requirements from the data layer such as:</span>
    </p>
    <ul class="c10 lst-kix_qq5mibqupt4r-0 start">
      <li class="c4 c9 c11 li-bullet-0">
        <span class="c1">High throughput vector search and classification</span>
      </li>
      <li class="c4 c9 c11 li-bullet-0">
        <span class="c1">Filter on rich and evolving metadata </span>
      </li>
      <li class="c4 c9 c11 li-bullet-0">
        <span class="c1">Ability to connect with original data </span>
      </li>
      <li class="c4 c9 c11 li-bullet-0">
        <span class="c1">Seamless integrations with various steps of an AI pipeline beyond just a query or analytics framework</span>
      </li>
      <li class="c4 c9 c11 li-bullet-0">
        <span class="c1">Reliability and stability at scale to support production</span>
      </li>
      <li class="c4 c9 c11 li-bullet-0">
        <span class="c1">Production-ready, cloud-agnostic, often virtual private cloud (VPC) deployments</span>
      </li>
    </ul>
    <p class="c4 c6">
      <span class="c1"></span>
    </p>
    <p class="c4">
      <span>But can </span>
      <span class="c5">
        <a class="c2" href="/blog/are-vector-databases-enough">vector databases</a>
      </span>
      <span class="c1">handle all this by themselves? Today&rsquo;s popular databases can:</span>
    </p>
    <p class="c4 c6">
      <span class="c1"></span>
    </p>
    <ul class="c10 lst-kix_bk5fcb7nvtc0-0 start">
      <li class="c4 c9 c11 li-bullet-0">
        <span class="c1">Store feature vectors and return an ID, but the ID needs to be managed separately and linked to different data types.</span>
      </li>
      <li class="c4 c9 c11 li-bullet-0">
        <span class="c1">Sometimes allow users to attach a row of columns and include some metadata information but by nature of it being a few columns of metadata, it is difficult to represent typically complex application metadata</span>
      </li>
      <li class="c4 c9 c11 li-bullet-0">
        <span class="c1">Rarely support complex filtering, such as overlaying intricate schemas that mimic graphs, even when we are talking about incumbent relational databases allowing vector search</span>
      </li>
      <li class="c4 c9 c11 li-bullet-0">
        <span class="c1">Often lack the ability to manage multimodal data and provide provenance information</span>
      </li>
    </ul>
    <p class="c4 c6">
      <span class="c1"></span>
    </p>
    <p class="c4">
      <span>While vector databases are able to solve the vector indexing and search challenges, you need to add other databases and storage options to the data pipeline and architecture to handle the rest, resulting in a complex glued together system that is brittle and painful to maintain and reuse. We also cover <a class="c2" href="/blog/purpose-built-database">the other database alternatives</a> and their pros/cons in our blog on multimodal data requirements
      </span>
      <span class="c1">&nbsp;in detail.</span>
    </p>
    <p class="c4 c6">
      <span class="c1"></span>
    </p>
    <p class="c4">
      <span>Given that the traditional data and database tools result in a spaghetti data architecture, a technical debt that should be avoided, one solution then is to architect a purpose-built database that can manage complex and varied data types while exposing a unified interface to index, search, and retrieve those various data types. With the right implementation, it can save companies lots of headaches and wasted engineering time. ApertureDB is one such database. Databases like ApertureDB </span>
      <span class="c5">
        <a class="c2" href="/blog/purpose-built-database">focus on multimodal data and AI</a> giving businesses a unified, scalable, and easy-to-use solution for data management and analysis in today&#39;s fast-changing world.</span>
    </p>
    <p class="c4 c6">
      <span class="c1"></span>
    </p>
    <h1 class="c17" id="h.kxvo3bauujwv">
      <span>What Next?</span>
    </h1>
    <p class="c4">
      <span>What to learn more? To continue digging deeper into the world of multimodal data and AI, check out these 
      blogs on a) </span>
      <span class="c5">
        <a class="c2" href="/blog/purpose-built-database">Why Do We Need A Purpose-Built Database For Multimodal Data?</a>
      </span>
      <span>, b) </span>
      <span class="c5">
        <a class="c2" href="/blog/challenges-with-multimodal-data-evolving">Your Multimodal Data Is Constantly Evolving - How Bad Can It Get?</a>
      </span>
      <span>, and c) </span>
      <span class="c5">
        <a class="c2" href="/blog/multimodal-data-tco">How a Purpose-Built Database for Multimodal AI Can Save You Time and Money</a>.</span>
    </p>
    <p class="c4 c6">
      <span class="c1"></span>
    </p>
    <p class="c4">
      <span>Last but not least, you can </span>
      <span class="c5">
        <a class="c2" href="https://forms.gle/BdxgdUndw5FcsBQP6">subscribe here</a>
      </span>
      <span class="c1">to learn more about text and multimodal AI and data as we document lessons from our journey in our blog.</span>
    </p>
    <p class="c4 c6">
      <span class="c1"></span>
    </p>
    <p class="c4 c6">
      <span class="c1"></span>
    </p>
    <p class="c4">
      <span class="c18"><i>I want to acknowledge the insights and valuable edits from Laura Horvath and Drew Ogle.</i></span>
    </p>
    <p class="c4 c6">
      <span class="c1"></span>
    </p>
  </body>
</html>
`;
