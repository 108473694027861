import styles from './AboutUs.module.scss';
import React from 'react';

const AboutUs: React.FC = () => {
	return (
		<div className={styles['about-us']}>
			<div
				className={`${styles['section-container']} ${styles['section-container-extension']}`}
			>
				<div className={styles['title-column']}>About Us</div>
				<div className={styles['content-column']}>
					Petabytes of multimodal data (images, videos, documents) are getting captured or
					generated across various industries such as smart retail, e-commerce,
					smart agriculture, visual inspection, and medical imaging. Companies
					are increasingly using machine learning (ML) to derive insights from
					this enormous collection of data. These efforts are, however, often
					hindered by the lack of a data management solution that recognizes the
					challenges and opportunities presented by this large scale of multimodal
					data. Our product, ApertureDB, solves the data management challenges
					for large scale multimodal data by recognizing its characteristics,
					the importance of metadata around it, and is
					purpose-built for data science and ML.
				</div>
			</div>
		</div>
	);
};

export default AboutUs;
